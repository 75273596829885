/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { arrayUsers } from '../../../store/old/User/User.selector';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';
import { userSelector } from '../../../store/old/Authentication/Authentication.selector';
import { Permission } from '../../components/Permission';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { User } from '../../models';
import { changeUserRoleRequest, deleteUserRequest } from '../../../store/old/User/User.action';
import './UsersManagement.scss'
import { getAllInvitationsRequest } from '../../../store/old/Invitation/Invitation.action';
import ConfirmationModal from '../../components/ConfirmationModal';

const UsersTab = () => {
    const dispatch = useDispatch()
    const searchRef = useRef(null)

    const user = useSelector(userSelector)
    const users = useSelector(arrayUsers);
    const { entity_id } = useSelector(currentEntitySelector);

    useEffect(() => {
        dispatch(getAllInvitationsRequest())
    }, [])

    const { isClientUser, isWhitelistedDemoUser } = user;

    const [searchValue, setSearchValue] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    const [removeUser, setRemoveUser] = useState(null)

    const isEditPermission = user.check_resource_policy(
        'users',
        false,
        true,
        false
    );

    const usersBelongToCurrentEntity = users.filter(
        (v) => v.entity_id === entity_id
    );

    const handleRemoveUser= (record) => {
        dispatch(deleteUserRequest({ single: record }, () => setRemoveUser(null)))
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchValue(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleChangeRole = (e, user) => {
        const newUser = new User(
            user.user_id,
            user.email,
            user.name_first,
            user.name_last,
            user.mobile,
            user.country_code,
            e.target.value,
            user.appointment,
            user.entity_id,
            user.confirmed,
            user.user_img,
            user.user_img_id,
            null
        );

        dispatch(changeUserRoleRequest({ single: newUser }))
    }

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchRef}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90, display: 'flex', alignItems: 'center' }}
                    >
            Search
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters();
                            handleSearch([], confirm, dataIndex)
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >
            Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchRef.current.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchValue]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: 'First Name',
            dataIndex: 'name_first',
            key: 'name_first',
            ...getColumnSearchProps('name_first'),
        },
        {
            title: 'Last Name',
            dataIndex: 'name_last',
            key: 'name_last',
            ...getColumnSearchProps('name_last'),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ...getColumnSearchProps('email'),
        },
        {
            title: 'Appointment',
            dataIndex: 'appointment',
            key: 'appointment',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            render: (text, record, index) => {
                return (
                    <select
                        className="users-select"
                        onChange={(event) => handleChangeRole(event, record)}
                        value={record.role_name}
                        disabled={!isEditPermission}
                    >
                        <option value="owner">owner</option>
                        <option value="editor">editor</option>
                        <option value="viewer">viewer</option>
                    </select>
                );
            },
        },
        {
            title: '',
            dataIndex: 'action',
            render: (text, record, index) => (
                <Permission forResource resource="users" canDo="full">
                    <div className="d-flex justify-content-center">
                        <span
                            className="p-1"
                            style={{ cursor: 'pointer' }}
                            onClick={() => setRemoveUser(record)}
                        >
                            <i className="far fa-trash-alt" />
                        </span>
                    </div>
                </Permission>
            ),
            width: 50,
        },
    ];

    return (
        <div className="users-tab-content">
            <Table
                columns={columns}
                dataSource={
                    isClientUser || isWhitelistedDemoUser
                        ? usersBelongToCurrentEntity
                        : []
                }
                rowKey="user_id"
            />
            <ConfirmationModal
                showConfirmation={!!removeUser}
                onConfirmation={() => handleRemoveUser(removeUser)}
                toggleConfirmation={() => setRemoveUser(null)}
                action="Remove User"
                message={<RemoveUserMessage user={removeUser}/>}
            />
        </div>
    );
}

const RemoveUserMessage = ({ user }) => {
    if (!user) return null;

    return (
        <div>
            <p>This action is irreversible.</p>
            <p>
                {user.name_first} {user.name_last}'s account will be deleted, and he/she will no
      longer have access to your organisation.
            </p>
            <p>Are you sure?</p>
        </div>
    );
}

export default UsersTab;