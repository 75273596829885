import * as d3 from 'd3';
import moment from 'moment';

import { timeStart, timeEnd } from './date';
import { currentEntitySelector } from '../../store/old/Entity/Entity.selector';
import { getWindow } from '../../store/old/UI/Controls/Controls.selector';
import { store } from '../../store';
import CONSTANTS from '../Constants';

export const canvasPixelRatio = (ctx) => {
    const dpr = window.devicePixelRatio || 1;
    const bsr =
    ctx.webkitBackingStorePixelRatio ||
    ctx.mozBackingStorePixelRatio ||
    ctx.msBackingStorePixelRatio ||
    ctx.oBackingStorePixelRatio ||
    ctx.backingStorePixelRatio ||
    1;

    return dpr / bsr;
};

export const updateHDPICanvas = (node, w, h) => {
    const context = node.getContext('2d');
    const ratio = canvasPixelRatio(context);
    node.width = w * ratio;
    node.height = h * ratio;
    node.style.width = `${w}px`;
    node.style.height = `${h}px`;
    context.setTransform(ratio, 0, 0, ratio, 0, 0);
};

export const getTimeIntervalBandwidth = (d, x) => {
    const { time, int } = d;
    const t1 = +time + +int;
    return x(t1) - x(+time);
};

export const getTimeSeriesBrushXSelection = ({ x, selection, xAccessor }) => {
    const rootState = store.getState();
    const {
        ui: {
            controls: { resolution },
        },
    } = rootState;
    const { tzStartDifference } = currentEntitySelector(rootState);

    const { lower, upper } = getWindow(rootState);
    const startDate = new moment(lower);
    const endDate = new moment(upper);
    const halfResSeconds =
    moment.duration(resolution.res_x, resolution.res_period).as('seconds') *
    0.5;

    const d0 = selection.map(x.invert);
    const d1 = d0.map((d) =>
        roundDateToResolution(d, resolution, tzStartDifference)
    );

    if (d1[0].isBefore(startDate)) {
        d1[0] = roundDateToResolution(
            moment(d0[0]).add(halfResSeconds, 'seconds'),
            resolution,
            tzStartDifference
        );
    }

    if (d1[1].isAfter(endDate)) {
        d1[1] = roundDateToResolution(
            moment(d0[1]).subtract(halfResSeconds, 'seconds'),
            resolution,
            tzStartDifference
        );
    }

    return d1.map((d) => d.toDate());
};

export function roundDateToResolution(time, resolution, timezone = '+08:00') {
    const t = moment(time);
    const roundStart = timeStart(time, resolution, timezone);
    const roundEnd = timeEnd(time, resolution, timezone);

    return moment.duration(t.clone().diff(roundStart)) <
    moment.duration(roundEnd.clone().diff(t))
        ? roundStart
        : roundEnd;
}

export function roundRect(ctx, x, y, width, height, radius) {
    // https://stackoverflow.com/questions/1255512/how-to-draw-a-rounded-rectangle-on-html-canvas
    if (width < 2 * radius) radius = 0;
    if (height < 2 * radius) radius = 0;
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    return;
}

export const genColor = (i) => {
    var ret = [];
    const index = i + 1;
    if (index < 16777215) {
        ret.push(index & 0xff); // R
        ret.push((index & 0xff00) >> 8); // G
        ret.push((index & 0xff0000) >> 16); // B
    }
    var col = 'rgb(' + ret.join(',') + ')';
    return col;
};

export const clearCanvas = (ctx, w, h) => {
    ctx.fillStyle = '#fff';
    ctx.rect(0, 0, w, h);
    ctx.fill();
};

const formatMillisecond = d3.timeFormat('.%L'),
    formatSecond = d3.timeFormat(':%S'),
    formatMinute = d3.timeFormat('%H:%M'),
    formatHour = d3.timeFormat('%H:%M'),
    formatDay = d3.timeFormat('%b %d'),
    formatWeek = d3.timeFormat('%b %d'),
    formatMonth = d3.timeFormat('%b'),
    formatYear = d3.timeFormat('%Y');

export const multiFormat = (date) => {
    return (
        d3.timeSecond(date) < date
            ? formatMillisecond
            : d3.timeMinute(date) < date
                ? formatSecond
                : d3.timeHour(date) < date
                    ? formatMinute
                    : d3.timeDay(date) < date
                        ? formatHour
                        : d3.timeMonth(date) < date
                            ? d3.timeWeek(date) < date
                                ? formatDay
                                : formatWeek
                            : d3.timeYear(date) < date
                                ? formatMonth
                                : formatYear
    )(date);
};

export const generateContinuousDomain = (data, accessor, multiplier = 1.2) => {
    return data.length
        ? [
            d3.min([
                0,
                d3.min(data, accessor) < 0
                    ? d3.min(data, accessor) * multiplier
                    : d3.min(data, accessor) / multiplier,
            ]),
            d3.max(data, accessor) === 0
                ? 1
                : d3.max(data, accessor) < 0
                    ? d3.max(data, accessor) / multiplier
                    : d3.max(data, accessor) * multiplier,
        ]
        : [0, 1];
};

export const moveBrushSelection = (selection, target) => {
    selection.transition().call(d3.brushX().move, target)
}

export const cancelBrushSelection = (selection) => {
    moveBrushSelection(selection, null)
}

export const getLabelBrushRange = (d, window, options = {}) => {
    const from = options.fromAccessor ? options.fromAccessor(d) : d.from
    const to = options.toAccessor ? options.toAccessor(d) : d.to

    const [windowStartDate, windowEndDate] = window.map(d => moment(d))
    const [brushStartDate, brushEndDate] = [moment(from), moment(to)]

    return [
        moment.max(windowStartDate, brushStartDate).toDate(),
        moment.min(windowEndDate, brushEndDate).toDate(),
    ]
}

export const getDefaultChartType = (mode) => {
    if (mode === '3a' || mode === '4a' || mode === '5a' ) {
        return CONSTANTS.CHARTS.TYPES.LINE
    }

    return CONSTANTS.CHARTS.TYPES.COLUMN
}
