import React from 'react';
import VTable, { CustomFilterDropdown, CustomFilterIcon } from '../../components/VTable';
import { regexMatch, sortAscAlphabet } from '../../utils/helpers';
import useCurrentEntity from '../../../hooks/useCurrentEntity';
import './AssetChartsTable.scss'

const AssetChartsTable = (props) => {
    const { entityId } = useCurrentEntity();
    const assetId = props.asset.asset_id;

    return <VTable
        className="asset-charts-table"
        rowHeight={57}
        columns={[
            {
                title: "Chart",
                dataIndex: 'chart_title',
                width: 100,
            },
            {
                title: "Data Source",
                render: (text, record, index) => getDataSourceType(record),
                sorter: (a, b) => sortAscAlphabet(a, b, getDataSourceType),
                onFilter: (value, record) => regexMatch(getDataSourceType(record), value, { escape: true }),
                filterDropdown: CustomFilterDropdown,
                filterIcon: (filtered) => <CustomFilterIcon active={filtered} />,
                width: 60,
            },
            {
                title: "Reference",
                render: (text, record, index) => (
                    <div 
                        // className="asset-charts-item--with-overflow"
                        title={getDataReference(record)}
                    >
                        {getDataReference(record)}
                    </div>),
                sorter: (a, b) => sortAscAlphabet(a, b, getDataReference),
                onFilter: (value, record) => regexMatch(getDataReference(record), value, { escape: true }),
                filterDropdown: CustomFilterDropdown,
                filterIcon: (filtered) => <CustomFilterIcon active={filtered} />,
                width: 120,
            },
            {
                title: "Chart ID",
                width: 60,
                render: (text, record, index) => record.fusion_id || record.metadata_id,
            },
            {
                title: "API Endpoint - Data",
                width: 150,
                render: (text, record, index) => {
                    const value = record.fusion_id ? getAssetFusionsApiEndpoint(entityId, assetId) :  getAssetDeviceApiEndpoint(entityId, assetId);
                    return (
                        <div 
                            // className="asset-charts-item--with-overflow"
                            title={value}
                        >
                            {value}
                        </div>
                )}  
            },
        ]}
        dataSource={props.data}
        rowKey={i => i}
    />
}

export default AssetChartsTable

const CHART_DATA_TYPES = {
    'opcua': 'OPCUA',
    'rs232': 'RS232',
    'rs485': 'RS485',
    'fusion': 'Fusion',
    'i/o': 'I/O'
}

const getDataSourceType = (chart) => {
    if (chart.fusion_id) {
        return 'Fusion'
    }

    return CHART_DATA_TYPES[chart.type]
}

const getDataReference = (chart) => {
    if (chart.fusion_id) {
        return chart.fusion_metadata.map(getChannelReference).join('; ')
    }

    if (chart.type === 'i/o') {
        return getChannelReference(chart)
    }

    if (chart.type === 'opcua') {
        return chart.item_id;
    }

    return getDeviceReference(chart)
}

const getDeviceReference = (channel) => {
    return channel.ofDevice.mac_address;
}

const getChannelReference = (channel) => {
    return `${channel.ofDevice.mac_address}:${channel.channel}`
}

const getAssetDeviceApiEndpoint = (entity_id, asset_id) => `/v1/entity/${entity_id}/asset/${asset_id}/data`
const getAssetFusionsApiEndpoint = (entity_id, asset_id) => ` /v1/entity/${entity_id}/asset/${asset_id}/fusion_data`
// const getAssetOeeApiEndpoint = (entity_id, asset_id) => ` /v1/entity/${entity_id}/asset/${asset_id}/oee`