import { GatewayConstants as K } from './Gateway.constants';

export const addGateway = (entity_id, serial_number, callback) => {
    return {
        type: K.ACTIONS.ADD_GATEWAY_REQUEST,
        payload: { serial_number, entity_id },
        callback,
    };
};

export const SetGatewaysResource = (gateways) => {
    return {
        type: K.ACTIONS.SET_GATEWAYS_RESOURCE,
        payload: { gateways },
    };
};

export const AddGatewaysResource = (gateways) => {
    return {
        type: K.ACTIONS.ADD_GATEWAYS_RESOURCE,
        payload: { gateways },
    };
};

export const SetGatewaysStatusResource = (statuses) => {
    return {
        type: K.ACTIONS.SET_GATEWAYS_STATUS_RESOURCE,
        payload: { statuses },
    };
};

export const AddGatewaysStatusResource = (statuses) => {
    return {
        type: K.ACTIONS.ADD_GATEWAYS_RESOURCE,
        payload: { statuses },
    };
};

// GET ENTITY INFORMATION
export const GetEntityGatewaysStatus = (entity_id, callback) => {
    return {
        type: K.ACTIONS.GET_ENTITY_GATEWAY_STATUS_REQUEST,
        payload: { entity_id },
        callback,
    };
};
