/* eslint-disable react/prop-types */
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';

// COMPONENT
import AukTooltip from '../../components/AukTooltip';
import { Table } from 'reactstrap';
import { Permission } from '../../components/Permission';

// MODULES
import CreateEditPolicyModal from './modules/CreateEditPolicyModal/CreateEditPolicyModal';
import DeletePolicyModal from './modules/DeletePolicyModal';
import PermissionTableRow from './modules/PermissionTableRow';

// STYLE
import './index.scss';

// REDUX
import {
    getPoliciesRequest,
    getResourcesRequest,
} from '../../../store/old/Permission/Permission.action';
import { entityBlock } from '../../../store/old/Blocks/Blocks.selector';

// MODEL
import { Policy, ResourcePermission } from '../../models';
import { Button } from 'antd';
import { SPAWrapper } from '../../components/SPAWrapper';

class PermissionManagement extends Component {
    createPolicyModal = createRef();
    createEditPolicyModal = createRef();
    deletePolicyModal = createRef();
    resource_policies = [];

    componentDidMount() {
        const { getResources, getPolicies } = this.props;
        getResources();
        getPolicies();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const resourcesLength = Object.keys(nextProps.resources).length;
        const resourcesKeys = Object.keys(nextProps.resources);

        let resource_policies = [];

        for (let i = 0; i < resourcesLength; i++) {
            const resourcePermision = new ResourcePermission(
                nextProps.resources[Number(resourcesKeys[i])].resource_id,
                'nil',
                nextProps.resources[Number(resourcesKeys[i])].resource
            );

            resource_policies.push(resourcePermision);
        }

        this.resource_policies = resource_policies;
    }

    handleOpenInteractionPolicyModal = (action, policy) => {
        if (action === 'create') {
            const policyNewIns = new Policy(
                '',
                -1,
                [],
                [],
                [],
                this.resource_policies
            );

            return (
                this.createEditPolicyModal.current &&
        this.createEditPolicyModal.current.changeStateCreateEditPolicyModal(
            policyNewIns,
            action
        )
            );
        }

        if (action === 'edit') {
            const resourcesPolicySet = policy.resource_policies.reduce(
                (a, c) => a.add(Number(c.resource_id)),
                new Set()
            );

            const unExistedPolicy = this.resource_policies.filter((v) => {
                if (!resourcesPolicySet.has(v.resource_id)) {
                    return v;
                }
            });

            return (
                this.createEditPolicyModal.current &&
        this.createEditPolicyModal.current.changeStateCreateEditPolicyModal(
            policy,
            action,
            unExistedPolicy
        )
            );
        }
    };

    render() {
        const { policies, resources, rootBlock } = this.props;

        return (
            <SPAWrapper className="page_permission">
                <div className="d-flex flex-column justify-content-center h-100">
                    <Permission forResource resource="permissions" canDo="edit">
                        {Object.keys(resources).length > 0 ? (
                            <div className="mb-3 d-flex justify-content-end">
                                <AukTooltip.Help title="Create new policy">
                                    <Button
                                        className="auk-button auk-button--round"
                                        onClick={() =>
                                            this.handleOpenInteractionPolicyModal('create')
                                        }
                                    >
                                        <i className="fas fa-plus" />
                                    </Button>
                                </AukTooltip.Help>
                            </div>
                        ) : null}
                    </Permission>

                    {Object.keys(resources).length === 0 ? (
                        <div className="mb-3 d-flex">
              You do not have access to this feature. Please contact your sales
              representative for assistance
                        </div>
                    ) : null}

                    <div className='d-flex h-100' style={{overflow: 'auto'}}>
                    {Object.keys(resources).length > 0 ? (
                        <div className="permission-policies-table">
                            <Table responsive bordered>
                                <thead>
                                    <tr>
                                        <th className="policy-name">Policy Name</th>
                                        <th className="groups-list">Groups</th>
                                        <th className="actions">Actions</th>
                                    </tr>
                                </thead>

                                {policies.length > 0 ? (
                                    <tbody>
                                        {policies.map((policyItem) => (
                                            <PermissionTableRow
                                                key={policyItem.policy_id}
                                                policy={policyItem}
                                                rawArrayBlocks={[rootBlock]}
                                                openModalEdit={(policy) =>
                                                    this.handleOpenInteractionPolicyModal('edit', policy)
                                                }
                                                openModalDelete={(policy) =>
                                                    this.deletePolicyModal.current &&
                          this.deletePolicyModal.current.changeStateDeletePolicyModal(
                              policy
                          )
                                                }
                                            />
                                        ))}
                                    </tbody>
                                ) : null}

                                {policies.length === 0 ? (
                                    <tbody className="no-policy-data-body">
                                        <p className="no-policy-data-text">
                      There are no policies,{' '}
                                            <b
                                                className="create-policy-btn"
                                                onClick={() =>
                                                    this.handleOpenInteractionPolicyModal('create')
                                                }
                                            >
                        create new policy.
                                            </b>
                                        </p>
                                        <tr className="no-policy-data">
                                            <td className="policy-name" />
                                            <td className="policy-name" />
                                            <td className="policy-name" />
                                            <td className="policy-name" />
                                        </tr>
                                    </tbody>
                                ) : null}
                            </Table>
                        </div>
                    ) : null}
                    </div>
                </div>
                {/** here is create edit policy modal */}
                <CreateEditPolicyModal
                    rawArrayBlocks={[this.props.rootBlock]}
                    ref={this.createEditPolicyModal}
                />

                {/** here is delete policy modal */}
                <DeletePolicyModal ref={this.deletePolicyModal} />
            </SPAWrapper>
        );
    }
}

const mapStateToProps = (rootState) => {
    return {
        policies: rootState.permission.policies,
        resources: rootState.permission.resources,
        rootBlock: entityBlock(rootState),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPolicies: (callback) =>
            dispatch(getPoliciesRequest(callback)),
        getResources: (callback) =>
            dispatch(getResourcesRequest(callback)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PermissionManagement);
