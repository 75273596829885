/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import AukButton from '../../components/AukButton';
import AssetForm from '../Assets/AssetForm';
import DeviceConfiguration from './DeviceConfiguration';
import OpcuaForm from './components/OpcuaForm';
import ModalPanel from '../../components/ModalPanel';
import { PanelBody, PanelFooter, PanelHeader } from '../../components/Panel';
import { linkDevice, initializeDevice, createAssetOpcuaDevicesMetadata } from '../../../store/old/Devices/Devices.action';
import { DeviceConstants as K } from '../../../store/old/Devices/Devices.constants';
import DataInputsSelect from './components/DataInputsSelect';
import { DeviceScanNode } from './components/DeviceScan';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { createAsset } from '../../../store/old/Assets/Assets.action';
import useCurrentEntity from '../../../hooks/useCurrentEntity';
import { createTile } from '../../../store/old/Tiles/Tiles.action';
import { flash } from '../../components/Flash';

const ASSET_SETUP = 1;
const INPUT_SELECT = 2;
const DEVICE_INIT = 3.1;
const DEVICE_SETUP = 3.2;
const OPCUA_SETUP = 4;

const InitFlow = (props) => {
    const dispatch = useDispatch();
    const { entityId } = useCurrentEntity()

    const assetFormRef = useRef();
    const deviceInitFormRef = useRef();
    const opcuaFormRef = useRef()

    const [step, setStep] = useState(ASSET_SETUP);
    const [dataInputType, setDataInputType] = useState('')
    const [asset, setAsset] = useState(null);
    const [device, setDevice] = useState(null);

    if (step === ASSET_SETUP) {
        return (
            <ModalPanel>
                <PanelHeader closeIcon={true} handleClose={props.cancel}>Add Asset</PanelHeader>
                <PanelBody className="d-flex justify-content-center">
                    <AssetForm ref={assetFormRef} submit={(data) => {
                        dispatch(createAsset(entityId, data, (created) => {
                            dispatch(createTile(entityId, {
                                asset_id: created.asset.asset_id,
                                position_x: props.gridSelection.x,
                                position_y: props.gridSelection.y,
                                label: created.asset.asset_name
                            }))
                            setAsset(created.asset);
                            setStep(INPUT_SELECT);
                        }))
                    }}/> 
                </PanelBody>
                <PanelFooter className="p-3 d-flex justify-content-end">
                    <AukButton.Outlined onClick={() => { assetFormRef.current.form.submit() }}>
                        Save & Continue <ArrowRightOutlined/>
                    </AukButton.Outlined>
                </PanelFooter>
            </ModalPanel>
        );
    }

    if (step === INPUT_SELECT) {
        return (
            <ModalPanel>
                <PanelHeader closeIcon={true} handleClose={props.cancel}>Select Data Input</PanelHeader>
                <PanelBody className="d-flex justify-content-center">
                    <DataInputsSelect onChange={(inputType) => {
                        setDataInputType(inputType)

                        if (inputType !== 'OPCUA') {
                            setStep(DEVICE_INIT);
                            return;
                        }

                        setStep(OPCUA_SETUP)
                    }}/>
                </PanelBody>
                <PanelFooter className="p-3">
                    <AukButton.Outlined onClick={props.cancel}>Close</AukButton.Outlined>
                </PanelFooter>
            </ModalPanel>
        );
    }

    if (step === DEVICE_INIT) {
        return (
            <ModalPanel>
                <PanelHeader closeIcon={true} handleClose={props.cancel}>Initialize Device</PanelHeader>
                <PanelBody className="d-flex justify-content-center">
                    <DeviceScanNode 
                        forModel={K.MODELS.NODE}
                        ref={deviceInitFormRef} 
                        submit={(data) => {
                            dispatch(initializeDevice(entityId, data.dmac, data.gatewayId, { interface: dataInputType } ,(created) => {
                                dispatch(linkDevice(asset.asset_id, created.device_id, (updated) => {
                                    setDevice(updated);
                                    setStep(DEVICE_SETUP);
                                }))
                            }))
                        }}
                    />
                </PanelBody>
                <PanelFooter className="p-3 d-flex justify-content-between">
                    <AukButton.Outlined onClick={() => { setStep(INPUT_SELECT)}}>
                        <ArrowLeftOutlined/> Select Data Input
                    </AukButton.Outlined>
                    <AukButton.Outlined onClick={async () => { deviceInitFormRef.current.form.submit() }}>
                        Save & Continue <ArrowRightOutlined/>
                    </AukButton.Outlined>
                </PanelFooter>
            </ModalPanel>
        );
    }

    if (step === DEVICE_SETUP) {
        return (
            <ModalPanel>
                <PanelHeader closeIcon={true} handleClose={props.cancel}>Configure Device</PanelHeader>
                <PanelBody className="d-flex justify-content-center">
                    {device && (
                        <DeviceConfiguration
                            interface={dataInputType}
                            device={device}
                            onSubmit={props.cancel}
                            isInit={true}
                        />
                    )}
                </PanelBody>
            </ModalPanel>
        );
    }

    return (
        <ModalPanel className="opcua-dialog">
            <PanelHeader closeIcon={true} handleClose={props.cancel}>Configure OPCUA Inputs</PanelHeader>
            <PanelBody className="d-flex justify-content-center">
                <OpcuaForm 
                    ref={opcuaFormRef} 
                    submit={(data) => { 
                        dispatch(createAssetOpcuaDevicesMetadata(entityId, asset.asset_id, data, () => {
                            flash({ message: 'OPCUA inputs configured' });
                            props.cancel()
                        }))
                     }}
                />
            </PanelBody>

            <PanelFooter className="p-3 d-flex justify-content-between">
                <AukButton.Outlined onClick={() => { setStep(INPUT_SELECT)}}>
                    <ArrowLeftOutlined/> Select Data Input
                </AukButton.Outlined>
                <AukButton.Save onClick={() => { opcuaFormRef.current.form.submit() }}/>
            </PanelFooter>
        </ModalPanel>
    );
};

export default InitFlow;
