/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Input, Table } from 'antd';
import { AutoSizer, Grid } from 'react-virtualized';

import './VTable.scss';
import { SearchOutlined } from '@ant-design/icons';

const SCROLLBAR_SIZE = 10;

const VTable = (props) => {
    const { columns, scroll, rowHeight } = props;

    const [totalWidth, setTotalWidth] = useState(0);
    const [scrollbarPresent, setScrollbarPresent] = useState(false);

    const totalWidthParts = useMemo(
        () => columns.reduce((acc, curr) => (acc += curr.width || 1), 0),
        [columns]
    );

    const widthColumnCount = useMemo(
        () => columns.filter((c) => !c.width).length,
        [columns]
    );

    const getColWidth = useCallback(
        (col) => {
            if (col.width) {
                const extendedWidth = Math.floor(
                    (col.width / totalWidthParts) * totalWidth
                );
                return Math.max(extendedWidth, col.width);
            }

            return Math.floor(totalWidth / widthColumnCount);
        },
        [totalWidth, widthColumnCount, totalWidthParts]
    );

    const gridRef = useRef();

    useEffect(
        () => gridRef.current && gridRef.current.recomputeGridSize(),
        [totalWidth]
    );

    const renderVirtualList = useCallback(
        ({ width, height }) =>
            (rawData, { onScroll }) => {
                const headerHeight = 57;
                return (
                    <Grid
                        ref={gridRef}
                        columnCount={props.columns.length}
                        rowCount={rawData.length}
                //         columnWidth={({ index }) => {
                //             const cw = getColWidth(columns[index]);

                //             return rawData.length * rowHeight > height &&
                // index === columns.length - 1
                //                 ? cw - SCROLLBAR_SIZE
                //                 : cw;
                //         }}
                        columnWidth={({ index }) => { return getColWidth(columns[index])}}
                        onScrollbarPresenceChange={(d) => setScrollbarPresent(d.vertical)}
                        rowHeight={rowHeight}
                        width={scrollbarPresent ? width + SCROLLBAR_SIZE : width}
                        height={height - headerHeight}
                        onScroll={({ scrollLeft }) => onScroll({ scrollLeft })}
                        cellRenderer={({ columnIndex, key, rowIndex, style }) => {
                            const d = rawData[rowIndex];
                            const { dataIndex, render } = columns[columnIndex];
                            return (
                                <div
                                    className="vtable-cell"
                                    key={key}
                                    style={{
                                        ...style,
                                        padding: 8,
                                        // borderBottom: '1px solid #f0f0f0',
                                    }}
                                >
                                    {render ? render(d, d, rowIndex) : d[dataIndex]}
                                </div>
                            );
                        }}
                    />
                );
            },
        [totalWidth, scrollbarPresent]
    );

    return (
        <AutoSizer
            className="w-100 h-100"
            onResize={({ width }) => setTotalWidth(width)}
        >
            {({ width, height }) => (
                <Table
                    {...props}
                    style={{ width, height }}
                    className={`auk-virtual-table ${props.className || ''}`}
                    pagination={false}
                    components={{ body: renderVirtualList({ width, height }) }}
                    scroll={{
                        x: width,
                        y: height,
                    }}
                />
            )}
        </AutoSizer>
    );
};

VTable.defaultProps = {
    rowHeight: 47,
};

export default VTable;

export const CustomFilterDropdown = (props) => {
    const { setSelectedKeys, selectedKeys, confirm, clearFilters, close } = props;

    const onChange = (value) => {
        setSelectedKeys([value]);
        confirm({
            closeDropdown: false,
        });
    };

    return (
        <div
            style={{
                padding: 8,
            }}
            onKeyDown={(e) => e.stopPropagation()}
        >
            <Input
                autoFocus
                placeholder="Search"
                value={selectedKeys[0]}
                onChange={(e) => onChange(e.target.value)}
                style={{
                    marginBottom: 8,
                    display: 'block',
                }}
            />
        </div>
    );
};

export const CustomFilterIcon = (props) => (
    <SearchOutlined style={{ color: props.active ? '#1890ff' : undefined }} />
);
