export const TilesConstants = {
    ACTIONS: {
        SET_DASHBOARD_TILES_STATE: 'SET_DASHBOARD_TILES_STATE',
        RESET_DASHBOARD_TILES_STATE: 'RESET_DASHBOARD_TILES_STATE',
        ADD_TILES_STATE: 'ADD_TILES_STATE',
        SET_TILES_STATE: 'SET_TILES_STATE',
        DELETE_TILES_STATE: 'DELETE_TILES_STATE',
        GET_ALL_SUMMARY_TILES_REQUEST: 'GET_ALL_SUMMARY_TILES_REQUEST',
        GET_ALL_SUMMARY_TILES_SUCCESS: 'GET_ALL_SUMMARY_TILES_SUCCESS',
        GET_ALL_SUMMARY_TILES_FAILURE: 'GET_ALL_SUMMARY_TILES_FAILURE',
        CREATE_SUMMARY_TILE_REQUEST: 'CREATE_SUMMARY_TILE_REQUEST',
        CREATE_SUMMARY_TILE_SUCCESS: 'CREATE_SUMMARY_TILE_SUCCESS',
        CREATE_SUMMARY_TILE_FAILURE: 'CREATE_SUMMARY_TILE_FAILURE',
        EDIT_SUMMARY_TILE_REQUEST: 'EDIT_SUMMARY_TILE_REQUEST',
        EDIT_SUMMARY_TILE_SUCCESS: 'EDIT_SUMMARY_TILE_SUCCESS',
        EDIT_SUMMARY_TILE_FAILURE: 'EDIT_SUMMARY_TILE_FAILURE',
        DELETE_SUMMARY_TILE_REQUEST: 'DELETE_SUMMARY_TILE_REQUEST',
        DELETE_SUMMARY_TILE_SUCCESS: 'DELETE_SUMMARY_TILE_SUCCESS',
        DELETE_SUMMARY_TILE_FAILURE: 'DELETE_SUMMARY_TILE_FAILURE',
        EDIT_TILE_REQUEST: 'EDIT_TILE_REQUEST',
        EDIT_TILE_SUCCESS: 'EDIT_TILE_SUCCESS',
        UPDATE_TILE_POSITION_FAILURE: 'UPDATE_TILE_POSITION_FAILURE',
        CREATE_TILE_REQUEST: 'CREATE_TILE_REQUEST'
    },
};
