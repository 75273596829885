/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from 'react';
import { keys } from 'lodash';
import { Form, Input } from 'antd';
import ChannelRow, { ChannelHeader } from './ChannelRow';
import { getMapFromArr, regexMatch } from '../../../utils/helpers';
import { getDefaultSpeedAttribute } from '../../../models';
import { DeviceConstants as K } from '../../../../store/old/Devices/Devices.constants';
import { getChannelRows, getChannelsFormData } from '../../../utils/device';

const NODE_CHANNELS = 6;
const channelRows = getChannelRows(NODE_CHANNELS)

const IoForm = forwardRef((props, ref) => {
    const [form] = Form.useForm();

    const channels = getMapFromArr(props.data, 'channel');

    useEffect(() => props.onChange && props.onChange(getFormData(form.getFieldsValue(true))), []);

    const getFormData = useCallback((data) => getChannelsFormData(data), []);

    useImperativeHandle(ref, () => ({
        getFormData() {
            return getFormData(form.getFieldsValue(true));
        },
    }));

    const isChangedChannel = (obj) => {
        const [channel] = keys(obj).filter((k) => regexMatch(k, /\d/g));
        return channel;
    };

    return (
        <Form
            ref={ref}
            name="ioForm"
            form={form}
            className="d-flex w-100 h-100 flex-column justify-content-between"
            initialValues={{ remember: true }}
            onValuesChange={(changedValues) => {
                const channel = isChangedChannel(changedValues);
                if (channel && changedValues[channel].mode) {
                    form.setFields([
                        {
                            name: [channel, 'speed'],
                            value: getDefaultSpeedAttribute(changedValues[channel].mode),
                        },
                    ]);
                }

                props.onChange && props.onChange(getFormData(form.getFieldsValue(true)));
            }}
            hidden={props.hidden}
            preserve
            key={props.data.length}
        >
            <Form.Item noStyle name="type" initialValue={K.IO} hidden={true}>
                <Input disabled />
            </Form.Item>
            <ChannelHeader hideMapping={true} />
            {channelRows.map((c) => (
                <Form.Item noStyle name={`${c}`} key={c}>
                    <ChannelRow
                        form={form}
                        key={c}
                        data={channels[+c]}
                        channel={+c}
                        isSerial={false}
                        handleDelete={() => {
                            form.setFields([
                                { name: [`${c}`, 'chart_title'], value: '', errors: [] },
                                { name: [`${c}`, 'mode'], value: null, errors: [] },
                                { name: [`${c}`, 'batRec', 'upp'], value: 99999, errors: [] },
                                { name: [`${c}`, 'batRec', 'low'], value: 0.001, errors: [] },
                                { name: [`${c}`, 'batRec', 'in'], value: 1, errors: [] },
                                { name: [`${c}`, 'batRec', 'out'], value: 1, errors: [] },
                            ]);
                            props.onChange && props.onChange(getFormData(form.getFieldsValue(true)));
                        }}
                    />
                </Form.Item>
            ))}
        </Form>
    );
});

export default IoForm;
