/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { isEmpty, keys } from 'lodash';
import { useDispatch } from 'react-redux';
import { Form, Input } from 'antd';
import ChannelRow, { ChannelHeader } from './ChannelRow';
import AukButton from '../../../components/AukButton';
import { DeviceConstants as K } from '../../../../store/old/Devices/Devices.constants';
import { getMapFromArr, regexMatch } from '../../../utils/helpers';
import { getDefaultSpeedAttribute } from '../../../models';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { getChannelRows, getChannelsFormData } from '../../../utils/device';
import SerialPreview from './SerialPreview';

const CHANNELS_VALIDATE = {
    SLAVE_ID: [
        (form) => ({
            validator: (_, value) => {
                if (value === '') {
                    return Promise.reject(new Error('Slave ID is required'));
                }

                if (+value < 0 || +value > 255)
                    return Promise.reject(
                        new Error('Slave ID must be between 0 and 255')
                    );
                return Promise.resolve();
            },
        }),
    ],
    ADDRESS: [
        (form) => ({
            validator: (_, value) => {
                if (!value.length) {
                    return Promise.reject(
                        new Error('Must have a minimum of one register.')
                    );
                }

                return Promise.resolve();
            },
        }),
    ],
    REG: (fieldName) => [
        {
            transform: (value) => +value,
            type: 'number',
            min: 0,
            message: 'Register address must be greater than 0',
        },
        {
            transform: (value) => +value,
            type: 'number',
            max: 65535,
            message: 'Register address cannot be greater than 65535',
        },
        (form) => ({
            validator: (_, value) => {
                const addresses = form.getFieldValue('address');
                const address = addresses[fieldName];

                if (+value < 0 || +value < 65535) {
                }

                if (value && address.regSize === undefined) {
                    return Promise.resolve(
                        form.setFields([
                            {
                                name: ['address', fieldName, 'regSize'],
                                errors: ['Register size is required'],
                            },
                        ])
                    );
                }

                return Promise.resolve();
            },
        }),
    ],
    REG_SIZE: (fieldName) => [
        {
            transform: (value) => +value,
            type: 'number',
            min: 0,
            message: 'Register size must be greater than 0',
        },
        {
            transform: (value) => +value,
            type: 'number',
            max: 65535,
            message: 'Register size cannot be greater than 65535',
        },
        (form) => ({
            validator: (_, value) => {
                const addresses = form.getFieldValue('address');
                const address = addresses[fieldName];

                if (value && address.reg === undefined) {
                    return Promise.resolve(
                        form.setFields([
                            {
                                name: ['address', fieldName, 'reg'],
                                errors: ['Register address is required'],
                            },
                        ])
                    );
                }

                return Promise.resolve();
            },
        }),
    ],
};

const Rs485Form = forwardRef((props, ref) => {
    const [form] = Form.useForm();

    const [preview, setPreview] = useState('');
    const [serialMap, setSerialMap] = useState(props.device.channelSerialMap);

    const channelRows = useMemo(() => {
        return getChannelRows(keys(serialMap).length)
    }, [preview, serialMap]);

    const channels = getMapFromArr(props.data, 'channel');

    const getSerialPreviewData = () => {
        const data = form.getFieldsValue(true);

        const { reg, regSize } = getRegAddress(data.address);

        return {
            address: {
                reg,
                regSize,
                slaveId: data.slaveId,
            },
        };
    };

    useEffect(() => {
        if (!preview) return;

        const data = form.getFieldsValue(true);

        const previewResult = preview.match(K.REGEX_OPTIONS[0].rawLabel) || [];

        const _serialMap = previewResult.reduce(
            (acc, curr, index) => ({
                ...acc,
                [index]: [data.address[index].reg, +curr],
            }),
            {}
        );

        setSerialMap(_serialMap);
    }, [preview]);

    useEffect(
        () => props.onChange && props.onChange(getFormData(form.getFieldsValue(true))),
        [channelRows]
    );

    const getRegAddress = (registers) => {
        const reg = registers.map((r) => r.reg);
        const regSize = registers.map((r) => r.regSize);
        return { reg, regSize };
    };

    const getSerialOrder = (metadata) => {
        return metadata.reduce(
            (acc, curr) => ({
                ...acc,
                [curr.channel]: { ch: `${curr.channel}`, mode: curr.mode },
            }),
            {}
        );
    };

    const getSerialData = (formData, metadata) => {
        return {
            dmode: K.DMODE_POLL,
            data: [
                {
                    address: {
                        ...getRegAddress(formData.address.filter((a) => a)),
                        slaveId: formData.slaveId,
                    },
                    map: getSerialOrder(metadata),
                    regex: K.REGEX_OPTIONS[0].label,
                },
            ],
        };
    };

    const getFormData = (data) => {
        let metadata = getChannelsFormData(data);
        metadata = metadata.slice(0, channelRows.length);
        return { metadata, serial: getSerialData(data, metadata) };
    };

    useImperativeHandle(ref, () => ({
        getFormData() {
            return getFormData(form.getFieldsValue(true));
        },
    }));

    const isChangedChannel = (obj) => {
        const [channel] = keys(obj).filter((k) => regexMatch(k, /\d/g));
        return channel;
    };

    // useEffect(() => {
    //     form.setFields([{ name: 'type', value: interfaceType }]);
    // }, [form, interfaceType]);

    return (
        <Form
            ref={ref}
            name="rs485Form"
            form={form}
            className="d-flex w-100 h-100 flex-column justify-content-between"
            initialValues={{ remember: true }}
            onValuesChange={(changedValues) => {
                const channel = isChangedChannel(changedValues);
                if (channel && changedValues[channel].mode) {
                    form.setFields([
                        {
                            name: [channel, 'speed'],
                            value: getDefaultSpeedAttribute(changedValues[channel].mode),
                        },
                    ]);
                }

                props.onChange && props.onChange(getFormData(form.getFieldsValue(true)));
            }}
            hidden={props.hidden}
            preserve
            key={props.data.length}
        >
            <Form.Item noStyle name="type" initialValue={K.RS485} hidden={true}>
                <Input disabled />
            </Form.Item>
            <Form.Item
                name="slaveId"
                label="Slave ID"
                initialValue={props.device.slaveId}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                rules={CHANNELS_VALIDATE.SLAVE_ID}
            >
                <Input className="w-100" type="number" />
            </Form.Item>
            <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Registers"
            >
                <Form.List
                    name="address"
                    initialValue={props.device.registers}
                    rules={CHANNELS_VALIDATE.ADDRESS}
                >
                    {(fields, { add, remove }, { errors }) => {
                        return (
                            <>
                                {fields.map((field, i) => {
                                    return (
                                        <Form.Item noStyle key={i}>
                                            <Input.Group className="d-flex align-items-center">
                                                <Form.Item
                                                    className="pr-2"
                                                    style={{ flexGrow: 1 }}
                                                    name={[field.name, 'reg']}
                                                    rules={CHANNELS_VALIDATE.REG(field.name)}
                                                >
                                                    <Input
                                                        addonBefore={
                                                            <span style={{ width: 50 }}>Address</span>
                                                        }
                                                        type="number"
                                                        className="w-100"
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    className="pl-2"
                                                    style={{ flexGrow: 1 }}
                                                    name={[field.name, 'regSize']}
                                                    rules={CHANNELS_VALIDATE.REG_SIZE(field.name)}
                                                >
                                                    <Input
                                                        addonBefore={
                                                            <span style={{ width: 50 }}>Size</span>
                                                        }
                                                        type="number"
                                                        className="w-100"
                                                    />
                                                </Form.Item>
                                                <Form.Item className="mx-3">
                                                    <MinusCircleOutlined
                                                        onClick={() => remove(field.name)}
                                                    />
                                                </Form.Item>
                                            </Input.Group>
                                        </Form.Item>
                                    );
                                })}

                                <Form.Item>
                                    <AukButton.Outlined
                                        type="dashed"
                                        onClick={() => add()}
                                        block
                                        icon={<PlusOutlined />}
                                    >
                    Add Address
                                    </AukButton.Outlined>
                                    <Form.ErrorList errors={errors} />
                                </Form.Item>
                            </>
                        );
                    }}
                </Form.List>
            </Form.Item>
            <SerialPreview
                device={props.device}
                onChange={setPreview}
                getDataForPreview={getSerialPreviewData}
                updateBeforePreview={props.isInit}
                updateDevice={props.updateDevice}
            />
            <hr style={{ width: '60%' }} />
            <ChannelHeader hideMapping={isEmpty(serialMap)} />
            {channelRows.map((c) => (
                <Form.Item noStyle name={`${c}`} key={c}>
                    <ChannelRow
                        form={form}
                        key={c}
                        data={channels[+c]}
                        channel={+c}
                        serialMap={serialMap}
                        isSerial={true}
                        handleDelete={() => {
                            form.setFields([
                                { name: [`${c}`, 'chart_title'], value: '', errors: [] },
                                { name: [`${c}`, 'mode'], value: null, errors: [] },
                                { name: [`${c}`, 'batRec', 'upp'], value: 99999, errors: [] },
                                { name: [`${c}`, 'batRec', 'low'], value: 0.001, errors: [] },
                                { name: [`${c}`, 'batRec', 'in'], value: 1, errors: [] },
                                { name: [`${c}`, 'batRec', 'out'], value: 1, errors: [] },
                            ]);
                            props.onChange && props.onChange(getFormData(form.getFieldsValue(true)));
                        }}
                    />
                </Form.Item>
            ))}
        </Form>
    );
});

export default Rs485Form;
