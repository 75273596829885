import { all, call, put, takeLatest } from 'redux-saga/effects';
import _, { difference } from 'lodash';

import { DeviceConstants as K } from './Devices.constants';
import { store } from '../..';

// DEVICE SERVICES
import {
    api_previewSerialData,
    api_updateDevice,
    api_initDevice,
    api_updateDeviceEntity,
    api_initializeDeviceAsRelay,
    api_linkDevice,
    api_unlinkDevice,
    api_resetDevice,
    api_createAssetInitOpcuaDevice,
} from './Devices.services';

import { Device, Metadatum, parseDeviceArguments, parseMetadatumArguments } from '../../../legacy/models';
import { addDevicesResource, cascadeUnlinkDevice, removeDevicesResource } from './Devices.action';
import { removeMetadataResource, setMetadataResource } from '../Metadata/Metadata.action';
import { getMapFromArr } from '../../../legacy/utils/helpers';
import { errorFlash, flash } from '../../../legacy/components/Flash';
import { currentEntitySelector } from '../Entity/Entity.selector';

// PREVIEW DATA
function* handlePreviewData(data) {
    if (data.payload.data && Object.keys(data.payload.data).length === 0) {
        errorFlash({
            message:
        'Ensure that Serial Device is configured to "Print" mode and press the Print Button on Serial Device',
        });
    }

    try {
        const response = yield api_previewSerialData(data.payload);

        if (data.callback) {
            yield data.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* previewData() {
    yield takeLatest(
        K.ACTIONS.PREVIEW_SERIAL_COMMUNICATIONS_DATA,
        handlePreviewData
    );
}

// UPDATE DEVICE ENTITY
function* handleUpdateDeviceEntity(action) {
    try {
        const { mac_address } = action.payload;
        const { entity_id } = currentEntitySelector(store.getState());

        const result = yield api_updateDeviceEntity(entity_id, mac_address);
        const device = new Device(...parseDeviceArguments(result));

        yield put(addDevicesResource({ [device.device_id]: device }));

        if (action.callback) {
            yield action.callback(device);
        }

        return device;
    } catch (error) {
        errorFlash(error);
    }
}

export function* updateDeviceEntitySaga() {
    yield takeLatest(
        K.ACTIONS.UPDATE_DEVICE_ENTITY_REQUEST,
        handleUpdateDeviceEntity
    );
}

// INITIALIZE AS RELAY
function* handleInitializeDeviceAsRelay(action) {
    try {
        const { mac_address, gateway_id } = action.payload;

        const response = yield* handleUpdateDeviceEntity({
            payload: { mac_address },
        });

        if (!response) throw { message: 'Invalid mac address' };

        const _relay = yield api_initializeDeviceAsRelay(
            response.device_id,
            gateway_id
        );

        const relay = new Device(...parseDeviceArguments(_relay));
        yield put(addDevicesResource({ [relay.device_id]: relay }));

        flash({ message: 'Relay initialized', status: 'success' });

        if (action.callback) {
            yield action.callback();
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* initializeDeviceAsRelaySaga() {
    yield takeLatest(
        K.ACTIONS.INITIALIZE_RELAY_REQUEST,
        handleInitializeDeviceAsRelay
    );
}

// REMOVE RELAY
function* handleRemoveRelay(action) {
    try {
        const { device_id } = action.payload;

        yield call(api_resetDevice, device_id);

        flash({ message: 'Relay removed', status: 'success' });

        yield put(removeDevicesResource([device_id]));

        if (action.callback) {
            yield action.callback();
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* removeRelaySaga() {
    yield takeLatest(
        K.ACTIONS.REMOVE_RELAY_REQUEST,
        handleRemoveRelay
    );
}

// UPDATE DEVICE
function* handleUpdateDevice(data) {
    try {
        const appState = store.getState();
        const originalDevice = appState.devices.devices[data.payload.device_id];

        const clonedMetadataState = { ...appState.metadata.metadata };

        // remove old metadata (in case of deleted channels)
        originalDevice.metadata.forEach(
            ({ metadata_id }) => delete clonedMetadataState[metadata_id]
        );

        const response = yield api_updateDevice(data.payload);
        const device = new Device(...parseDeviceArguments(response.device));

        delete response.metadata.device_id;
        const metadata = getMapFromArr(
            response.device.metadata.map((m) => {
                m.device_id = device.device_id;
                return new Metadatum(...parseMetadatumArguments(m));
            }),
            'metadata_id'
        );

        yield all([
            put(addDevicesResource({ [device.device_id]: device })),
            put(setMetadataResource({ ...clonedMetadataState, ...metadata })),
        ]);

        flash({
            message: 'Device successfully updated',
            status: 'success',
        });

        if (data.callback) {
            yield data.callback();
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* updateDeviceRequestSaga() {
    yield takeLatest(
        K.ACTIONS.UPDATE_DEVICE_REQUEST,
        handleUpdateDevice
    );
}

function* handleInitializeDevice(action) {
    try {
        const { payload } = action;

        let device = yield call(api_updateDeviceEntity, payload.entity_id, payload.mac_address)
        device = yield call(api_initDevice, payload.entity_id, device.device_id, {
            device: {
                sampling_rate: 15,
                gateway_id: payload.gateway_id,
                ...payload.options
            }
        });

        put(addDevicesResource({ [device.device_id]: device }));

        if (action.callback) {
            action.callback(device)
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* initDeviceRequestSaga() {
    yield takeLatest(
        K.ACTIONS.INITIALIZE_DEVICE_REQUEST,
        handleInitializeDevice
    );
}

// LINK DEVICE
function* handleLinkDevice(action) {
    try {
        const { entity_id } = currentEntitySelector(store.getState());
        const { asset_id, device_id } = action.payload;

        const response = yield call(api_linkDevice, entity_id, asset_id, device_id);
        const device = new Device(...parseDeviceArguments(response));

        yield put(addDevicesResource({ [device.device_id]: device }));
        flash({ message: 'Device linked', status: 'success' });

        if (action.callback) {
            yield action.callback(device);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* LinkDeviceRequestSaga() {
    yield takeLatest(
        K.ACTIONS.LINK_DEVICE_REQUEST,
        handleLinkDevice
    );
}

// UNLINK DEVICE
function* handleUnlinkDevice(action) {
    try {
        const { entity_id } = currentEntitySelector(store.getState());
        const { asset_id, device_id } = action.payload;

        yield call(
            api_unlinkDevice,
            entity_id,
            asset_id,
            device_id
        );

        const { devices } = store.getState().devices;
        const device = devices[device_id];
        yield put(cascadeUnlinkDevice(device));
        flash({ message: 'Device unlinked', status: 'success' });

        if (action.callback) {
            yield action.callback();
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* UnlinkDeviceRequestSaga() {
    yield takeLatest(
        K.ACTIONS.UNLINK_DEVICE_REQUEST,
        handleUnlinkDevice
    );
}

function* handleCreateAssetOpcuaDeviceMetadata(action) {
    try {
        const { payload } = action;

        const createArray = payload.devices;

        for (let i = 0; i < createArray.length; i++) {
            let device = {
                serial_number: createArray[i].device.mac_address,
                ...createArray[i].device,
                ...K.OPCUA.BASE_DEVICE_CONFIG
            }
            const created = yield call(api_createAssetInitOpcuaDevice, payload.entity_id, payload.asset_id, device);
            device = new Device(...parseDeviceArguments(created))

            yield put(addDevicesResource({ [device.device_id]: device }))

            yield* handleUpdateDevice({ payload: {
                device_id: device.device_id,
                data: {
                    device: {},
                    metadata: createArray[i].metadata
                }
            }})
        }

        if (action.callback) {
            action.callback()
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* createAssetOpcuaDevicesMetadataSaga() {
    yield takeLatest(
        K.ACTIONS.CREATE_ASSET_OPCUA_DEVICES_METADATA,
        handleCreateAssetOpcuaDeviceMetadata
    );
}

function* handleUpdateAssetOpcuaDeviceMetadata(action) {
    try {
        const { payload } = action;

        const appState = store.getState()
        const { assets } = appState.assets;
        const asset = assets[payload.asset_id];

        const createArray = payload.devices.filter(d => !d.device.device_id);
        const updateArray = payload.devices.filter(d => d.device.device_id);

        const updateDeviceIds = updateArray.map(d => d.device.device_id);
        const currentOpcuaDeviceIds = asset.opcua.map(d => d.device_id)
        const deleteArray = difference(currentOpcuaDeviceIds, updateDeviceIds);

        // call unlink device saga handler
        for (let i = 0; i < deleteArray.length; i++) {
            yield* handleUnlinkDevice({ payload: { asset_id: payload.asset_id, device_id: deleteArray[i] }});
        }

        // call update device saga handler
        for (let i = 0; i < updateArray.length; i++) {
            yield* handleUpdateDevice({ payload: {
                device_id: updateArray[i].device.device_id,
                data: {
                    device: updateArray[i].device,
                    metadata: updateArray[i].metadata
                }
            }});
        }

        yield* handleCreateAssetOpcuaDeviceMetadata({ payload: { 
            entity_id: payload.entity_id, 
            asset_id: payload.asset_id, 
            devices: createArray 
        }})

        if (action.callback) {
            action.callback()
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* updateAssetOpcuaDevicesMetadataSaga() {
    yield takeLatest(
        K.ACTIONS.UPDATE_ASSET_OPCUA_DEVICES_METADATA,
        handleUpdateAssetOpcuaDeviceMetadata
    );
}

function* handleUnlinkDeviceCascade(action) {
    try {
        const { device } = action.payload;

        const metadata_ids = device.metadata.map((m) => m.metadata_id);

        yield all([
            put(removeDevicesResource([device.device_id])),
            put(removeMetadataResource(metadata_ids)),
        ]);
    } catch (error) {
        errorFlash(error);
    }
}

export function* UnlinkDeviceCascadeSaga() {
    yield takeLatest(
        K.ACTIONS.CASCADE_UNLINK_DEVICE,
        handleUnlinkDeviceCascade
    );
}
