import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
    PlusOutlined,
    TableOutlined,
} from '@ant-design/icons';
import { Input, Layout, Modal, Tabs } from 'antd';
import { isNumber, keys, mapValues, pick, pickBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import AukButton from '../../components/AukButton';
import HardwareItem from './HardwareItem';
import VList from '../../components/VList';
import AukTooltip from '../../components/AukTooltip';
import { errorFlash } from '../../components/Flash';
import { HardwareMesh, HardwareMeshTable } from './HardwareMesh';
import { SPAWrapper } from '../../components/SPAWrapper';
import { HardwareManagementContext } from './HardwareManagementContext';
import { Colors } from '../../Charts';
import { DeviceConstants as K } from '../../../store/old/Devices/Devices.constants';
import { regexMatch } from '../../utils/helpers';
import { arrayDevices } from '../../../store/old/Devices/Devices.selector';
import { arrayGateways } from '../../../store/old/Gateway/Gateway.selector';
import { initializeDeviceAsRelay, removeRelay } from '../../../store/old/Devices/Devices.action';
import { api_getGatewayMeshData } from '../../../store/old/Gateway/Gateway.services';
import { api_refreshMeshNode } from '../../../store/old/Devices/Devices.services';
import UndirectedGraph from '../../utils/UndirectedGraph';
import './HardwareManagement.scss';
import { saveCsv } from '../../utils/dataExports';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';
import { GetEntityGatewaysStatus } from '../../../store/old/Gateway/Gateway.action';
import { Permission, RolePermission } from '../../components/Permission';
import { userSelector } from '../../../store/old/Authentication/Authentication.selector';
import translate from '../../utils/translate';
import { DeviceScanGateway, DeviceScanNode } from './components/DeviceScan';

const NODE_TYPES = {
    GATEWAY: 'gateway',
    NODE: 'node',
    RELAY: 'relay',
};

const getNodeId = (n) => {
    switch (n.type) {
    case NODE_TYPES.GATEWAY:
        return `"Gateway (${n.node})"`;
    case NODE_TYPES.RELAY:
        return `"Relay (${n.node})"`;
    default:
        return `"${n.asset_name} (${n.node})"`;
    }
};

const NODE_COLORS = {
    [NODE_TYPES.NODE]: '#6761A8',
    [NODE_TYPES.GATEWAY]: '#009B72',
    [NODE_TYPES.RELAY]: '#F26430',
};

const { Sider, Content } = Layout;
const { TabPane } = Tabs;

const VIEWS = {
    FORCE: '0',
    TABLE: '1',
};

const WEAK_SIGNAL_THRESHOLD = 70;

let addGatewayIndex = -1;

const HardwareManagement = () => {
    const dispatch = useDispatch();
    const gateways = useSelector(arrayGateways);
    const devices = useSelector(arrayDevices);
    const authUser = useSelector(userSelector);
    const { entity_id } = useSelector(currentEntitySelector);

    const physicalGateways = useMemo(() => gateways.filter(gw => gw.interface !== 'opcua'), [gateways])

    const [search, setSearch] = useState('');
    const [view, setView] = useState(VIEWS.FORCE);
    const [data, setData] = useState({ vertices: [], edges: {} });
    const [deviceSelection, setDeviceSelection] = useState(null);
    const [gatewaySelection, setGatewaySelection] = useState(
        physicalGateways.length ? `${physicalGateways[0].device_id}` : null
    );
    const [addGatewaySelection, setAddGatewaySelection] = useState([]);
    const [showAddRelay, setShowAddRelay] = useState(false);

    const changeGatewaySelection = useCallback((g) => {
        setSearch('');
        setDeviceSelection(null);
        setGatewaySelection(g);
    }, []);

    const addTab = () => {
        const key = `${addGatewayIndex}`;
        const newTab = { title: 'Add Gateway', key };

        setAddGatewaySelection(addGatewaySelection.concat(newTab));
        changeGatewaySelection(key);

        addGatewayIndex--;
    };

    const removeTab = useCallback(
        (targetKey) => {
            setAddGatewaySelection(
                addGatewaySelection.filter((s) => s.key !== targetKey)
            );
            changeGatewaySelection(
                physicalGateways.length ? `${physicalGateways[0].device_id}` : null
            );
        },
        [physicalGateways]
    );

    const onEditTabs = (targetKey, action) => {
        if (action === 'add') addTab();
        if (action === 'remove') removeTab(targetKey);
    };

    const onGatewayAdded =
    (targetKey) =>
        (result) => {
            removeTab(targetKey);
            changeGatewaySelection(`${result.device.device_id}`);
            dispatch(GetEntityGatewaysStatus(entity_id));
        };

    const changeDeviceSelection = useCallback(
        (device, path, health) => {
            if (deviceSelection && deviceSelection.device === device) {
                return setDeviceSelection(null);
            }

            setDeviceSelection({ device, path, health });
        },
        [deviceSelection]
    );

    const closeAddRelay = () => setShowAddRelay(false);

    // services
    const fetchMeshData = async (gw_id) => {
        try {
            if (!gw_id || gw_id < 0) return;
            const result = await api_getGatewayMeshData(gw_id);
            setData(prepareGraphData(result));
        } catch (e) {
            errorFlash(e);
        }
    };

    const refresh = useCallback(
        () => fetchMeshData(+gatewaySelection),
        [gatewaySelection]
    );

    useEffect(() => {
        refresh()
    }, [gatewaySelection]);

    const refreshNode = useCallback(
        (dmac) => {
            if (!gatewaySelection) return;
            api_refreshMeshNode(+gatewaySelection, dmac)
                .then(refresh)
                .catch((e) => errorFlash(e));
        },
        [gatewaySelection]
    );

    const deleteRelay = useCallback(
        (device_id) => {
            if (!gatewaySelection) return;

            dispatch(
                removeRelay(device_id, () => {
                    refresh();
                })
            );
        },
        [gatewaySelection]
    );

    // graph, dijkstra, force chart
    const filteredEdges = useMemo(
        () => pickBy(data.edges, (e) => e.rssi < WEAK_SIGNAL_THRESHOLD),
        [data]
    );

    const graph = useMemo(() => {
        return new UndirectedGraph(
            data.vertices.map(({ node }) => node),
            mapValues(filteredEdges, ({ weight }) => weight)
        );
    }, [data]);

    const chartNodes = useMemo(() => {
        if (!deviceSelection) return data.vertices;
        const { path } = deviceSelection.path || { path: [] };
        const nodeSet = new Set(path);

        return data.vertices.filter((v) => nodeSet.has(v.node));
    }, [deviceSelection, data]);

    const chartEdges = useMemo(() => {
        if (!deviceSelection) return filteredEdges;
        const { path } = deviceSelection.path || { path: [] };

        let i = 0;
        let edgeKeys = [];
        while (i < path.length - 1) {
            const curr = path[i];
            const next = path[i + 1];
            const k1 = getEdgePath(curr, next);
            const k2 = getEdgePath(next, curr);

            edgeKeys.push(k1);
            edgeKeys.push(k2);
            i++;
        }

        return pick(filteredEdges, edgeKeys);
    }, [deviceSelection, filteredEdges]);

    const gw = useMemo(
        () => physicalGateways.find((g) => g.device_id === +gatewaySelection),
        [gatewaySelection]
    );

    const gatewayToNodes = useMemo(() => {
        return gw ? graph.dijkstra(gw.mac_address) : {};
    }, [graph, gw]);

    // table data
    const tableData = useMemo(() => {
        const links = keys(data.edges).reduce((acc, curr) => {
            const [source, target] = curr.split('-');
            acc[source] = acc[source]
                ? { ...acc[source], [target]: data.edges[curr].rssi }
                : { [target]: data.edges[curr].rssi };
            return acc;
        }, {});

        const cellValues = data.vertices.map((source) =>
            data.vertices.map((target) =>
                links[source.node] && isNumber(links[source.node][target.node])
                    ? -links[source.node][target.node]
                    : ''
            )
        );

        return { nodes: data.vertices, cellValues };
    }, [data]);

    // csv is based on table representation
    const csvString = useCallback(() => {
        const header = ',' + tableData.nodes.map(getNodeId).join(',');

        const rows = tableData.nodes
            .map((n, i) => {
                return `${getNodeId(n)},${tableData.cellValues[i].join(',')}`;
            })
            .join('\n');

        return header + '\n' + rows;
    }, [tableData]);

    // list of devices connected to respective gateway
    const gatewaysDevices = useMemo(() => {
        return devices.reduce((acc, curr) => {
            const { gateway_id } = curr;
            acc[gateway_id] = acc[gateway_id] ? acc[gateway_id].concat(curr) : [curr];

            return acc;
        }, {});
    }, [devices]);

    const context = {
        selection: deviceSelection,
        refreshNode,
        deleteRelay,
        NODE_TYPES,
        NODE_COLORS,
    };

    const hasDevicesEditAccess = authUser.check_resource_policy(
        'devices',
        false,
        true,
        false
    );


    return (
        <HardwareManagementContext.Provider value={context}>
            <SPAWrapper className="hardware-management">
                <Tabs
                    type={hasDevicesEditAccess ? 'editable-card' : 'card'}
                    destroyInactiveTabPane
                    onChange={changeGatewaySelection}
                    activeKey={gatewaySelection}
                    className="hardware-gateway"
                    onEdit={onEditTabs}
                >
                    {physicalGateways.map((g) => {
                        let gatewayDevices = gatewaysDevices[g.device_id] || [];
                        gatewayDevices = [g].concat(
                            gatewayDevices
                                .filter((d) => {
                                    const targetString =
                    d.asset && !d.relay
                        ? `${d.asset.asset_name}${d.mac_address}`
                        : `relay${d.mac_address}`;
                                    return regexMatch(targetString, search);
                                })
                                .sort((a, b) => +a.relay - +b.relay)
                        );
                        return (
                            <TabPane
                                tab={<span title={g.serial_number}>{g.serial_number}</span>}
                                key={g.device_id}
                                closable={false}
                            >
                                <Layout className="d-flex w-100 h-100">
                                    <Sider
                                        className="d-flex flex-column h-100"
                                        width="30%"
                                        theme="light"
                                    >
                                        <div
                                            className="d-flex flex-column w-100 h-100"
                                            style={{ background: '#fff' }}
                                        >
                                            <Permission forResource resource="devices" canDo="edit">
                                                <AukButton.Blue
                                                    ghost
                                                    className="w-100 my-2"
                                                    onClick={() => setShowAddRelay(true)}
                                                >
                                                    <PlusOutlined className="mr-2" />
                          Add Relay
                                                </AukButton.Blue>
                                            </Permission>
                                            <div className="w-100">
                                                <Input
                                                    onChange={(e) => setSearch(e.target.value)}
                                                    prefix={<i className="fas fa-search" />}
                                                    allowClear
                                                />
                                            </div>
                                            <div className="hardware-vlist-container">
                                                <VList
                                                    rowHeight={60}
                                                    rowCount={gatewayDevices.length}
                                                    data={gatewayDevices}
                                                    rowRenderer={({ key, index, style }) => {
                                                        const device = gatewayDevices[index];
                                                        const path = gatewayToNodes[device.mac_address];
                                                        const health = getNodeHealth(path);
                                                        const selected =
                              deviceSelection &&
                              deviceSelection.device.mac_address ===
                                device.mac_address;

                                                        return (
                                                            <div className="py-1" key={key} style={style}>
                                                                <HardwareItem
                                                                    selected={selected}
                                                                    item={device}
                                                                    health={health}
                                                                    onClick={() =>
                                                                        changeDeviceSelection(device, path, health)
                                                                    }
                                                                />
                                                            </div>
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Sider>
                                    <Content className="w-100 h-100 pl-3">
                                        <div className="hardware-management__content">
                                            <div
                                                className="d-flex w-100 justify-content-end"
                                                style={{ flexShrink: 0 }}
                                            >
                                                <AukTooltip.Help title="View mesh">
                                                    <AukButton.Blue
                                                        ghost={view !== VIEWS.FORCE}
                                                        icon={<i className="fas fa-broadcast-tower" />}
                                                        onClick={() => setView(VIEWS.FORCE)}
                                                    />
                                                </AukTooltip.Help>
                                                <AukTooltip.Help title="View table">
                                                    <AukButton.Blue
                                                        className="mr-1"
                                                        ghost={view === VIEWS.FORCE}
                                                        icon={<TableOutlined />}
                                                        onClick={() => setView(VIEWS.TABLE)}
                                                    />
                                                </AukTooltip.Help>
                                                <RolePermission accessLevel="editor">
                                                    <AukTooltip.Help title={translate('export')}>
                                                        <AukButton.Outlined
                                                            className="auk-button--round"
                                                            icon={<i className="fas fa-download" />}
                                                            onClick={() =>
                                                                saveCsv(csvString(), 'mesh_export.csv')
                                                            }
                                                        />
                                                    </AukTooltip.Help>
                                                </RolePermission>
                                            </div>
                                            <div className="d-flex w-100" style={{ flexGrow: 1 }}>
                                                {view === VIEWS.FORCE ? (
                                                    <HardwareMesh
                                                        data={{
                                                            nodes: chartNodes,
                                                            links: chartEdges,
                                                        }}
                                                    />
                                                ) : (
                                                    <HardwareMeshTable data={tableData} />
                                                )}
                                            </div>
                                        </div>
                                    </Content>
                                </Layout>
                            </TabPane>
                        );
                    })}
                    {addGatewaySelection.map((s) => (
                        <TabPane tab={s.title} key={s.key} closable>
                            <div className="w-100 h-100">
                                <DeviceScanGateway 
                                    onSubmit={onGatewayAdded(s.key)}
                                />
                            </div>
                        </TabPane>
                    ))}
                </Tabs>
            </SPAWrapper>
            <Modal
                className="auk-modal add-relay"
                title="Add relay"
                key={showAddRelay ? 1 : 0}
                visible={showAddRelay}
                footer={
                    <div className="w-100 d-flex">
                        <AukButton.Cancel onClick={closeAddRelay} />
                    </div>
                }
            >
                <DeviceScanNode
                    forModel={K.MODELS.RELAY}
                    showSubmit
                    submit={(data) => {
                        dispatch(
                            initializeDeviceAsRelay(
                                data.dmac,
                                data.gatewayId,
                                () => {
                                    closeAddRelay();
                                    refresh();
                                }
                            )
                        );
                    }}
                />
            </Modal>
        </HardwareManagementContext.Provider>
    );
};

export default HardwareManagement;

const RELAY_BACKBONE = 1; // strong
const MAX_JUMPS = 7;
const FAIL = Colors.red[0];
const WARN = Colors.yellow[0];
const STRONG = Colors.green[0];

const getNodeHealth = (path) => {
    if (!path)
        return {
            health: FAIL,
            summary: 'Weak or no signal',
            warnings: ['No path to gateway'],
        };

    const noPathToGateway = path.distance === Infinity; // no connection to other nodes
    const relayBackbone = path.distance <= RELAY_BACKBONE; // connected to relay backbone

    const maxJumps = path.path.length - 1 >= MAX_JUMPS;

    const health = noPathToGateway
        ? FAIL
        : relayBackbone
            ? maxJumps
                ? WARN
                : STRONG
            : WARN;

    const iconStyle = { color: health, flexShrink: 0 };
    const icon =
    health === FAIL ? (
        <CloseCircleOutlined className="mx-1" style={iconStyle} />
    ) : health === WARN ? (
        <ExclamationCircleOutlined className="mx-1" style={iconStyle} />
    ) : (
        <CheckCircleOutlined className="mx-1" style={iconStyle} />
    );

    const summary = noPathToGateway
        ? 'Weak or no signal'
        : relayBackbone
            ? 'Strong, reliable signal'
            : 'Strong, but unreliable signal';

    const warnings = [];

    if (noPathToGateway) warnings.push('No path to gateway');
    if (!relayBackbone)
        warnings.push('Not connected to gateway through relay backbone');
    if (maxJumps) warnings.push('Path to gateway too long');
    if (health === STRONG) warnings.push('Strong connection to mesh core');

    return { icon, health, summary, warnings };
};

const RELAY_WEIGHT = 0;
const NODE_WEIGHT = 2;
const WEIGHTS = {
    relay: RELAY_WEIGHT,
    gateway: RELAY_WEIGHT,
    node: NODE_WEIGHT,
};

const getEdgeWeight = (sourceType, targetType) => (WEIGHTS[sourceType] + WEIGHTS[targetType]) / 2;
const getEdgePath = (sourceNode, targetNode) => `${sourceNode}-${targetNode}`;
const prepareGraphData = (data) => {
    const vertices = data.map((d) => ({
        id: d.device_id,
        node: d.node,
        type: d.type,
        asset_name: d.asset ? d.asset.asset_name : undefined,
    }));

    const verticesSet = new Set(vertices.map(({ node }) => node));

    const nodesToTypes = data.reduce((acc, curr) => {
        return { ...acc, [curr.node]: curr.type };
    }, {});

    const edges = data
        .filter((d) => d.links)
        .map((d) =>
            d.links
                .filter((link) => verticesSet.has(link.node))
                .map((link) => {
                    const start = d.node;
                    const end = link.node;

                    const weight = getEdgeWeight(d.type, nodesToTypes[end]);
                    return { start, end, weight, rssi: link.rssi };
                })
        )
        .reduce((acc, curr) => acc.concat(...curr), [])
        .reduce((acc, curr) => {
            const { start, end, rssi, weight } = curr;
            const key = getEdgePath(start, end);
            const reverseKey = getEdgePath(end, start);

            const lower = acc[reverseKey]
                ? acc[reverseKey] < rssi
                    ? acc[reverseKey]
                    : rssi
                : rssi;

            const value = { rssi: lower, weight };
            acc[key] = value;
            acc[reverseKey] = value;
            return acc;
        }, {});

    return { vertices, edges };
};
