/* eslint-disable react/prop-types */
import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import { Table, Tag } from 'antd';

import DaysOfWeekTabs from './DaysOfWeekTabs';
import {
    numberToOrdinal,
    regexMatch,
    uiDatetimeFormat,
} from '../../../utils/helpers';
import { Permission } from '../../../components/Permission';
import { getOrdinalWeekOfMonth } from '../../../utils/date';

import {
    CustomFilterDropdown,
    CustomFilterIcon,
} from '../../../components/VTable';
import { DeleteOutlined } from '@ant-design/icons';
import translate from '../../../utils/translate';

const LONG_LONG_TIME_AGO = moment().set({ year: 1970 });

export default class ScheduleTable extends React.Component {
    constructor(props) {
        super(props);

        this.nameAttr = `${this.props.type}_name`;

        this.state = {
            pageSize: '5',
        };

        this.handleChangePageSize = this.handleChangePageSize.bind(this);
    }

    handleChangePageSize(pageSize) {
        this.setState({ pageSize });
    }

    getTimeCell(d) {
        const recur_format = 'HH:mm';
        const single_format = uiDatetimeFormat;
        const { from, to } = d;

        const format = d.recurring ? recur_format : single_format;

        return (
            <span>
                <Tag color="blue">{moment(from).format(format)}</Tag>
                <span className="mr-2">-</span>
                <Tag color="blue">{moment(to).format(format)}</Tag>
            </span>
        );
    }

    getDateCell(d, accessor) {
        if (!d.recurring) return '-';

        const format = uiDatetimeFormat;
        return d[accessor] ? (
            <Tag color="blue">{moment(d[accessor]).format(format)}</Tag>
        ) : (
            ''
        );
    }

    getFrequencyCell(d) {
        const { recurring, frequency, recur_start } = d;

        if (!recurring) return '-';

        switch (frequency.period) {
        case 'days':
            return this.dailyReccurence(frequency);
        case 'weeks':
            return this.weeklyReccurence(frequency);
        case 'months':
            return this.monthlyReccurence(frequency, recur_start);
        case 'years':
            return this.annuallyRecurrence(frequency);
        }
    }

    getDeleteCell(d) {
        if (d.isCompleted) return null;

        if (d.isFuture) {
            return (
                <Permission resource="schedules" forResource canDo="full">
                    <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.props.handleClickDelete(d)}
                        title="Delete shift"
                    >
                        <DeleteOutlined />
                    </span>
                </Permission>
            );
        }

        if (!d.recurring) return null;

        return (
            <Permission resource="schedules" forResource canDo="full">
                <span
                    style={{ cursor: 'pointer', color: '#797979' }}
                    onClick={() => this.props.handleClickStop(d)}
                    title="End recurring shift"
                >
                    <i className="fa fa-stop-circle" />
                </span>
            </Permission>
        );
    }

    getAssetsCell(d) {
        return (
            <div className="h-100">
                {d.linkedAssets.map((a) => a.asset_name).join(' ,')}
            </div>
        );
    }

    get columns() {
        const { nameAttr } = this;
        const { assetOptions } = this.props;
        return [
            {
                title: translate("name"),
                width: 200,
                render: (text, record, index) => {
                    const value = record[nameAttr];
                    const comp = record.isCompleted ? (
                        <span>
                            <span className="mr-2">{value}</span> <Tag>Archived</Tag>
                        </span>
                    ) : (
                        value
                    );
                    return comp;
                },
                sorter: (a, b) => {
                    if (a[nameAttr] < b[nameAttr]) return -1;
                    if (a[nameAttr] > b[nameAttr]) return 1;
                    return 0;
                },
                onFilter: (value, record) =>
                    regexMatch(record[nameAttr], value, { escape: true }),
                filterDropdown: CustomFilterDropdown,
                filterIcon: (filtered) => <CustomFilterIcon active={filtered} />,
            },
            {
                title: translate("time"),
                width: 200,
                render: (text, record, index) => this.getTimeCell(record),
            },
            {
                title: translate("asset"),
                width: 150,
                render: (text, record, index) => this.getAssetsCell(record),
                filters: assetOptions.map((a) => ({
                    value: a.asset_id,
                    text: a.asset_name,
                })),
                onFilter: (value, record) => {
                    const assets = new Set(record.assets);
                    return assets.has(value);
                },
            },
            {
                title: translate("repeat"),
                width: 100,
                render: (text, record, index) => (record.recurring ? 'Yes' : 'No'),
                sorter: (a, b) => {
                    if (a.recurring < b.recurring) return -1;
                    if (a.recurring > b.recurring) return 1;
                    return 0;
                },
            },
            {
                title: translate('frequency'),
                // width: 200,
                render: (text, record, index) => this.getFrequencyCell(record),
            },
            {
                title: translate("repeatstartdate"),
                width: 180,
                render: (text, record, index) =>
                    this.getDateCell(record, 'recur_start'),
                sorter: (a, b) => {
                    const first = a.recur_start || moment(LONG_LONG_TIME_AGO);
                    const second = b.recur_start || moment(LONG_LONG_TIME_AGO);

                    return moment(first).isSameOrAfter(moment(second)) ? 1 : -1;
                },
            },
            {
                title: translate('repeatenddate'),
                width: 180,
                render: (text, record, index) => this.getDateCell(record, 'recur_end'),
                sorter: (a, b) => {
                    const first = a.recur_end || moment(LONG_LONG_TIME_AGO);
                    const second = b.recur_end || moment(LONG_LONG_TIME_AGO);

                    return moment(first).isSameOrAfter(moment(second)) ? 1 : -1;
                },
            },
            {
                title: translate('modifiedby'),
                width: 180,
                render: (text, record, index) => (
                    <>
                        {record.userName} <br />
            on {record.lastUpdated}
                    </>
                ),
            },
            {
                render: (text, record, index) => this.getDeleteCell(record),
                width: 50,
            },
        ];
    }

    render() {
        return (
            <Table
                className="schedule-table"
                size="middle"
                rowKey={(record) => this.props.rowKeyAccessor(record)}
                columns={this.columns}
                dataSource={this.props.data}
                pagination={{
                    position: ['bottomCenter'],
                    pageSize: this.state.pageSize,
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '50'],
                    onShowSizeChange: (e, size) => this.handleChangePageSize(size),
                }}
                rowClassName={(d) => (d.isCompleted ? 'op-06' : '')}
            />
        );
    }

    dailyReccurence(f) {
        const { x } = f;

        return x === 1 ? translate('repeatsdaily') : <span>{translate('repeatsevery')}{" "}{x}{" "}{translate('days')}</span>;
    }

    weeklyReccurence(f) {
        const { days, x } = f;
        return (
            <>
                {x === 1 ? translate('repeatsweekly') : <span>{translate('repeatsevery')}{" "}{x}{" "}{translate('weeks')}</span>}
                <div className="mt-2">
                    <DaysOfWeekTabs value={days} readOnly />
                </div>
            </>
        );
    }

    monthlyReccurence(f, start) {
        const { date, day, week, x } = f;
        const repeat =
      x === 1 ? translate('repeatsmonthly') : <span>{translate('repeatsevery')}{" "}{x}{" "}{translate('months')}</span>;
        const details = date
            ? `every ${numberToOrdinal(+date)} of the month`
            : `every ${getOrdinalWeekOfMonth(week, new moment(start))} ${moment()
                .day(day)
                .format('dddd')}`;

        return (
            <>
                {repeat}, {details}
            </>
        );
    }

    annuallyRecurrence(f) {
        const { month, date, x } = f;
        const repeat =
      x === 1 ? translate('repeatsannually') : <span>{translate('repeatsevery')}{" "}{x}{" "}{translate('years')}</span>;
        const details = `every ${date} ${moment()
            .month(month - 1)
            .format('MMMM')}`; // month is 0-indexed
        return (
            <>
                {repeat}, {details}
            </>
        );
    }
}
