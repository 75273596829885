/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { Form, Input, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import AukButton from '../../components/AukButton';
import { DangerZoneItem } from '../../components/DangerZone';
import ImageUpload from '../../components/ImageUpload';
import { api_uploadImage } from '../../../store/old/Images/Images.services';
import { deleteAsset } from '../../../store/old/Assets/Assets.action';
import { errorFlash } from '../../components/Flash';
import { containsSuspiciousCharacters } from '../../utils/validate';
import './AssetForm.scss';
import { useSelector } from 'react-redux';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';
import useTranslate from '../../../hooks/useTranslate';
const { confirm } = Modal;

const ASSET_VALIDATE = {
    ASSET_NAME: [
        {
            required: true,
            message: 'Machine name is required.',
        },
        {
            whitespace: true,
            message: 'Machine name is required.',
        },
        () => ({
            validator: (_, value) => {
                if (containsSuspiciousCharacters(value))
                    return Promise.reject(
                        new Error('Asset name cannot contain #%&*?|<>\\.')
                    );
                return Promise.resolve();
            },
        }),
    ],
    BRAND: [
        {
            required: true,
            message: 'Machine brand is required.',
        },
        {
            whitespace: true,
            message: 'Machine brand is required.',
        },
        () => ({
            validator: (_, value) => {
                if (containsSuspiciousCharacters(value))
                    return Promise.reject(
                        new Error('Machine brand cannot contain #%&*?|<>\\.')
                    );
                return Promise.resolve();
            },
        }),
    ],
    MODEL: [
        {
            required: true,
            message: 'Machine model is required.',
        },
        {
            whitespace: true,
            message: 'Machine model is required.',
        },
        () => ({
            validator: (_, value) => {
                if (containsSuspiciousCharacters(value))
                    return Promise.reject(
                        new Error('Machine model cannot contain #%&*?|<>\\.')
                    );
                return Promise.resolve();
            },
        }),
    ],
};

const AssetForm = forwardRef((props, ref) => {
    const {
        asset = {},
        cancel,
        showCancel = false,
        showDelete = true,
    } = props;

    const dispatch = useDispatch();
    const { entity_id } = useSelector(currentEntitySelector);
    const { translate } = useTranslate();

    const [form] = Form.useForm();
    const [imageLoading, setImageLoading] = useState(false);

    const [asset_img_id, setAssetImgId] = useState(
        asset.asset_img ? asset.asset_img.image_id : null
    );
    

    const getFormData = (d) => {
        const { asset_name, brand, model, asset_code } = d;

        return {
            asset_img_id,
            asset_name,
            brand,
            model,
            asset_code,
        };
    };

    const onImageChange = async (base64Img) => {
        try {
            setImageLoading(true);
            const { image_id } = await api_uploadImage({
                base64Img,
                image_type: 'asset',
            });
            setAssetImgId(image_id);
            setImageLoading(false);
        } catch (e) {
            errorFlash(e);
            setImageLoading(false);
        }
    };

    useImperativeHandle(ref, () => ({
        getFormData: () => getFormData(form.getFieldsValue(true)),
        form
    }));

    return (
        <Form
            ref={ref}
            name="assetForm"
            id="assetForm"
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            className="asset-form"
            onFinish={async () => {
                try {
                    await form.validateFields()
                    const errors = form.getFieldsError().filter(item => item.errors.length)
    
                    if (!errors.length) {
                        props.submit(getFormData(form.getFieldsValue(true)));
                        return;
                    }
                } catch (e) {}
            }}
        >
            <div className="asset-form__body">
                <div className="asset-form__image">
                    <ImageUpload onChange={onImageChange} value={asset.image || ''} />
                </div>
                <div style={{ flexGrow: 1 }} className="asset-form__fields">
                    <Form.Item
                        style={{ marginTop: 24 }}
                        name="asset_name"
                        initialValue={asset.asset_name || ''}
                        label={translate('Machine')}
                        rules={ASSET_VALIDATE.ASSET_NAME}
                    >
                        <Input maxLength={50} />
                    </Form.Item>
                    <Form.Item
                        name="brand"
                        initialValue={asset.brand || ''}
                        label={translate('Brand')}
                        rules={ASSET_VALIDATE.BRAND}
                    >
                        <Input maxLength={50} />
                    </Form.Item>
                    <Form.Item
                        name="model"
                        initialValue={asset.model || ''}
                        label={translate('Model')}
                        rules={ASSET_VALIDATE.MODEL}
                    >
                        <Input maxLength={50} />
                    </Form.Item>
                    <Form.Item
                        name="asset_code"
                        initialValue={asset.asset_code || ''}
                        label={translate("Asset ID")}
                    >
                        <Input maxLength={50} />
                    </Form.Item>
                    {!isEmpty(asset) && showDelete && (
                        <Form.Item label=" " className="mb-3" colon={false}>
                            <DangerZoneItem
                                title="Remove asset"
                                description="Removes asset and all associations, including linked devices. May affect OEE of parent processes."
                                button={
                                    <AukButton.Red
                                        size="small"
                                        onClick={() => {
                                            confirm({
                                                title: `Remove asset ${asset.asset_name || ''}`,
                                                icon: <ExclamationCircleOutlined />,
                                                content: 'This action is not reversible, continue?',
                                                onOk: () => dispatch(deleteAsset(entity_id, asset.asset_id, cancel))
                                            });
                                        }}
                                    >
                    Delete
                                    </AukButton.Red>
                                }
                            />
                        </Form.Item>
                    )}
                </div>
            </div>
            <div className="asset-form__submit">
                {props.showSubmit && <AukButton.Save htmlType="submit" disabled={imageLoading} />}
                {showCancel && <AukButton.Cancel onClick={cancel} />}
            </div>
        </Form>
    );
});

export default AssetForm;
