/* eslint-disable react/prop-types */
import React from 'react';
import { DeviceConstants as K } from '../../../store/old/Devices/Devices.constants';
import './DeviceConfiguration.scss';
// import DeviceInterfaceSelect from './components/DeviceInterfaceSelect';
import DeviceIoForm from './components/DeviceIoForm';
import DeviceRs232Form from './components/DeviceRs232Form';
import DeviceRs485Form from './components/DeviceRs485Form';

const DeviceConfiguration = (props) => {
    // console.log(props.interface)
    // const [deviceInterface, setDeviceInterface] = useState(props.interface);

    return (
        <>
            {/* {
                deviceInterface ? 
                    null : <DeviceInterfaceSelect onChange={setDeviceInterface}/>
            } */}
            {
                props.interface === K.IO ? (
                    <DeviceIoForm 
                        device={props.device}
                        onUnlink={props.onUnlink}
                        onSubmit={props.onSubmit}
                        isInit={props.isInit}
                    />
                ): props.interface === K.RS232 ? (
                    <DeviceRs232Form
                        device={props.device}
                        onUnlink={props.onUnlink}
                        onSubmit={props.onSubmit}
                        isInit={props.isInit}
                    />
                ): props.interface === K.RS485 ? (
                    <DeviceRs485Form
                        device={props.device}
                        onUnlink={props.onUnlink}
                        onSubmit={props.onSubmit}
                        isInit={props.isInit}
                    />
                ): null
            }
        </>
    );
};

export default DeviceConfiguration;
