import { put, call, takeLatest } from 'redux-saga/effects';
import { NotificationConstants as K } from './Notifications.constants';
import {
    api_getNotifications,
    api_createNotification,
    api_deleteNotification,
    api_updateNotification,
} from './Notifications.services';
import { getMapFromArr } from '../../../legacy/utils/helpers';
import { Notification, parseNotificationArguments } from '../../../legacy/models';
import {
    addNotificationsResource,
    removeNotificationsResource,
    setNotificationsState,
} from './Notifications.action';

import { errorFlash } from '../../../legacy/components/Flash';

function* handleFetchNotificationsRequest(action) {
    try {
        const response = yield call(api_getNotifications, action.payload.entityId);
        const notifications = getMapFromArr(
            response.map((n) => new Notification(...parseNotificationArguments(n))),
            'notification_id'
        );

        yield put(setNotificationsState(notifications))
        // yield put(FetchNotificationsRequestSuccess(notifications));
    } catch (e) {
        errorFlash(e);
    }
}

export function* fetchNotificationsList() {
    yield takeLatest(K.ACTIONS.FETCH_NOTIFICATIONS_REQUEST, handleFetchNotificationsRequest);
}

function* handleCreateNotificationsRequest(action) {
    try {
        const result = yield call(api_createNotification, action.payload);
        const notification = new Notification(
            ...parseNotificationArguments(result)
        );

        yield put(addNotificationsResource({ [notification.notification_id]: notification }))
        // yield put(CreateNotificationRequestSuccess(notification));
    } catch (e) {
        errorFlash(e);
    }
}

export function* createNotification() {
    yield takeLatest(K.ACTIONS.CREATE_NOTIFICATIONS_REQUEST, handleCreateNotificationsRequest);
}

function* handleUpdateNotificationRequest(action) {
    try {
        const result = yield call(api_updateNotification, action.payload);
        const notification = new Notification(
            ...parseNotificationArguments(result)
        );

        yield put(addNotificationsResource({ [notification.notification_id]: notification }))
        // yield put(UpdateNotificationRequestSuccess(notification));
    } catch (e) {
        errorFlash(e);
    }
}

export function* updateNotification() {
    yield takeLatest(K.ACTIONS.UPDATE_NOTIFICATIONS_REQUEST, handleUpdateNotificationRequest);
}

function* handleDeleteNotificationRequest(action) {
    try {
        const { notification_id } = action.payload;
        yield call(api_deleteNotification, notification_id);

        yield put(removeNotificationsResource([notification_id]))
        // yield put(DeleteNotificationRequestSuccess(notification_id));
    } catch (e) {
        errorFlash(e);
    }
}

export function* deleteNotification() {
    yield takeLatest(K.ACTIONS.DELETE_NOTIFICATIONS_REQUEST, handleDeleteNotificationRequest);
}
