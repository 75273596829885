import { store } from '../../store';
import CONSTANTS from '../Constants';
import { values } from 'lodash';
import { TimeSeriesFactory } from '../utils/data';

export const parseFusionArguments = (f) => {
    return [
        f.chart_order && f.chart_order.asset_id
            ? f.chart_order.asset_id
            : f.asset_id,
        f.assets,
        f.autoUpdate,
        f.batRec,
        f.color,
        f.chart_order && f.chart_order.chart_order ? f.chart_order.chart_order : -1,
        f.chart_title,
        f.chart_type,
        f.exp_mapping,
        f.exp_transform,
        f.fusion_id,
        f.type,
        f.mode,
        f.oee_input,
        f.speed,
        f.stdTime,
        f.units,
        f.control_limits,
    ];
};

export class Fusion {
    asset_id;
    assets;
    autoUpdate;
    batRec;
    color;
    chart_order;
    chart_title;
    chart_type;
    exp_mapping;
    exp_transform;
    fusion_id;
    type;
    mode;
    oee_input;
    speed;
    stdTime;
    units;
    control_limits;

    constructor(
        asset_id = -1,
        assets = [],
        autoUpdate = true,
        batRec = null,
        color,
        chart_order = -1,
        chart_title = '',
        chart_type = '',
        exp_mapping,
        exp_transform,
        fusion_id = -1,
        type = '',
        mode = '',
        oee_input = null,
        speed = false,
        stdTime = null,
        units = '',
        control_limits = null
    ) {
        this.asset_id = asset_id;
        this.assets = assets;
        this.autoUpdate = autoUpdate;
        this.batRec = batRec;
        this.color = color || '#4682b4';
        this.chart_order = chart_order;
        this.chart_title = chart_title;
        this.chart_type = chart_type;
        this.exp_mapping = exp_mapping;
        this.exp_transform = exp_transform;
        this.fusion_id = fusion_id;
        this.type = type;
        this.mode = mode;
        this.oee_input = oee_input;
        this.speed = speed;
        this.stdTime = stdTime;
        this.units = units || 'units';
        this.control_limits = control_limits;
    }

    get id() {
        return this.fusion_id;
    }

    get hasData() {
        return this.data.length;
    }

    updateChartOrder(order) {
        this.chart_order = order;
    }

    get data() {
        const { fusions } = store.getState().data;

        return fusions[this.fusion_id] || TimeSeriesFactory([]);
    }

    get unitMeasure() {
        return this.units || 'units';
    }

    get aggregate() {
        const { mode, data, unitMeasure } = this;
        switch (mode) {
        case CONSTANTS.CHANNELS.MODES.DIGITAL_COUNT:
            const total = data.reduce((acc, curr) => (acc += curr.val), 0);
            return total ? `Total: ${total.toLocaleString()} ${unitMeasure}` : '';
        case CONSTANTS.CHANNELS.MODES.ANALOG_VOLTAGE:
            const lastMeasured = data.length ? data[data.length - 1].val : '';
            return lastMeasured
                ? `Last: ${lastMeasured.toFixed(2)} ${unitMeasure}`
                : '';
        default:
            return '';
        }
    }

    get fusion_metadata() {
        const {
            metadata: { metadata: metadataState },
        } = store.getState();

        return values(this.exp_mapping).map((m_id) => metadataState[m_id]);
    }

    get samplingRate() {
        const valid = this.fusion_metadata.filter(m => m).length === values(this.exp_mapping).length;
        if (!valid) {
            return undefined
        }
        return Math.max(...this.fusion_metadata.map(m => m.samplingRate))
    }

    serialize() {
        return {
            ...this,
            chart_order: {
                asset_id: this.asset_id,
                chart_order: this.chart_order,
            },
        };
    }
}
