import { all, call, put, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';

// TYPE
import { TilesConstants as K } from './Tiles.constants';

// SERVICE
import { api_createSummaryTile, api_editSummaryTile, api_deleteSummaryTile, api_editTile, api_createTile } from './Tiles.services';

// ACTIONS
import { createSummaryTileSuccess, createSummaryTileFailure, editSummaryTileFailure, editSummaryTileSuccess, deleteSummaryTileSuccess, deleteSummaryTileFailure, updateTilePositionFailure, editTileSuccess, AddTilesState } from './Tiles.action';

// HELPERS
import { watcherBuilder } from '../Base/Base.saga';

// STORE
import { store } from '../..';
import { currentEntitySelector } from '../Entity/Entity.selector';

// MODEL
import { SummaryTile, Tile, parseTileArguments, parseSummaryTileArguments, tileToWidget } from '../../../legacy/models';

import { errorFlash } from '../../../legacy/components/Flash';
import { AddWidgetsResource, RemoveWidgetsResource } from '../Widgets/Widget.action';

// CREATE SUMMARY TILE
function* handleCreateSummaryTile(data) {
    try {
        const { entity_id } = currentEntitySelector(store.getState());
        const {
            tiles: { summary_tiles },
        } = store.getState();

        const response = yield call(api_createSummaryTile, entity_id, data.payload);

        const summary_tile = new SummaryTile(
            ...parseSummaryTileArguments({
                ...response,
                block: {
                    block_id: response.block_id,
                    entity_id: data.payload.entity_id,
                },
            })
        );

        const widget = tileToWidget(summary_tile);

        yield all([
            put(
                createSummaryTileSuccess({
                    summary_tiles: {
                        ...summary_tiles,
                        [summary_tile.summary_tile_id]: summary_tile,
                    },
                })
            ),
            put(AddWidgetsResource({ [widget.widget_id]: widget })),
        ]);

        if (data.callback) {
            yield data.callback();
        }
    } catch (error) {
        errorFlash({
            details: error.message,
            message: error.error,
        });
        yield put(createSummaryTileFailure(error));
    }
}

export function* createSummaryTile() {
    yield takeLatest(
        K.ACTIONS.CREATE_SUMMARY_TILE_REQUEST,
        handleCreateSummaryTile
    );
}

// EDIT SUMMARY TILE
function* handleEditSummaryTile(action) {
    try {
        const { entity_id } = currentEntitySelector(store.getState());

        const {
            tiles: { summary_tiles },
        } = store.getState();

        const { summary_tile_id } = action.payload;

        const original = summary_tiles[summary_tile_id];
        const data = Object.assign({}, action.payload);
        delete data.entity_id;

        const response = yield call(
            api_editSummaryTile,
            entity_id,
            summary_tile_id,
            data
        );

        response.block = {
            ...response.block,
            block_id: action.payload.block_id || original.block.block_id,
            entity_id: action.payload.entity_id || original.block.entity_id,
        };

        response.label = response.label
            ? response.label
            : response.block && response.block.block_name
                ? response.block.block_name
                : response.block.asset && response.block.asset.asset_name
                    ? response.block.asset.asset_name
                    : '';

        const summary_tile = new SummaryTile(
            ...parseSummaryTileArguments({ ...response })
        );

        yield put(editSummaryTileSuccess(summary_tile));

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash({
            details: error.message,
            message: error.error,
        });
        yield put(editSummaryTileFailure(error));
    }
}

export function* editSummaryTile() {
    yield takeLatest(
        K.ACTIONS.EDIT_SUMMARY_TILE_REQUEST,
        handleEditSummaryTile
    );
}

// DELETE SUMMARY TILE
function* handleDeleteSummaryTile(action) {
    try {
        const { entity_id } = currentEntitySelector(store.getState());
        const { summary_tile_id } = action.payload;
        
        // widget adaptor
        yield put(RemoveWidgetsResource([`st_${summary_tile_id}`]))
        
        const response = yield api_deleteSummaryTile(entity_id, summary_tile_id);
        yield put(deleteSummaryTileSuccess([summary_tile_id]))

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash({
            details: error.message,
            message: error.error,
        });
        yield put(deleteSummaryTileFailure(error));
    }
}

export function* deleteSummaryTile() {
    yield takeLatest(
        K.ACTIONS.DELETE_SUMMARY_TILE_REQUEST,
        handleDeleteSummaryTile
    );
}

// UPDATE TILE POSITION
function* handleEditTile(data) {
    try {
        const appState = store.getState();
        const { entity_id } = currentEntitySelector(appState);

        const {
            tiles: { tiles },
        } = appState;

        const { position_x, position_y, tile_id } = data.payload;

        const obj = { ...tiles[tile_id], position_x, position_y };
        const tile = new Tile(...parseTileArguments(obj));

        yield put(editTileSuccess({ tile }));
        yield call(api_editTile, entity_id, tile_id, data.payload);

        if (data.callback) {
            data.callback(tile);
        }
    } catch (error) {
        errorFlash({
            details: error.message,
            message: error.error,
        });
        yield put(updateTilePositionFailure(error));
    }
}

export function* updateTilePosition() {
    yield takeLatest(
        K.ACTIONS.EDIT_TILE_REQUEST,
        handleEditTile
    );
}

function* watchEditTileSuccess(action) {
    try {
        const { tile } = action.payload;
        const widget = tileToWidget(tile);
        yield put(AddWidgetsResource({ [widget.widget_id]: widget }));
    } catch (e) {
        errorFlash(e);
    }
}

export function* watchEditTileSuccessSaga() {
    yield watcherBuilder(
        K.ACTIONS.EDIT_TILE_SUCCESS,
        watchEditTileSuccess
    );
}

function* watchEditSummaryTileSuccess(action) {
    try {
        const { summary_tile } = action.payload;
        const widget = tileToWidget(summary_tile);
        yield put(AddWidgetsResource({ [widget.widget_id]: widget }));
    } catch (e) {
        errorFlash(e);
    }
}

export function* watchEditSummaryTileSuccessSaga() {
    yield watcherBuilder(
        K.ACTIONS.EDIT_SUMMARY_TILE_SUCCESS,
        watchEditSummaryTileSuccess
    );
}

function* handleCreateTile(action) {
    try {
        const { payload } = action;

        const response = yield call(api_createTile, payload.entity_id, payload.tile)
        const tile = new Tile(...parseTileArguments(response));
        const widget = tileToWidget(response);

        yield all([
            put(AddTilesState(tile)),
            put(AddWidgetsResource({ [widget.widget_id]: widget })),
        ]);

        if (action.callback) {
            yield action.callback();
        }
    } catch (error) {
        errorFlash(error)
    }
}

export function* createTileSaga() {
    yield takeLatest(
        K.ACTIONS.CREATE_TILE_REQUEST,
        handleCreateTile
    );
}
