import { pickBy } from 'lodash';
import { updateStoreRedux } from '../../../legacy/utils/redux';
import { NotificationConstants as K } from './Notifications.constants';

const initialReducer = {
    notifications: {},
};

export const notificationsReducer = (
    state = initialReducer,
    action
) => {
    switch (action.type) {
    case K.ACTIONS.SET_NOTIFICATIONS_STATE:
        return updateStoreRedux(state, {
            notifications: { ...action.payload.notifications },
        });
    case K.ACTIONS.ADD_NOTIFICATIONS_RESOURCE:
        return updateStoreRedux(state, {
            notifications: { ...state.notifications, ...action.payload.notifications },
        });
    case K.ACTIONS.REMOVE_NOTIFICATIONS_RESOURCE:
        const deleteSet = new Set(action.payload.notificationIds);
        const deletedState = pickBy(
            { ...state.notifications },
            ({ notification_id }) => !deleteSet.has(notification_id)
        );

        return updateStoreRedux(state, {
            notifications: deletedState,
        });
    default:
        return updateStoreRedux(state, {});
    }
};
