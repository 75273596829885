/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, Modal, Select } from 'antd';
import { DeviceConstants as K } from '../../../../store/old/Devices/Devices.constants';
import { arrayGateways } from '../../../../store/old/Gateway/Gateway.selector';
import { useSelector } from 'react-redux';
import AukButton from '../../../components/AukButton';
import { DangerZoneItem } from '../../../components/DangerZone';
import { unlinkDevice } from '../../../../store/old/Devices/Devices.action';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './DeviceForm.scss';
import { Permission } from '../../../components/Permission';
import useTranslate from '../../../../hooks/useTranslate';
import { values } from 'lodash';

const { confirm } = Modal;

const getDefaultFilter = (firmware_version = 'v0.0.1', filter) => {
    if (filter !== undefined) return filter;

    if (!firmware_version) return 0;
    if (firmware_version.length === 0) return 0;

    const nodeVersionNumber = +firmware_version.replace('v', '').split('.')[0];

    if (nodeVersionNumber < 5) return 0;

    return 15;
};

const DEVICE_VALIDATE = {
    OPERATING_VOLTAGE: [
        {
            required: true,
            message: 'Operating voltage is required.',
        },
        {
            transform: (value) => +value,
            type: 'number',
            min: 0,
            message: 'Operating voltage cannot be less than 0.',
        },
    ],
    NOISE_FILTER: [
        {
            required: true,
            message: 'Filter is required (default = 0).',
        },
        {
            transform: (value) => +value,
            type: 'number',
            min: 0,
            message: 'Filter value must be greater than or equal to 0.',
        },
        {
            transform: (value) => +value,
            type: 'number',
            max: 255,
            message: 'Filter value must be less than or equal to 255.',
        },
    ],
    MINMAX_FILTER: [
        {
            required: true,
            message: 'Min/max filter is required (default = 0).',
        },
        {
            transform: (value) => +value,
            type: 'number',
            min: 0,
            message: 'Min/max filter value must be greater than or equal to 0.',
        },
        {
            transform: (value) => +value,
            type: 'number',
            max: 255,
            message: 'Min/max filter value must be less than or equal to 255.',
        },
    ],
    BAUD: [
        ({ getFieldValue }) => ({
            validator: (_, value) => {
                if (getFieldValue('interface_type') !== 'i/o') {
                    return !!value
                        ? Promise.resolve()
                        : Promise.reject(new Error('Baud rate is required.'));
                }
                return Promise.resolve();
            },
        }),
    ],
};

const getFormData = (allValues, deviceInterface) => {
    const {
        gateway_id,
        device_id,
        // interface_type,
        sampling_rate,
        operating_voltage,
        filter,
        minmax_filter,
        baud,
    } = allValues;

    const result = {
        gateway_id,
        device_id,
        sampling_rate,
        operating_voltage: +operating_voltage,
        interface: deviceInterface,
    };

    switch (deviceInterface) {
    case K.IO:
        return {
            ...result,
            filter: +filter,
            minmax_filter: +minmax_filter,
        };
    default:
        return {
            ...result,
            baud,
        };
    }
};

const DeviceForm = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { translate } = useTranslate();

    const { onUnlink, isInit } = props;

    const gateways = useSelector(appState => appState.gateway.gateways)

    const deviceGateways = useMemo(() => {
        return values(gateways)
            .filter(gw => gw.interface !== 'opcua')
            .map(gw => ({
                label: gw.serial_number,
                value: gw.device_id
            }))
    }, [gateways])

    const {
        firmware_version = 'v0.0.1',
        sampling_rate = 15,
        operating_voltage = 24,
        // interface_type = K.IO,
        filter = getDefaultFilter(props.device.firmware_version, props.device.filter),
        minmax_filter = 0,
        baud = 9600,
        device_id,
        gateway_id,
        mac_address,
    } = props.device;

    const [form] = Form.useForm();

    const isSerial = useMemo(() => props.interface !== K.IO, [props.interface])

    useEffect(() => props.onChange && props.onChange(getFormData(form.getFieldsValue(true), props.interface)), []);

    useImperativeHandle(ref, () => ({
        getFormData() {
            return getFormData(form.getFieldsValue(true), props.interface);
        },
    }));

    return (
        <Form
            className="device-form w-100"
            ref={ref}
            form={form}
            name="deviceForm"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            initialValues={{ remember: true }}
            hidden={props.hidden}
            preserve
        >
            <Form.Item hidden={true} name="device_id" initialValue={device_id}>
                <Input disabled />
            </Form.Item>
            <Form.Item
                name="mac_address"
                label={translate("Serial Number")}
                initialValue={mac_address}
            >
                <Input disabled />
            </Form.Item>
            <Form.Item
                label={translate("Gateway")}
                name="gateway_id"
                initialValue={gateway_id || deviceGateways[0].value}
            >
                <Select
                    options={deviceGateways}
                    disabled={gateway_id || deviceGateways.length === 1}
                />
            </Form.Item>
            <Form.Item
                name="firmware_version"
                label={translate("Version")}
                initialValue={firmware_version}
            >
                <Input disabled />
            </Form.Item>
            <Form.Item
                name="sampling_rate"
                label={translate("Push Rate (sec)")}
                initialValue={sampling_rate}
            >
                <Select options={K.SAMPLING_RATES} disabled={false} />
            </Form.Item>
            <Form.Item
                name="operating_voltage"
                label={translate("Voltage (V)")}
                initialValue={operating_voltage}
                rules={DEVICE_VALIDATE.OPERATING_VOLTAGE}
            >
                <Input type="number" disabled={false} />
            </Form.Item>
            <Form.Item
                name="filter"
                label={translate("Noise Filter")}
                initialValue={filter}
                rules={DEVICE_VALIDATE.NOISE_FILTER}
                hidden={isSerial}
            >
                <Input type="number" min={0} max={255} disabled={false} />
            </Form.Item>
            <Form.Item
                name="minmax_filter"
                label={translate("Min/Max Filter")}
                initialValue={minmax_filter}
                rules={DEVICE_VALIDATE.MINMAX_FILTER}
                hidden={isSerial}
            >
                <Input type="number" min={0} max={10} disabled={false} />
            </Form.Item>
            <Form.Item
                name="baud"
                label={translate("Baud Rate")}
                initialValue={baud || 9600}
                rules={DEVICE_VALIDATE.BAUD}
                hidden={!isSerial}
            >
                <Select options={K.BAUD_RATE} disabled={false} />
            </Form.Item>
            {!isInit && (
                <Permission forResource resource="devices" canDo="full">
                    <Form.Item label=" " className="mb-3" colon={false}>
                        <DangerZoneItem
                            title="Unlink device"
                            description="Removes all channels, may affect machine OEE and fusion charts."
                            button={
                                <AukButton.Red
                                    size="small"
                                    onClick={() => {
                                        confirm({
                                            title: `Unlink Device ${mac_address}`,
                                            icon: <ExclamationCircleOutlined />,
                                            content: 'This action is not reversible, continue?',
                                            onOk: () => {
                                                dispatch(
                                                    unlinkDevice(
                                                        props.device.asset_id,
                                                        props.device.device_id,
                                                        () => onUnlink && onUnlink()
                                                    )
                                                );
                                            },
                                        });
                                    }}
                                >
                  Unlink
                                </AukButton.Red>
                            }
                        />
                    </Form.Item>
                </Permission>
            )}
        </Form>
    );
});

export default DeviceForm;
