/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import { DeviceConstants as K } from '../../../store/old/Devices/Devices.constants';
import DeviceInterfaceSelect from './components/DeviceInterfaceSelect';
import { DeviceScanNode } from './components/DeviceScan';
import { Panel, PanelBody } from '../../components/Panel';
import { useDispatch } from 'react-redux';
import useCurrentEntity from '../../../hooks/useCurrentEntity';
import { initializeDevice, linkDevice } from '../../../store/old/Devices/Devices.action';

const INTERFACE_SELECT = 1;
const DEVICE_INIT = 2;

const LinkFlow = (props) => {
    const dispatch = useDispatch();
    const deviceInitFormRef = useRef();
    const { entityId } = useCurrentEntity()

    const [step, setStep] = useState(INTERFACE_SELECT);
    const [deviceInterface, setDeviceInterface] = useState('')


    if (step === INTERFACE_SELECT) {
        return (
            <DeviceInterfaceSelect onChange={(inputType) => {
                setDeviceInterface(inputType)
                setStep(DEVICE_INIT);
            }}/>
        );
    }

    return (
        <DeviceScanNode
            showSubmit
            showBack
            handleClickBack={() => setStep(INTERFACE_SELECT)}
            forModel={K.MODELS.NODE}
            ref={deviceInitFormRef} 
            
            submit={(data) => { 
                dispatch(initializeDevice(entityId, data.dmac, data.gatewayId, { interface: deviceInterface }, (created) => {
                    dispatch(linkDevice(props.asset.asset_id, created.device_id, (updated) => {
                        props.onSubmit(updated);
                    }))
                }))
            }}
        />
    );
};

export default LinkFlow;