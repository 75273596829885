import { MetadataConstants as K } from './Metadata.constants';
import { Metadatum } from '../../../legacy/models';

export const ResetMetadataState = () => {
    return {
        type: K.ACTIONS.RESET_METADATA_STATE,
    };
};

export const setMetadataResource = (metadata) => {
    return {
        type: K.ACTIONS.SET_METADATA_RESOURCE,
        payload: { metadata },
    };
};

export const AddMetadataResource = (metadata) => {
    return {
        type: K.ACTIONS.ADD_METADATA_RESOURCE,
        payload: { metadata },
    };
};

export const removeMetadataResource = (metadata_ids) => {
    return {
        type: K.ACTIONS.REMOVE_METADATA_RESOURCE,
        payload: { metadata_ids },
    };
};

export const UpdateMetadatum = (asset_id, data, callback) => {
    return {
        type: K.ACTIONS.UPDATE_METADATUM_REQUEST,
        callback,
        payload: { asset_id, data },
    };
};
