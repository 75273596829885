import React, { useEffect, useState } from "react"
import WithBarcode from "../../../components/WithBarcode"
import { Input } from "antd"
import { ArrowRightOutlined } from "@ant-design/icons"
import { AnyFunction } from "../../../../types/any"

const DeviceSerialInputScan = (props: Props) => {
    const [value, setValue] = useState<string>(props.value || "")

    useEffect(() => {
        props.onChange && props.onChange(value)
    }, [value])

    return <WithBarcode handleScan={(value: string) => { setValue(value)}}>
        <Input
            value={value}
            onChange={(e) => setValue(e.target.value)}
            addonAfter={props.submit ? <NextArrow onClick={() => props.submit && props.submit(value)}/> : null}
            maxLength={50}
        />
    </WithBarcode>
}

export default DeviceSerialInputScan

interface Props {
    value?: string;
    onChange?: AnyFunction
    submit?: AnyFunction
}

const NextArrow = (props: NextArrowProps) => {
    return <span
        className="d-flex align-items-center px-2"
        onClick={props.onClick}
        style={{ cursor: "pointer" }}
    >
        <ArrowRightOutlined />
    </span>
}

interface NextArrowProps {
    onClick?: AnyFunction
}