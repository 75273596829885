/* eslint-disable react/prop-types */
import React from 'react';

import { Panel } from './Panel';

import './ModalPanel.scss';
import PortalWrapper from './PortalWrapper';

const ModalPanel = (props) => {
    return (
        <PortalWrapper domNode="#root">
            <div className="auk-modalpanel">
                <div className="auk-modalpanel__close">X</div>
                <div className="auk-modalpanel__underlay" />
                <div className={`auk-modalpanel__main ${props.className || ''}`}>
                    <Panel>{props.children}</Panel>
                </div>
            </div>
        </PortalWrapper>
    );
};

export default ModalPanel;
