/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge, TreeSelect } from 'antd';

// COMPONENTS
import AukTooltip from '../AukTooltip';
import Modal from '../Modal';
import { withEntity } from '../../Wrappers/HOCs/withEntity';

// REDUX
import { LogOut } from '../../../store/old/Authentication/Authentication.action';
import { changeEntityFlow } from '../../../store/old/Entity/Entity.action';

// HELPERS
import CONSTANTS from '../../Constants';
import { regexMatch } from '../../utils/helpers';
import UserAvatar from '../Avatar';

import './Header.scss';
import { QuestionCircleOutlined } from '@ant-design/icons';
import withRouter from '../../Wrappers/HOCs/withRouter';
import SystemInbox from './SystemInbox';
import CompanyLogo from '../CompanyLogo';
import translate from '../../utils/translate';
import { ws_generateAuthKey } from '../../../api/workspaceApi';
import { useAppSelector } from '../../../store/hooks';
import { userSelector } from '../../../store/old/Authentication/Authentication.selector';
import { errorFlash } from '../Flash';

class HeaderAuthenticated extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeProfile: false,
            modal: {
                show: false,
                body: '',
                onProceed: () => {},
            },
        };

        this.resetModal = this.resetModal.bind(this);
    }

    handleProfile = () => {
        this.setState((prev) => {
            return { activeProfile: !prev.activeProfile };
        });
    };

    profile = () => {
        this.resetState();
        this.props.router.navigate(CONSTANTS.URLS.PROFILE);
    };

    logout = () => {
        this.props.logout(() => {
            this.props.router.navigate('/login');
            window.location.reload();
        });
    };

    resetState = () => {
        this.setState({ activeProfile: false });
    };

    handleChange = (id) => {
        if (this.props.entity.entity_id === id) return;
        const destination = this.props.entities[id];

        if (this.shouldAlertEntityChange) {
            return this.setState({
                modal: {
                    show: true,
                    body: (
                        <div>
                            <p>
                This will redirect you to{' '}
                                <strong>{destination.entity_name}</strong>&apos;s dashboard.
                            </p>
                        </div>
                    ),
                    onProceed: () => {
                        this.props.router.navigate(`${CONSTANTS.URLS.DASHBOARD}?entity_id=${id}`);
                        this.resetModal();
                    },
                },
            });
        }

        const query = window.location.search.replace(`entity_id=${this.props.entity.entity_id}`, `entity_id=${id}`)
        this.props.router.navigate(`${window.location.pathname}${query}`);
    };

    resetModal = () => {
        this.setState({
            modal: {
                show: false,
                body: '',
                onProceed: () => {},
            },
        });
    };

    get shouldAlertEntityChange() {
        const blacklist = [CONSTANTS.URLS.ASSET, CONSTANTS.URLS.BLOCK, CONSTANTS.URLS.PARETO];
        const {
            location: { href: url },
        } = window;

        return blacklist.reduce((acc, curr) => {
            if (acc === true) return true;
            return !!regexMatch(url, curr);
        }, false);
    }

    get orgTreeData() {
        return [getTreeData(this.props.organisation)];
    }

    render() {
        const { user, entity } = this.props;

        const EntitySelect = (
            <TreeSelect
                value={entity.entity_id}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={this.orgTreeData}
                treeDefaultExpandAll
                onChange={(val) => this.handleChange(val)}
            />
        );

        const guide = (
            <Link className="header-auth__icon" to={CONSTANTS.URLS.USER_GUIDE}>
                <AukTooltip.Info title="User Guide" placement="right">
                    <QuestionCircleOutlined />
                </AukTooltip.Info>
            </Link>
        );

        const inbox = (
            <div className="header-auth__icon">
                <AukTooltip.Info title="Inbox" placement="left">
                    <span>
                        <SystemInbox />
                    </span>
                </AukTooltip.Info>
            </div>
        );

        const auth = (
            <div className="d-flex h-100 w-100">
                <div className="company-name">
                    <CompanyLogo/>
                    {EntitySelect}
                </div>
                <div className='app-title'>
                    <div className="d-flex align-items-center">
                        Factory Dashboard
                    </div>
                </div>
                <div className="collapse navbar-collapse">
                    <div className="navbar-nav"></div>
                    <div className="d-flex align-items-center">
                        <WorkspacesButton/>
                        {inbox}
                        {guide}
                        <ul className="navbar-nav">
                            <li
                                tabIndex="0"
                                className={`nav-item dropdown user-menu ${
                                    this.state.activeProfile ? 'show' : ''
                                }`}
                            >
                                <a
                                    onClick={this.handleProfile}
                                    className="nav-link dropdown-toggle d-flex align-items-center justify-content-between"
                                    id="navbarDropdownMenuLink"
                                    aria-haspopup="true"
                                    aria-expanded={this.state.activeProfile}
                                >
                                    <UserAvatar user={user} />
                                    <div className="logo">
                                        <div className="l">{user && user.nameFull}</div>
                                        <div className="e">{user && user.email}</div>
                                    </div>
                                </a>
                                <div
                                    className={`dropdown-menu ${
                                        this.state.activeProfile ? 'show' : ''
                                    }`}
                                    aria-labelledby="navbarDropdownMenuLink"
                                >
                                    <button
                                        className="dropdown-item"
                                        href="#"
                                        onClick={this.profile}
                                    >
                                        {translate('profile')}
                                    </button>
                                    <div className="dropdown-divider" />
                                    <button
                                        className="dropdown-item"
                                        href="#"
                                        onClick={this.logout}
                                    >
                                        {translate('logout')}
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );



        const hamburger = (
            <div className="hidden-lg">
                {/* <WorkspacesButton/> */}
                {guide}
                <ul className="navbar-nav">
                    <li
                        tabIndex="0"
                        className={`nav-item dropdown user-menu ${
                            this.state.activeProfile ? 'show' : ''
                        }`}
                    >
                        <button
                            className="nav-link navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarNav"
                            aria-controls="navbarNav"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            style={{ marginRight: '5px' }}
                            onClick={this.handleProfile}
                        >
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div
                            className={`dropdown-menu ${
                                this.state.activeProfile ? 'show' : ''
                            }`}
                        >
                            <button className="dropdown-item">
                                <Link to="user">Profile</Link>
                            </button>
                            <div className="dropdown-divider" />
                            <button className="dropdown-item" href="#" onClick={this.logout}>
                Logout
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
        );

        return (
            <div className="header-auth">
                {auth}
                {hamburger}
                <Modal
                    title="Change Entity"
                    body={this.state.modal.body}
                    show={this.state.modal.show}
                    toggle={() => this.resetModal()}
                    onProceed={() => this.state.modal.onProceed()}
                />
            </div>
        );
    }
}

const mapStateToProps = (appState) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: (callback) => dispatch(LogOut(callback)),
        changeEntity: (entity_id, callback) =>
            dispatch(changeEntityFlow(entity_id, callback)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withEntity(withRouter(HeaderAuthenticated)));

const getTreeData = (node) => {
    if (node.children && node.children.length) {
        return {
            title: node.entity_name,
            value: node.entity_id,
            children: node.children.map((c) => getTreeData(c)),
            disabled: node.accessDenied,
        };
    }

    return {
        title: node.entity_name,
        value: node.entity_id,
        disabled: node.accessDenied,
    };
};

const WorkspacesButton = () => {
    const user = useAppSelector(userSelector)

    const href = window.location.hostname === 'ops.auk.industries' ? "https://work.auk.industries" : "https://auk-formapp-frontend.vercel.app/";

    const handleClick = async () => {
        try {
            const { data } = await ws_generateAuthKey(user.user_id);
            const url = `${process.env.REACT_APP_WORKSPACE_APP_URL}login_by_key?key=${data.auth_key}`
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        } catch(e) {
            errorFlash(e)
        }
    }
    return (
        <Badge color='gray' title="Beta early access - This is a pilot release of the Digital Workspace. This product and any associated commercial terms might be changed and is not subject to any SLA or deprecation policy. Your feedback will help us build a high-quality product. Please send questions and comments to service@auk.industries." dot offset={[-10, 0]}>
            <a 
                className="workspace" 
                href={href}
                onClick={(e) => {
                    e.preventDefault()
                    handleClick()
                }}
                target="_blank" rel="noreferrer"
            >
                <div class="d-flex align-items-center">Digital Workspace</div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" ariaHidden="true" data-slot="icon" style={{height: 20, width: 20, marginLeft: 12}}>
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"/>
                </svg>
            </a>
        </Badge>
    )
}