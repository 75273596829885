/* eslint-disable react/prop-types */
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';

// COMPONENTS
import FormCustomField from '../../../components/FormCustomField';
import Select from '../../../components/Select';
import SelectWithDisabledOptions from '../../../components/SelectWithDisabledOptions';
import { Permission } from '../../../components/Permission';
import {
    Panel,
    PanelHeader,
    PanelBody,
    PanelFooter,
} from '../../../components/Panel';
import { flash } from '../../../components/Flash';

// CONSTANTS
import {
    CATEGORIES,
    NOTIFY_FIELDS,
} from '../../../../store/old/Notifications/Notifications.constants';

// HELPERS
import { isValidFloat } from '../../../utils/validate';
import translate from '../../../utils/translate';

class NotificationsModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            category: null,
            topic: null,
            asset: null,
            device: null,
            metadatum: null,
            gateway: null,
            trigger_conditions: [{ th: 0, op: '' }],
            trigger_status: null,
            duration: 0,
            frequency: null,
            recipients: [],
            mode: [],
            correlation: null,
        };

        this.handleChangeInput = this.handleChangeInput.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handleClickAddTriggerCondition =
      this.handleClickAddTriggerCondition.bind(this);
        this.handleClickRemoveTriggerCondition =
      this.handleClickRemoveTriggerCondition.bind(this);

        this.handleClickSubmit = this.handleClickSubmit.bind(this);
        this.handleClickDelete = this.handleClickDelete.bind(this);

        this.reset = this.reset.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.selection !== this.props.selection) {
            this.props.selection && this.unpackData();
        }
    }

    reset() {
        this.setState(
            {
                category: null,
                topic: null,
                asset: null,
                metadatum: null,
                gateway: null,
                trigger_conditions: [{ th: '', op: '' }],
                duration: 0,
                frequency: null,
                recipients: [],
                mode: [],
                trigger_status: null,
                correlation: null,
                device: null,
            },
            this.props.toggle
        );
    }

    /* populate form if update */
    unpackData() {
        const {
            props: {
                selection: {
                    category: _category,
                    topic: _topic,
                    device_id: _device_id,
                    trigger: _trigger,
                    frequency: _frequency,
                    mode: _mode,
                    asset,
                    metadatum,
                    device,
                    correlation,
                    // recipients,
                    recipient_users,
                    recipient_groups,
                },
            },
        } = this;

        const { assets, metadata, groups, users, correlations, gateways } =
      this.props;

        const category =
      NOTIFY_FIELDS.CATEGORIES.find(({ value }) => value === _category) || null;
        const topic =
      NOTIFY_FIELDS.TOPICS.find(({ value }) => value === _topic) || null;
        const gateway = gateways.find((gw) => gw.device_id === _device_id) || null;
        const trigger_conditions =
      _trigger && _trigger.pri ? _trigger.pri : [{ th: '', op: '' }];
        const duration = _trigger ? _trigger.sec.dur : 0;
        const trigger_status =
      _trigger && _trigger.status
          ? NOTIFY_FIELDS.STATUS.find(({ value }) => value === _trigger.status) ||
          null
          : null;
        const frequency = NOTIFY_FIELDS.FREQUENCIES.find(
            (f) => f.value === _frequency
        );
        const recipients = [
            ...recipient_users.map((id) => users[id]),
            ...recipient_groups.map((id) => groups[id]),
        ];

        const mode = _mode
            .split(',')
            .map((m) => m.trim())
            .map((m) => NOTIFY_FIELDS.MODES.find(({ value }) => value === m));

        this.setState({
            category,
            topic,
            asset,
            metadatum,
            gateway,
            trigger_conditions,
            trigger_status,
            duration,
            frequency,
            recipients,
            mode,
            correlation,
            device,
        });
    }

    /* form data */
    get data() {
    // data to be sent to backend
        const {
            category: { value: category },
        } = this.state;

        switch (category) {
        case CATEGORIES.ALERT:
            return this.collateDataForAlert();
        case CATEGORIES.GATEWAY:
            return this.collateDataForGateway();
        case CATEGORIES.OUTLIER:
            return this.collateDataForOutlier();
        case CATEGORIES.NODE:
            return this.collateDataForNode();
        default:
            return null;
        }
    }

    get recipient_users() {
        const result = this.state.recipients
            .filter((r) => r.user_id)
            .map((u) => u.user_id);
        return result.length ? result : null;
    }

    get recipient_groups() {
        const result = this.state.recipients
            .filter((r) => r.group_id)
            .map((g) => g.group_id);
        return result.length ? result : null;
    }

    get mode() {
        return this.state.mode.map((m) => m.value).join(',');
    }

    get trigger() {
        return {
            pri: this.state.trigger_conditions.map((c) => ({
                ...c,
                th: parseFloat(c.th),
            })),
            sec: { dur: +this.state.duration },
        };
    }

    get nodeTrigger() {
        return {
            status: this.state.trigger_status.value,
            sec: { dur: +this.state.duration },
        };
    }

    collateDataForReport() {
        if (!this.reportFormValid) {
            flash({
                message: 'Please ensure all fields are valid',
                status: 'warning',
            });
            return null;
        }

        const {
            mode,
            recipient_users,
            recipient_groups,
            state: {
                category: { value: category },
                frequency: { value: frequency },
                topic: { value: report_topic },
            },
        } = this;

        return {
            mode,
            recipient_users,
            recipient_groups,
            category,
            frequency,
            report_topic,
        };
    }

    collateDataForAlert() {
        if (!this.alertFormValid) {
            flash({
                message: 'Please ensure all fields are valid',
                status: 'warning',
            });
            return null;
        }

        const {
            recipient_groups,
            recipient_users,
            mode,
            trigger,
            state: {
                category: { value: category },
                topic: { value: alert_topic },
                asset: { asset_id, asset_name },
                metadatum: { device_id, channel },
            },
        } = this;

        const message = `${alert_topic} for ${asset_name}`;

        return {
            category,
            trigger,
            asset_id,
            asset_name,
            device_id,
            channel,
            recipient_groups,
            recipient_users,
            mode,
            alert_topic,
            message,
        };
    }

    collateDataForOutlier() {
        if (!this.correlationFormValid) {
            flash({
                message: 'Please ensure all fields are valid',
                status: 'warning',
            });
            return null;
        }

        const {
            recipient_groups,
            recipient_users,
            mode,
            state: {
                category: { value: category },
                correlation: { correlation_id },
            },
        } = this;

        return {
            category,
            recipient_groups,
            recipient_users,
            mode,
            correlation_id,
        };
    }

    collateDataForGateway() {
        if (!this.gatewayFormValid) {
            flash({
                message: 'Please ensure all fields are valid',
                status: 'warning',
            });
            return null;
        }

        const {
            recipient_users,
            recipient_groups,
            mode,
            state: {
                category: { value: category },
                gateway: { device_id, entity_id },
            },
        } = this;

        return {
            category,
            device_id,
            entity_id,
            recipient_groups,
            recipient_users,
            mode,
        };
    }

    collateDataForNode() {
        if (!this.nodeFormValid) {
            flash({
                message: 'Please ensure all fields are valid',
                status: 'warning',
            });
            return null;
        }

        const {
            recipient_users,
            recipient_groups,
            mode,
            nodeTrigger: trigger,
            state: {
                category: { value: category },
                device: { device_id },
            },
        } = this;

        return {
            category,
            device_id,
            recipient_groups,
            recipient_users,
            trigger,
            mode,
        };
    }

    get formValid() {
        return this.state.category;
    }

    get alertFormValid() {
        const {
            state: { topic, asset, metadatum, mode, recipients },
            trigger,
        } = this;

        const triggerValid = !trigger.pri.filter((c) => !isValidFloat(c.th)).length;

        return !!(
            topic &&
      asset &&
      metadatum &&
      mode.length &&
      recipients.length &&
      triggerValid
        );
    }

    get correlationFormValid() {
        const {
            state: { correlation, recipients, mode },
        } = this;
        return !!(correlation && recipients.length && mode.length);
    }

    get gatewayFormValid() {
        const { gateway, recipients, mode } = this.state;
        return !!(gateway && mode.length && recipients.length);
    }

    get reportFormValid() {
        const { topic, frequency, mode, recipients } = this.state;
        return !!(topic && frequency && mode.length && recipients.length);
    }

    get nodeFormValid() {
        const { device, trigger_status, recipients, mode } = this.state;
        return !!(device && trigger_status && recipients && mode);
    }

    handleClickSubmit() {
        if (!this.formValid)
            return flash({
                message: 'Please ensure all fields are valid',
                status: 'warning',
            });

        const {
            data,
            isUpdate,
            props: { selection, createNotification, updateNotification },
        } = this;

        if (!data) return;

        this.reset();
        return isUpdate
            ? updateNotification({
                ...data,
                notification_id: selection.notification_id,
            })
            : createNotification(data);
    }

    handleClickDelete() {
        this.props.deleteNotification(this.props.selection);
        this.reset();
    }

    get isUpdate() {
        return this.props.selection;
    }

    /* event handlers */
    handleChangeInput(field, e) {
        this.setState({ [field]: e.target.value });
    }

    handleChangeSelect(field, e) {
        if (e && e.disabled) return;
        this.setState({ [field]: e });
    }

    handleChangeMultiSelect(field, e) {
        this.setState({ [field]: e });
    }

    handleChangeSelectTriggerOp(index, e) {
        const { trigger_conditions } = this.state;
        const condition = { ...trigger_conditions[index], op: e.value };
        trigger_conditions[index] = condition;

        this.setState({ trigger_conditions: trigger_conditions.concat() });
    }

    handleChangeInputTriggerTh(index, e) {
        const { trigger_conditions } = this.state;
        const condition = { ...trigger_conditions[index], th: e.target.value };
        trigger_conditions[index] = condition;

        this.setState({ trigger_conditions: trigger_conditions.concat() });
    }

    handleClickAddTriggerCondition() {
        const { trigger_conditions } = this.state;
        const default_condition = { th: '', op: '' };

        this.setState({
            trigger_conditions: trigger_conditions.concat(default_condition),
        });
    }

    handleClickRemoveTriggerCondition(index) {
        const { trigger_conditions } = this.state;
        trigger_conditions.splice(index, 1);

        this.setState({ trigger_conditions: trigger_conditions.concat() });
    }

    get defaultForm() {
        return (
            <>
                {this.categoryField}
                {this.topicField}
                {/* {this.frequencyField} */}
                {this.recipientsField}
                {this.communicationField}
            </>
        );
    }

    get alertForm() {
        return (
            <>
                {this.categoryField}
                {this.topicField}
                {this.conditionFields}
                {this.recipientsField}
                {this.communicationField}
            </>
        );
    }

    get reportForm() {
        return this.defaultForm;
    }

    get gatewayForm() {
        return (
            <>
                {this.categoryField}
                {this.gatewayField}
                {this.recipientsField}
                {this.communicationField}
            </>
        );
    }

    get outlierForm() {
        return (
            <>
                {this.categoryField}
                {this.correlationField}
                {this.recipientsField}
                {this.communicationField}
            </>
        );
    }

    get nodeForm() {
        return (
            <>
                {this.categoryField}
                {this.nodeField}
                {this.nodeStatusField}
                {this.triggerDurationField}
                {this.recipientsField}
                {this.communicationField}
            </>
        );
    }

    get form() {
        const { category } = this.state;
        if (!category) return this.defaultForm;

        switch (category.value) {
        case CATEGORIES.ALERT:
            return this.alertForm;
        case CATEGORIES.GATEWAY:
            return this.gatewayForm;
        case CATEGORIES.OUTLIER:
            return this.outlierForm;
        case CATEGORIES.REPORT:
            return this.reportForm;
        case CATEGORIES.NODE:
            return this.nodeForm;
        default:
            return null;
        }
    }

    get footer() {
        let isFullPermission = this.props.authUser.check_resource_policy(
            'notifications',
            false,
            false,
            true
        );

        const buttons = [
            {
                onClick: this.reset,
                label: translate('cancel'),
                className: 'btn b-cancel',
                display: true,
            },
            {
                onClick: this.handleClickDelete,
                label: translate('delete'),
                className: 'btn b-delete',
                display: isFullPermission,
            },
            {
                onClick: this.handleClickSubmit,
                label: this.isUpdate ? translate('save') : translate('add'),
                className: 'btn b-save',
                display: true,
            },
        ];

        return (
            <>
                {buttons
                    .filter((b) => b.display)
                    .map((b, i) => (
                        <button
                            key={i}
                            className={`${b.className} text-uppercase`}
                            onClick={b.onClick}
                        >
                            {b.label}
                        </button>
                    ))}
            </>
        );
    }

    render() {
        return (
            <Modal
                isOpen={this.props.modal} // backdrop='static'
                toggle={this.reset}
                className="notifications-form"
            >
                <Panel>
                    <PanelHeader>
                        {translate('createeditnotification')}
                    </PanelHeader>
                    <PanelBody>{this.form}</PanelBody>
                    <PanelFooter>{this.footer}</PanelFooter>
                </Panel>
            </Modal>
        );
    }

    get gatewayField() {
        const { gateways } = this.props;
        const { gateway } = this.state;

        return (
            <FormFieldLabelWrapper label={translate('gateway')}>
                <div className="col-sm-8">
                    <SelectWithDisabledOptions
                        className="font-weight-normal"
                        value={gateway}
                        options={gateways}
                        getOptionLabel={(opt) => opt.serial_number}
                        onChange={(e) => this.handleChangeSelect('gateway', e)}
                        placeholder="Gateway"
                        isDisabled={this.isUpdate}
                    />
                </div>
            </FormFieldLabelWrapper>
        );
    }

    get categoryField() {
        const { category } = this.state;

        return (
            <FormFieldLabelWrapper label={translate('category')}>
                <div className="col-sm-8">
                    <SelectWithDisabledOptions
                        className="font-weight-normal"
                        value={category}
                        options={NOTIFY_FIELDS.CATEGORIES}
                        getOptionLabel={(opt) => opt.label}
                        onChange={(e) => this.handleChangeSelect('category', e)}
                        placeholder="Category (Required)"
                        isDisabled={this.isUpdate}
                    />
                </div>
            </FormFieldLabelWrapper>
        );
    }

    get topicField() {
        const { topic } = this.state;

        return (
            <FormFieldLabelWrapper label={translate('topic')}>
                <div className="col-sm-8">
                    <SelectWithDisabledOptions
                        className="font-weight-normal"
                        value={topic}
                        options={NOTIFY_FIELDS.TOPICS}
                        onChange={(e) => this.handleChangeSelect('topic', e)}
                        isDisabled={this.isUpdate}
                        placeholder="Topic"
                    />
                </div>
            </FormFieldLabelWrapper>
        );
    }

    get machineSensorFields() {
        const { assets } = this.props;
        const { asset, metadatum } = this.state;

        const isAssetSelected = asset;

        return (
            <FormFieldLabelWrapper label={translate('monitoring')}>
                <div className="col-4 pr-1">
                    <Select
                        value={asset}
                        options={assets}
                        getOptionLabel={(opt) => opt.asset_name}
                        onChange={(e) => this.handleChangeSelect('asset', e)}
                        placeholder="Machine"
                        isDisabled={this.isUpdate}
                    />
                </div>
                <div className="col-4 pl-1">
                    <Select
                        value={metadatum}
                        options={asset ? asset.inputs : []}
                        isDisabled={this.isUpdate || !isAssetSelected}
                        getOptionLabel={(opt) => opt.chart_title}
                        onChange={(e) => this.handleChangeSelect('metadatum', e)}
                        placeholder="Sensor"
                    />
                </div>
            </FormFieldLabelWrapper>
        );
    }

    get triggerDurationField() {
        const { duration } = this.state;
        const isValid = isValidFloat(duration) || duration === '';

        return (
            <FormFieldLabelWrapper
                label={<span>{translate('continuousduration')} {" "} ({translate('seconds')}) </span>}
            >
                <div className="col-8">
                    <input
                        className={`form-control ${isValid ? '' : 'is-invalid'}`}
                        value={duration}
                        onChange={(e) => this.handleChangeInput('duration', e)}
                        placeholder="(optional) Notify immediately if left blank"
                    />
                </div>
            </FormFieldLabelWrapper>
        );
    }

    get nodeField() {
        const { device } = this.state;

        const options = this.props.devices.filter(d => d.interface_type !== 'opcua')

        return (
            <FormFieldLabelWrapper label={translate('node')}>
                <div className="col-8">
                    <Select
                        value={device}
                        options={options}
                        getOptionLabel={(opt) =>
                            `${opt.mac_address} (${opt.asset.asset_name})`
                        }
                        onChange={(e) => this.handleChangeSelect('device', e)}
                        placeholder="Node"
                    />
                </div>
            </FormFieldLabelWrapper>
        );
    }

    get nodeStatusField() {
        const { trigger_status } = this.state;

        return (
            <FormFieldLabelWrapper label={translate('status')}>
                <div className="col-8">
                    <Select
                        key={'nodestatus'}
                        value={trigger_status}
                        options={NOTIFY_FIELDS.STATUS}
                        getOptionLabel={(opt) => opt.label}
                        getOptionValue={(opt) => opt.id}
                        onChange={(e) => this.handleChangeSelect('trigger_status', e)}
                        placeholder="Online / Offline"
                    />
                </div>
            </FormFieldLabelWrapper>
        );
    }

    get conditionFields() {
        const { trigger_conditions } = this.state;

        return (
            <div className="alert-inputs">
                {this.machineSensorFields}
                {trigger_conditions.map((listValue, i) =>
                    this.triggerField(listValue, i)
                )}
                {this.triggerDurationField}
            </div>
        );
    }

    get correlationField() {
        const { correlation } = this.state;
        return (
            <FormFieldLabelWrapper label="Correlation">
                <div className="col-sm-8">
                    <SelectWithDisabledOptions
                        className="font-weight-normal"
                        value={correlation || null}
                        options={this.props.correlations}
                        getOptionLabel={(opt) => opt.title}
                        onChange={(e) => this.handleChangeSelect('correlation', e)}
                        isDisabled={this.isUpdate}
                    />
                </div>
            </FormFieldLabelWrapper>
        );
    }

    get recipientsField() {
        const { isWhitelistedDemoUser, isClientUser } = this.props.authUser;

        const { recipients } = this.state;

        const recipient_options = [
            ..._.values(this.props.users),
            ..._.values(this.props.groups),
        ];

        return (
            <FormFieldLabelWrapper label={translate('recipients')}>
                <div className="col-sm-8">
                    <Select
                        key="recipients"
                        value={recipients}
                        options={
                            isClientUser || isWhitelistedDemoUser ? recipient_options : []
                        }
                        getOptionLabel={(opt) => opt.email || opt.group_name}
                        getOptionValue={(opt) => opt.user_id || opt.group_id}
                        onChange={(e) => this.handleChangeMultiSelect('recipients', e)}
                        placeholder="Choose recipients"
                        isMulti
                        hideSelectedOptions
                    />
                </div>
            </FormFieldLabelWrapper>
        );
    }

    get communicationField() {
        const { mode } = this.state;

        return (
            <FormFieldLabelWrapper label={translate('notifyby')}>
                <div className="col-sm-8">
                    <Select
                        key="mode"
                        value={mode}
                        options={NOTIFY_FIELDS.MODES}
                        onChange={(e) => this.handleChangeMultiSelect('mode', e)}
                        placeholder="Email / SMS / Whatsapp"
                        isMulti
                        hideSelectedOptions
                    />
                </div>
            </FormFieldLabelWrapper>
        );
    }

    triggerField(condition, index) {
        return (
            <FormFieldLabelWrapper
                key={index}
                label={index === 0 ? translate('conditions') : ''}
            >
                <div className="col-4 pr-1">
                    <Select
                        value={NOTIFY_FIELDS.OPERATORS.find(
                            (opt) => opt.value === condition.op
                        )}
                        options={NOTIFY_FIELDS.OPERATORS}
                        onChange={(e) => this.handleChangeSelectTriggerOp(index, e)}
                        placeholder="e.g. Greater than"
                    />
                </div>
                <div className="col-3 pl-1 pr-0">
                    <input
                        className={`form-control ${
                            isValidFloat(condition.th) ? '' : 'is-invalid'
                        }`}
                        value={condition.th}
                        onChange={(e) => this.handleChangeInputTriggerTh(index, e)}
                        placeholder="Value"
                    />
                </div>
                <div className="col-1 pl-0 d-flex justify-content-center align-items-center vcenter">
                    <div
                        className={
                            'btn-add-condition ' + (index !== 0 && 'btn-transform')
                        }
                        onClick={
                            index > 0
                                ? (event) => this.handleClickRemoveTriggerCondition(index)
                                : this.handleClickAddTriggerCondition
                        }
                    />
                </div>
            </FormFieldLabelWrapper>

        );
    }
}

const FormFieldLabelWrapper = (props) => {
    return (
        <FormCustomField>
            <label className="col-sm-4 col-form-label d-flex justify-content-end">
                {props.label}
            </label>
            {props.children}
        </FormCustomField>
    );
};

const mapStateToProps = (appState) => {
    return {
        authUser: appState.auth.user,
    };
};

export default connect(mapStateToProps)(NotificationsModal);
