import { DeviceConstants as K } from './Devices.constants';

export const ResetDevicesState = () => {
    return {
        type: K.ACTIONS.RESET_DEVICES_STATE,
    };
};

export const SetDevicesResource = (devices) => {
    return {
        type: K.ACTIONS.SET_DEVICES_RESOURCE,
        payload: { devices },
    };
};

export const addDevicesResource = (devices) => {
    return {
        type: K.ACTIONS.ADD_DEVICES_RESOURCE,
        payload: { devices },
    };
};

export const removeDevicesResource = (device_ids) => {
    return {
        type: K.ACTIONS.REMOVE_DEVICES_RESOURCE,
        payload: { device_ids },
    };
};

export const PreviewSerialCommsData = (payload, callback) => {
    return {
        type: K.ACTIONS.PREVIEW_SERIAL_COMMUNICATIONS_DATA,
        payload,
        callback,
    };
};

export const updateDevice = (payload, callback) => {
    return {
        type: K.ACTIONS.UPDATE_DEVICE_REQUEST,
        payload,
        callback,
    };
};

// export const CreateDevice = (payload, callback) => {
//     return {
//         type: K.ACTIONS.CREATE_DEVICE_REQUEST,
//         payload,
//         callback,
//     };
// };

export const UpdateDeviceEntity = (mac_address, callback) => {
    return {
        type: K.ACTIONS.UPDATE_DEVICE_ENTITY_REQUEST,
        payload: { mac_address },
        callback,
    };
};

export const initializeDevice = (entity_id, mac_address, gateway_id, options = {}, callback) => {
    return {
        type: K.ACTIONS.INITIALIZE_DEVICE_REQUEST,
        payload: { entity_id, mac_address, gateway_id, options },
        callback,
    };
}

export const initializeDeviceAsRelay = (mac_address, gateway_id, callback) => {
    return {
        type: K.ACTIONS.INITIALIZE_RELAY_REQUEST,
        payload: { mac_address, gateway_id },
        callback,
    };
};

export const linkDevice = (asset_id, device_id, callback) => {
    return {
        type: K.ACTIONS.LINK_DEVICE_REQUEST,
        payload: { asset_id, device_id },
        callback,
    };
};

export const unlinkDevice = (asset_id, device_id, callback) => {
    return {
        type: K.ACTIONS.UNLINK_DEVICE_REQUEST,
        payload: { asset_id, device_id },
        callback,
    };
};

export const cascadeUnlinkDevice = (device) => {
    return {
        type: K.ACTIONS.CASCADE_UNLINK_DEVICE,
        payload: { device },
    };
};

export const removeRelay = (device_id, callback) => {
    return {
        type: K.ACTIONS.REMOVE_RELAY_REQUEST,
        payload: { device_id },
        callback,
    };
};

export const createAssetOpcuaDevicesMetadata = (entity_id, asset_id, devices, callback) => {
    return {
        type: K.ACTIONS.CREATE_ASSET_OPCUA_DEVICES_METADATA,
        payload: { entity_id, asset_id, devices },
        callback,
    };
}

export const updateAssetOpcuaDevicesMetadata = (entity_id, asset_id, devices, callback) => {
    return {
        type: K.ACTIONS.UPDATE_ASSET_OPCUA_DEVICES_METADATA,
        payload: { entity_id, asset_id, devices },
        callback,
    };
}
