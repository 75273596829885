
import { Select } from "antd"
import React from "react"
import { DeviceConstants as K } from "../../../../store/old/Devices/Devices.constants"
import "./DataInputsSelect.scss"

const options = K.INTERFACE_TYPES.concat({ label: "OPCUA", value: "OPCUA" })

const DataInputsSelect = (props: Props) => {
    return <div className="data-inputs-select">
        <Select options={options} onChange={props.onChange} placeholder={"Select a data stream to begin"}/>
    </div>
}

interface Props {
    onChange: (value: string) => any
}

export default DataInputsSelect

