/* eslint-disable react/prop-types */

// LIBRARIES
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

// COMPONENTS
import NotificationManagement from './Notifications.component';

// SELECTORS
import { arrayAssetsWithoutUsingBlock } from '../../../store/old/Assets/Assets.selector';
import { arrayCorrelations } from '../../../store/old/Correlations/Correlations.selector';
import { arrayMetadata } from '../../../store/old/Metadata/Metadata.selector';
import { arrayNotifications } from '../../../store/old/Notifications/Notifications.selector';
import { arrayConnectedDevices } from '../../../store/old/Devices/Devices.selector';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';

// ACTIONS
import { fetchCorrelations } from '../../../store/old/Correlations/Correlations.action';
import {
    FetchNotifications,
    CreateNotificationRequest,
    DeleteNotificationRequest,
    UpdateNotificationRequest,
} from '../../../store/old/Notifications/Notifications.action';

// HELPERS
import { depthFirstTraversal, getMapFromArr } from '../../utils/helpers';

// API
import { api_getGatewayStatus } from '../../../store/old/Gateway/Gateway.services';
import { api_getUserGroups } from '../../../store/old/Group/Group.services';
import { errorFlash } from '../../components/Flash';
import withRouter from '../../Wrappers/HOCs/withRouter';

class NotificationsContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            gateways: [],
            groups: {},
        };

        this.getData();
    }

    getData() {
        const { entity_id } = this.props.entity;
        this.props.fetchNotifications(entity_id);
        this.props.fetchCorrelations(entity_id);
        this.fetchGateways();
        this.fetchRecipients();
    }

    fetchGateways() {
        const gateways = [];
        depthFirstTraversal(this.props.entity, (node) => {
            gateways.push(
                ...node.gateways.map((gw) => {
                    return {
                        entity_id: node.entity_id,
                        gateway_id: gw,
                    }
                })
            )
        }
        );

        const promises = gateways.map(({ entity_id, gateway_id }) =>
            api_getGatewayStatus(entity_id, gateway_id)
        );

        Promise.all(promises)
            .then((res) => this.setState({ gateways: res }))
            .catch((e) => errorFlash(e));
    }

    fetchRecipients() {
        const entities = [];

        depthFirstTraversal(this.props.authUser.entity, (node) =>
            entities.push(node.entity_id)
        );

        Promise.all(entities.map((id) => api_getUserGroups(id)))
            .then((res) => {
                const [_groups] = res;

                const groups = getMapFromArr(_.flattenDeep(_groups), 'group_id');
                this.setState({ groups });
            })
            .catch((e) => {
                errorFlash(e);
            });
    }

    render() {
        return (
            <NotificationManagement
                {...(this.props)}
                gateways={this.state.gateways}
                users={this.props.users}
                groups={this.state.groups}
            />
        );
    }
}

const mapStateToProps = (root) => {
    return {
        assets: arrayAssetsWithoutUsingBlock(root),
        devices: arrayConnectedDevices(root),
        metadata: arrayMetadata(root),
        notifications: arrayNotifications(root),
        correlations: arrayCorrelations(root),
        authUser: root.auth.user,
        entity: currentEntitySelector(root),
        users: root.user.list,
        gateways: root.gateway.gateways
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchNotifications: (entity_id) => dispatch(FetchNotifications(entity_id)),
        fetchCorrelations: (entity_id) => dispatch(fetchCorrelations(entity_id)),
        createNotification: (data) =>
            dispatch(CreateNotificationRequest(data)),
        updateNotification: (data) =>
            dispatch(UpdateNotificationRequest(data)),
        deleteNotification: (data) =>
            dispatch(DeleteNotificationRequest(data)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(NotificationsContainer));
