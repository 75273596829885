/* eslint-disable react/prop-types */

import { Modal } from 'antd';
import React from 'react';
import AukButton from '../../components/AukButton';
import { PanelBody, PanelFooter } from '../../components/Panel';

import './UserAgreement.scss';

export default class UserAgreement extends React.Component {
    render() {
        const { close, visible } = this.props;
        return (
            <Modal
                title="SOFTWARE-AS-A-SERVICE TERMS OF USE"
                visible={visible}
                width={600}
                className="user-agreement"
                footer={
                    <PanelFooter className="justify-content-end">
                        <AukButton.Blue onClick={close}>OK</AukButton.Blue>
                    </PanelFooter>
                }
            >
                <PanelBody>
                    <div
                        style={{
                            padding: 20,
                        }}
                    >
                        <ol className="c6 lst-kix_list_1-0 start" start="1">
                            <li className="c4 c10">
                                <span className="c7">APPLICATION OF TERMS</span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-1 start" start="1">
                            <li className="c3" id="h.1fob9te">
                                <span>These Terms</span>
                                <span className="c0">
                  &nbsp;apply to your use of the Service (as that term is
                  defined below). &nbsp;By accessing and using the Service:
                  &nbsp;
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-2 start" start="1">
                            <li className="c2">
                                <span className="c0">you agree to these Terms; and</span>
                            </li>
                            <li className="c2" id="h.3znysh7">
                                <span className="c0">
                  where your access and use is on behalf of another person (e.g.
                  a company), you confirm that you are authorised to, and do in
                  fact, agree to these Terms on that person’s behalf and that,
                  by agreeing to these Terms on that person’s behalf, that
                  person is bound by these Terms.
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-1" start="2">
                            <li className="c3">
                                <span className="c0">
                  If you do not agree to these Terms, you are not authorised to
                  access and use the Service, and you must immediately stop
                  doing so.
                                </span>
                            </li>
                        </ol>
                        <p className="c5">
                            <span className="c0"></span>
                        </p>
                        <ol className="c6 lst-kix_list_1-0" start="2">
                            <li className="c10 c14">
                                <h1 id="h.6sljnm25u3rc" style={{ display: 'inline' }}>
                                    <span className="c7">CHANGES</span>
                                </h1>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-1 start" start="1">
                            <li className="c3" id="h.2et92p0">
                                <span className="c0">
                  We may change these Terms at any time by notifying you of the
                  change by email or by posting a notice on the web application.
                  &nbsp;Unless stated otherwise, any change takes effect from
                  the date set out in the notice. &nbsp;You are responsible for
                  ensuring you are familiar with the latest Terms. &nbsp;By
                  continuing to access and use the Service from the date on
                  which the Terms are changed, you agree to be bound by the
                  changed Terms.
                                </span>
                            </li>
                            <li className="c3">
                                <span className="c7">
                  These Terms were last updated on 18 July 2018.
                                </span>
                            </li>
                        </ol>
                        <p className="c5">
                            <span className="c7"></span>
                        </p>
                        <ol className="c6 lst-kix_list_1-0" start="3">
                            <li className="c3 c10">
                                <span className="c7">INTERPRETATION</span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-1 start" start="1">
                            <li className="c4 c10">
                                <span className="c0">In these Terms:</span>
                            </li>
                            <li className="c4 c10">
                                <span>
                  Auk Industrial IoT System means the software owned by us (and
                  our licensors) that is used to provide the Service
                                </span>
                                <span>. </span>
                                <span className="c12">&nbsp;</span>
                            </li>
                            <li className="c4">
                                <span>Confidential Information</span>
                                <span className="c12">&nbsp;</span>
                                <span>
                  means any information that is not public knowledge and that is
                  obtained from the other party in the course of, or in
                  connection with, the provision and use of the Service.
                  &nbsp;Our Confidential Information includes Intellectual
                  Property owned by us (or our licensors), including the{' '}
                                </span>
                                <span>Auk Industrial IoT </span>
                                <span>System</span>
                                <span className="c0">
                  . &nbsp;Your Confidential Information includes the Data.
                                </span>
                            </li>
                            <li className="c4">
                                <span>Data</span>
                                <span className="c12">&nbsp;</span>
                                <span className="c0">
                  means all data, content, and information (including personal
                  information) owned, held, used or created by you or on your
                  behalf that is stored using, or inputted into, the Service.
                                </span>
                            </li>
                            <li className="c4">
                                <span className="c1">Fees</span>
                                <span className="c12">&nbsp;</span>
                                <span>means the applicable fees </span>
                                <span>
                  set out on our pricing guide on the Web Application at
                  https://www.auk.industries or
                                </span>
                                <span className="c0">
                  &nbsp;as agreed otherwise in writing between you and us, as
                  may be updated from time to time in accordance with
                  clause&nbsp;7.6.
                                </span>
                            </li>
                            <li className="c4">
                                <span className="c1">Force Majeure</span>
                                <span className="c12">&nbsp;</span>
                                <span className="c0">
                  means an event that is beyond the reasonable control of a
                  party, excluding:
                                </span>
                            </li>
                        </ol>
                        <ul className="c6 lst-kix_ybasnrl62d4w-1 start">
                            <li className="c15">
                                <span className="c0">
                  an event to the extent that it could have been avoided by a
                  party taking reasonable steps or reasonable care; or
                                </span>
                            </li>
                            <li className="c15">
                                <span className="c0">a lack of funds for any reason.</span>
                            </li>
                        </ul>
                        <ol className="c6 lst-kix_list_1-1" start="7">
                            <li className="c4">
                                <span className="c1">including</span>
                                <span className="c12 c1">&nbsp;</span>
                                <span className="c0">
                  and similar words do not imply any limit.
                                </span>
                            </li>
                            <li className="c4">
                                <span className="c1">Intellectual Property Rights</span>
                                <span className="c12">&nbsp;</span>
                                <span>
                  includes copyright and all rights existing anywhere in the
                  world conferred under statute, common law or equity relating
                  to inventions (including patents), registered and unregistered
                  trademarks and designs, circuit layouts, data and databases,
                  confidential information, know-how, and all other rights
                  resulting from intellectual activity. &nbsp;
                                </span>
                                <span className="c1">Intellectual Property </span>
                                <span className="c0">
                  has a consistent meaning, and includes any enhancement,
                  modification or derivative work of the Intellectual Property.
                                </span>
                            </li>
                            <li className="c4">
                                <span className="c1">Objectionable</span>
                                <span className="c12">&nbsp;</span>
                                <span className="c0">
                  includes being objectionable, defamatory, obscene, harassing,
                  threatening, harmful, or unlawful in any way.
                                </span>
                            </li>
                            <li className="c4">
                                <span className="c0">
                  a party includes that party’s permitted assigns.
                                </span>
                            </li>
                            <li className="c4">
                                <span>
                  Permitted Users means your personnel who are authorised to
                  access and use the Service on your behalf in accordance with
                  clause 5.3.
                                </span>
                                <span className="c0">&nbsp;</span>
                            </li>
                            <li className="c4">
                                <span>a </span>
                                <span className="c1">person</span>
                                <span className="c0">
                  &nbsp;includes an individual, a body corporate, an association
                  of persons (whether corporate or not), a trust, a government
                  department, or any other entity.
                                </span>
                            </li>
                            <li className="c4">
                                <span className="c1">personal information</span>
                                <span className="c0">
                  &nbsp;means information about an identifiable, living person.
                                </span>
                            </li>
                            <li className="c4">
                                <span className="c1">personnel</span>
                                <span className="c0">
                  &nbsp;includes officers, employees, contractors and agents,
                  but a reference to your personnel does not include us.
                                </span>
                            </li>
                            <li className="c4">
                                <span className="c1">Service</span>
                                <span className="c12">&nbsp;</span>
                                <span>
                  means the service having the core functionality described on
                  the web application, as the web application is updated from
                  time to time.
                                </span>
                            </li>
                            <li className="c4">
                                <span className="c1">Start Date</span>
                                <span className="c12">&nbsp;</span>
                                <span>means the date that you </span>
                                <span>first access or use the Service. </span>
                                <span className="c0">&nbsp;</span>
                            </li>
                            <li className="c4">
                                <span className="c1">Terms</span>
                                <span className="c12">&nbsp;</span>
                                <span className="c0">
                  means these terms titled SaaS terms of use.
                                </span>
                            </li>
                            <li className="c4">
                                <span className="c1">Underlying Systems</span>
                                <span className="c12">&nbsp;</span>
                                <span>means the </span>
                                <span className="c1">Auk Industrial IoT System </span>
                                <span className="c0">
                  Software, IT solutions, systems and networks (including
                  software and hardware) used to provide the Service, including
                  any third party solutions, systems and networks.
                                </span>
                            </li>
                            <li className="c4">
                                <span className="c1">We</span>
                                <span>, </span>
                                <span className="c1">us</span>
                                <span>&nbsp;or </span>
                                <span className="c1">our</span>
                                <span className="c12">&nbsp;</span>
                                <span>means </span>
                                <span className="c12">Auk Industries Pte. Ltd.</span>
                                <span className="c0">, company number 201606513C.</span>
                            </li>
                            <li className="c4">
                                <span className="c1">Web Application </span>
                                <span className="c12">&nbsp;</span>
                                <span className="c0">
                  means the internet site at https://ops.auk.industries/login,
                  or such other site notified to you by us.
                                </span>
                            </li>
                            <li className="c4">
                                <span className="c1">Year</span>
                                <span className="c12">&nbsp;</span>
                                <span className="c0">
                  means a 12-month period starting on the Start Date or the
                  anniversary of that date.
                                </span>
                            </li>
                            <li className="c4">
                                <span className="c1">You</span>
                                <span>&nbsp;or </span>
                                <span className="c1">your</span>
                                <span>&nbsp;means</span>
                                <span className="c12">&nbsp;</span>
                                <span className="c0">
                  you or, if clause 1.1b applies, both you and the other person
                  on whose behalf you are acting.
                                </span>
                            </li>
                            <li className="c4">
                                <span className="c0">
                  Words in the singular include the plural and vice versa.
                                </span>
                            </li>
                            <li className="c4">
                                <span className="c0">
                  A reference to a statute includes references to regulations,
                  orders or notices made under or in connection with the statute
                  or regulations and all amendments, replacements or other
                  changes to any of them.
                                </span>
                            </li>
                        </ol>
                        <p className="c18">
                            <span className="c0"></span>
                        </p>
                        <ol className="c6 lst-kix_list_1-0" start="4">
                            <li className="c3 c10">
                                <span className="c7">PROVISION OF THE SERVICE</span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-1 start" start="1">
                            <li className="c3">
                                <span className="c0">
                  We must use reasonable efforts to provide the Service:
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-2 start" start="1">
                            <li className="c2">
                                <span className="c0">
                  in accordance with these Terms and the laws of Singapore;
                                </span>
                            </li>
                            <li className="c2">
                                <span className="c0">
                  exercising reasonable care, skill and diligence; and
                                </span>
                            </li>
                            <li className="c2">
                                <span className="c0">
                  using suitably skilled, experienced and qualified personnel.
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-1" start="2">
                            <li className="c3">
                                <span className="c0">
                  Our provision of the Service to you is non-exclusive.
                  &nbsp;Nothing in these Terms prevents us from providing the
                  Service to any other person.
                                </span>
                            </li>
                            <li className="c3">
                                <span>
                  Subject to clause 4.4, we must use reasonable efforts to
                  ensure the Service is available. &nbsp;However, it is possible
                  that on occasion the Service may be unavailable to permit
                  maintenance or other development activity to take place, or in
                  the event of Force Majeure. &nbsp;We must use reasonable
                  efforts to publish on the web application and/or notify you by
                  email advance details of any unavailability.
                                </span>
                                <span className="c0">&nbsp; </span>
                            </li>
                            <li className="c3" id="h.q0ynz9hbnhhk">
                                <span>
                  Through the use of web services and APIs, the Service
                  interoperates with a range of third party service features.
                  &nbsp;We do not make any warranty or representation on the
                  availability of those features. &nbsp;Without limiting the
                  previous sentence, if a third party feature provider ceases to
                  provide that feature or ceases to make that feature available
                  on reasonable terms, we may cease to make available that
                  feature to you. &nbsp;To avoid doubt, if we exercise our right
                  to cease the availability of a third party feature, you are
                  not entitled to any refund, discount or other compensation.
                                </span>
                            </li>
                        </ol>
                        <p className="c5" id="h.tyjcwt">
                            <span className="c9"></span>
                        </p>
                        <ol className="c6 lst-kix_list_1-0" start="5">
                            <li className="c3 c10">
                                <span className="c7">YOUR OBLIGATIONS</span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-1 start" start="1">
                            <li className="c3 c10" id="h.3dy6vkm">
                                <span className="c0">You and your personnel must:</span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-2 start" start="1">
                            <li className="c2 c10" id="h.1t3h5sf">
                                <span className="c0">
                  use the Service in accordance with these Terms solely for:
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-3 start" start="1">
                            <li className="c13">
                                <span>your own internal business purposes</span>
                                <span className="c0">
                  &nbsp;to monitor, analyse and/or improve operations; and
                  &nbsp;
                                </span>
                            </li>
                            <li className="c13">
                                <span className="c0">lawful purposes; and</span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-2" start="2">
                            <li className="c2">
                                <span className="c0">
                  not resell or make available the Service to any third party
                  without the prior written consent from us, or otherwise
                  commercially exploit the Service.
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-1" start="2">
                            <li className="c3" id="h.4d34og8">
                                <span className="c0">
                  When accessing the Service, you and your personnel must:
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-2 start" start="1">
                            <li className="c2">
                                <span className="c0">
                  not impersonate another person or misrepresent authorisation
                  to act on behalf of others or us;
                                </span>
                            </li>
                            <li className="c2">
                                <span className="c0">
                  correctly identify the sender of all electronic transmissions;
                                </span>
                            </li>
                            <li className="c2">
                                <span className="c0">
                  not attempt to undermine the security or integrity of the
                  Underlying Systems;{' '}
                                </span>
                            </li>
                            <li className="c2">
                                <span className="c0">
                  not use, or misuse, the Service in any way which may impair
                  the functionality of the Underlying Systems or impair the
                  ability of any other user to use the Service;
                                </span>
                            </li>
                            <li className="c2">
                                <span className="c0">
                  not attempt to view, access or copy any material or data other
                  than:
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-3 start" start="1">
                            <li className="c13">
                                <span className="c0">
                  that which you are authorised to access; and
                                </span>
                            </li>
                            <li className="c13">
                                <span className="c0">
                  to the extent necessary for you to use the Service in
                  accordance with these Terms; and
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-2" start="6">
                            <li className="c2">
                                <span className="c0">
                  neither use the Service in a manner, nor transmit, input or
                  store any Data, that breaches any third party right (including
                  Intellectual Property Rights and privacy rights) or is
                  Objectionable, incorrect or misleading.
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-1" start="3">
                            <li className="c3" id="h.2s8eyo1">
                                <span>
                  Without limiting clause 5.2, no individual other than a
                  Permitted User may access or use the Service. &nbsp;You may
                  authorise any member of your personnel to be a Permitted User,
                  in which case you must provide us with the Permitted User’s
                  name and other information that we reasonably require in
                  relation to the Permitted User. &nbsp;You must procure each
                  Permitted User’s compliance with clauses 5.1 and 5.2 and any
                  other reasonable condition notified by us to you.
                                </span>
                            </li>
                            <li className="c3" id="h.17dp8vu">
                                <span>A breach of any of these Terms by your personnel </span>
                                <span>(including a Permitted User)</span>
                                <span className="c0">
                  &nbsp;is deemed to be a breach of these Terms by you.
                                </span>
                            </li>
                            <li className="c3">
                                <span className="c0">
                  You are responsible for procuring all licences, authorisations
                  and consents required for you and your personnel to use the
                  Service, including to use, store and input Data into, and
                  process and distribute Data through, the Service.{' '}
                                </span>
                            </li>
                        </ol>
                        <p className="c5">
                            <span className="c0"></span>
                        </p>
                        <ol className="c6 lst-kix_list_1-0" start="6">
                            <li className="c3 c10" id="h.3rdcrjn">
                                <span className="c7">DATA </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-1 start" start="1">
                            <li className="c3" id="h.26in1rg">
                                <span className="c0">You acknowledge that:</span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-2 start" start="1">
                            <li className="c2">
                                <span>we</span>
                                <span className="c1">&nbsp;</span>
                                <span className="c0">
                  may require access to the Data to exercise our rights and
                  perform our obligations under these Terms; and
                                </span>
                            </li>
                            <li className="c2">
                                <span className="c0">
                  to the extent that this is necessary but subject to clause 9,
                  we may authorise a member or members of our personnel to
                  access the Data for this purpose.{' '}
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-1" start="2">
                            <li className="c3">
                                <span className="c0">
                  You must arrange all consents and approvals that are necessary
                  for us to access the Data as described in clause 6.1.
                                </span>
                            </li>
                            <li className="c3 c10">
                                <span className="c0">You acknowledge and agree that:</span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-2 start" start="1">
                            <li className="c2" id="h.lnxbz9">
                                <span className="c0">we may:</span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-3 start" start="1">
                            <li className="c13">
                                <span>
                  use Data and information about your and your end users’ use of
                  the Services to generate anonymised and aggregated statistical
                  and analytical data (
                                </span>
                                <span className="c12">Analytical Data</span>
                                <span className="c0">); and</span>
                            </li>
                            <li className="c13">
                                <span>
                  use Analytical Data for our internal research and product
                  development purposes and to conduct statistical analysis and
                  identify trends and insights;{' '}
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-2" start="2">
                            <li className="c2">
                                <span className="c0">
                  our rights under clause 6.3a above will survive termination of
                  expiry of the Agreement; and{' '}
                                </span>
                            </li>
                            <li className="c2">
                                <span className="c0">
                  title to, and all Intellectual Property Rights in, Analytical
                  Data is and remains our property.
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-1" start="4">
                            <li className="c3" id="h.35nkun2">
                                <span className="c0">
                  You acknowledge and agree that to the extent Data contains
                  personal information, in collecting, holding and processing
                  that information through the Service, we are acting as your
                  agent for the purposes of the General Data Protection
                  Regulation 2018 and any other applicable privacy law.
                  &nbsp;You must obtain all necessary consents from the relevant
                  individual to enable us to collect, use, hold and process that
                  information in accordance with these Terms.
                                </span>
                            </li>
                            <li className="c3">
                                <span className="c0">
                  While we will take standard industry measures to backup all
                  Data stored using the Service, you agree to keep a separate
                  backup copy of all Data uploaded by you onto the Service
                  periodically.
                                </span>
                            </li>
                            <li className="c3" id="h.1ksv4uv">
                                <span>
                  You agree that we may store Data (including any personal
                  information) in secure servers, which may be based overseas
                  dependent on your geographic location and may access that Data
                  (including any personal information) from time to time.{' '}
                                </span>
                                <span className="c0">&nbsp;</span>
                            </li>
                            <li className="c3" id="h.s2vsklyf2ico">
                                <span className="c0">
                  You indemnify us against any liability, claim, proceeding,
                  cost, expense (including the actual legal fees charged by our
                  solicitors) and loss of any kind arising from any actual or
                  alleged claim by a third party that any Data infringes the
                  rights of that third party (including Intellectual Property
                  Rights and privacy rights) or that the Data is Objectionable,
                  incorrect or misleading.
                                </span>
                            </li>
                        </ol>
                        <p className="c5" id="h.44sinio">
                            <span className="c0"></span>
                        </p>
                        <ol className="c6 lst-kix_list_1-0" start="7">
                            <li className="c3 c10" id="h.2jxsxqh">
                                <span className="c7">FEES</span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-1 start" start="1">
                            <li className="c3">
                                <span className="c0">You must pay us the Fees. &nbsp;</span>
                            </li>
                            <li className="c3">
                                <span>
                  We will provide you with valid sales and/or tax invoices,
                  whichever are applicable on a{' '}
                                </span>
                                <span>monthly</span>
                                <span>&nbsp;or yearly</span>
                                <span>&nbsp;basis prior to the due date for payment.</span>
                                <span className="c0">&nbsp;</span>
                            </li>
                            <li className="c3">
                                <span className="c0">
                  The Fees exclude GST, which you must pay on taxable supplies.
                                </span>
                            </li>
                            <li className="c3">
                                <span className="c0">You must pay the Fees:</span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-2 start" start="1">
                            <li className="c2">
                                <span>on the dates set out in the</span>
                                <span>&nbsp;Payment Terms</span>
                                <span>, or if there are none, by the 20</span>
                                <span className="c20">th</span>
                                <span className="c0">
                  &nbsp;of the month following the date of invoice; and
                                </span>
                            </li>
                            <li className="c2">
                                <span className="c0">
                  electronically in cleared funds without any set off or
                  deduction.
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-1" start="5">
                            <li className="c3">
                                <span>
                  We may charge interest on overdue amounts. &nbsp;Interest will
                  be calculated from the due date to the date of payment (both
                  inclusive) at an annual percentage rate equal to the corporate
                  overdraft reference rate (monthly charging cycle) applied by
                  our primary trading bank as at the due date (or, if our
                  primary trading bank ceases to quote that rate, then the rate
                  which in the opinion of the bank is equivalent to that rate in
                  respect of similar overdraft accommodation expressed as a
                  percentage) plus 2% per annum.
                                </span>
                                <span className="c0">&nbsp; </span>
                            </li>
                            <li className="c3" id="h.y5io2k3gmj0m">
                                <span>We may increase the Fees by giving at least </span>
                                <span className="c1">30</span>
                                <span>
                  &nbsp;days’ notice. &nbsp;If you do not wish to pay the
                  increased Fees, you may terminate these Terms and your right
                  to access and use the Service on no less than{' '}
                                </span>
                                <span className="c1">10</span>
                                <span>
                  &nbsp;days’ notice, provided the notice is received by us
                  before the effective date of the Fee increase. &nbsp;If you do
                  not terminate these Terms and your right to access and use the
                  Service in accordance with this clause, you are deemed to have
                  accepted the increased Fees.{' '}
                                </span>
                                <span>&nbsp;</span>
                            </li>
                        </ol>
                        <p className="c5" id="h.z337ya">
                            <span className="c9"></span>
                        </p>
                        <ol className="c6 lst-kix_list_1-0" start="8">
                            <li className="c3 c10" id="h.3j2qqm3">
                                <span className="c7">INTELLECTUAL PROPERTY </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-1 start" start="1">
                            <li className="c3" id="h.1y810tw">
                                <span className="c0">
                  Subject to clause 8.2, title to, and all Intellectual Property
                  Rights in, the Service, the web application, and all
                  Underlying Systems is and remains our property (and our
                  licensors’ property). &nbsp;You must not contest or dispute
                  that ownership, or the validity of those Intellectual Property
                  Rights.
                                </span>
                            </li>
                            <li className="c3" id="h.4i7ojhp">
                                <span>
                  Title to, and all Intellectual Property Rights in, the Data
                  (as between the parties) remains your property. &nbsp;You
                  grant us a worldwide, non-exclusive, fully paid up,
                  transferable, irrevocable licence to use, store, copy, modify,
                  make available and communicate the Data for any purpose in
                  connection with the exercise of our rights and performance of
                  our obligations in accordance with these Terms.
                                </span>
                                <span className="c12">&nbsp;</span>
                            </li>
                            <li className="c3">
                                <span className="c0">
                  To the extent not owned by us, you grant us a royalty-free,
                  transferable, irrevocable and perpetual licence to use for our
                  own business purposes any know-how, techniques, ideas,
                  methodologies, and similar Intellectual Property used by us in
                  the provision of the Services.
                                </span>
                            </li>
                            <li className="c3">
                                <span>
                  If you provide us with ideas, comments or suggestions relating
                  to the Service or Underlying Systems (together
                                </span>
                                <span className="c12">&nbsp;feedback</span>
                                <span>):</span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-2 start" start="1">
                            <li className="c2">
                                <span>
                  all Intellectual Property Rights in that feedback, and
                  anything created as a result of that feedback (including new
                  material, enhancements, modifications or derivative works),
                  are owned solely by us; and
                                </span>
                            </li>
                            <li className="c2">
                                <span className="c0">
                  we may use or disclose the feedback for any purpose.
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-1" start="5">
                            <li className="c3" id="h.64wmrll3uvvm">
                                <span>
                  You acknowledge that the Service may link to third party{' '}
                                </span>
                                <span>web applications </span>
                                <span>
                  or feeds that are connected or relevant to the Service.
                  &nbsp;Any link from the Service does not imply that we
                  endorse, approve or recommend, or have responsibility for,
                  those{' '}
                                </span>
                                <span>web applications </span>
                                <span>
                  or feeds or their content or operators. &nbsp;To the maximum
                  extent permitted by law, we exclude all responsibility or
                  liability for those{' '}
                                </span>
                                <span>web applications </span>
                                <span>or feeds.</span>
                                <span>&nbsp; </span>
                            </li>
                        </ol>
                        <p className="c5" id="h.2xcytpi">
                            <span className="c9"></span>
                        </p>
                        <ol className="c6 lst-kix_list_1-0" start="9">
                            <li className="c3 c10" id="h.1ci93xb">
                                <span className="c7">CONFIDENTIALITY</span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-1 start" start="1">
                            <li className="c3 c10" id="h.3whwml4">
                                <span className="c0">
                  Each party must, unless it has the prior written consent of
                  the other party:
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-2 start" start="1">
                            <li className="c2" id="h.2bn6wsx">
                                <span className="c0">
                  keep confidential at all times the Confidential Information of
                  the other party;{' '}
                                </span>
                            </li>
                            <li className="c2" id="h.qsh70q">
                                <span className="c0">
                  effect and maintain adequate security measures to safeguard
                  the other party’s Confidential Information from unauthorised
                  access or use; and
                                </span>
                            </li>
                            <li className="c2">
                                <span>
                  disclose the other party’s Confidential Information to its
                  personnel or professional advisors on a{' '}
                                </span>
                                <span className="c1">need to know </span>
                                <span className="c0">
                  basis only and, in that case, ensure that any personnel or
                  professional advisor to whom it discloses the other party’s
                  Confidential Information is aware of, and complies with,
                  clauses 9.1a and 9.1b.
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-1" start="2">
                            <li className="c3 c10" id="h.3as4poj">
                                <span className="c0">
                  The obligation of confidentiality in clause 9.1 does not apply
                  to any disclosure or use of Confidential Information:
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-2 start" start="1">
                            <li className="c2">
                                <span className="c0">
                  for the purpose of performing a party’s obligations, or
                  exercising a party’s rights, under these Terms;
                                </span>
                            </li>
                            <li className="c2">
                                <span className="c0">
                  required by law (including under the rules of any stock
                  exchange);
                                </span>
                            </li>
                            <li className="c2" id="h.1pxezwc">
                                <span className="c0">
                  which is publicly available through no fault of the recipient
                  of the Confidential Information or its personnel;
                                </span>
                            </li>
                            <li className="c2" id="h.49x2ik5">
                                <span className="c0">
                  which was rightfully received by a party from a third party
                  without restriction and without breach of any obligation of
                  confidentiality; or
                                </span>
                            </li>
                            <li className="c2">
                                <span>by us if required as part of a </span>
                                <span className="c1">bona fide</span>
                                <span className="c0">
                  &nbsp;sale of our business (assets or shares, whether in whole
                  or in part) to a third party, provided that we enter into a
                  confidentiality agreement with the third party on terms no
                  less restrictive than this clause&nbsp;9.
                                </span>
                            </li>
                        </ol>
                        <p className="c5">
                            <span className="c0"></span>
                        </p>
                        <ol className="c6 lst-kix_list_1-0" start="10">
                            <li className="c3 c10">
                                <span className="c7">WARRANTIES</span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-1 start" start="1">
                            <li className="c3">
                                <span className="c0">
                  Each party warrants that it has full power and authority to
                  enter into, and perform its obligations under, these Terms.
                                </span>
                            </li>
                            <li className="c3 c10">
                                <span className="c0">
                  To the maximum extent permitted by law:
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-2 start" start="1">
                            <li className="c2" id="h.2p2csry">
                                <span className="c0">
                  our warranties are limited to those set out in these Terms,
                  and all other conditions, guarantees or warranties whether
                  expressed or implied by statute or otherwise (including any
                  warranty under the Sale of Goods Act (Chapter 393) 1999) are
                  expressly excluded and, to the extent that they cannot be
                  excluded, liability for them is limited to SGD 3,000.00; and
                                </span>
                            </li>
                            <li className="c2 c10">
                                <span className="c0">
                  we make no representation concerning the quality of the
                  Service and do not promise that the Service will:
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-3 start" start="1">
                            <li className="c13">
                                <span>
                  meet your requirements or be suitable for a particular
                  purpose, including that the use of the Service will fulfil or
                  meet any statutory role or responsibility you may have.{' '}
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-1" start="3">
                            <li className="c3">
                                <span className="c0">
                  Where legislation or rule of law implies into these Terms a
                  condition or warranty that cannot be excluded or modified by
                  contract, the condition or warranty is deemed to be included
                  in these Terms. &nbsp;However, our liability for any breach of
                  that condition or warranty is limited, at our option, to:
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-2 start" start="1">
                            <li className="c2">
                                <span className="c0">supplying the Service again; and/or</span>
                            </li>
                            <li className="c2">
                                <span className="c0">
                  paying the costs of having the Service supplied again.
                                </span>
                            </li>
                        </ol>
                        <p className="c5">
                            <span className="c0"></span>
                        </p>
                        <ol className="c6 lst-kix_list_1-0" start="11">
                            <li className="c3 c10" id="h.3o7alnk">
                                <span className="c7">LIABILITY</span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-1 start" start="1">
                            <li className="c3" id="h.23ckvvd">
                                <span className="c0">
                  Our maximum aggregate liability under or in connection with
                  these Terms or relating to the Service, whether in contract,
                  tort (including negligence), breach of statutory duty or
                  otherwise, must not in any Year exceed an amount equal to the
                  Fees paid by the Client under the Agreement in the previous
                  Year (which in the first Year is deemed to be the total Fees
                  paid by the Client from the Start Date to the date of the
                  first event giving rise to liability). &nbsp;The cap in this
                  clause 11.1 includes the cap set out in clause 10.2a.
                                </span>
                            </li>
                            <li className="c3" id="h.ihv636">
                                <span className="c0">
                  Neither party is liable to the other under or in connection
                  with these Terms or the Service for any:{' '}
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-2 start" start="1">
                            <li className="c2">
                                <span className="c0">
                  loss of profit, revenue, savings, business, use, data
                  (including Data), and/or goodwill; or
                                </span>
                            </li>
                            <li className="c2">
                                <span className="c0">
                  consequential, indirect, incidental or special damage or loss
                  of any kind.
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-1" start="3">
                            <li className="c3">
                                <span className="c0">
                  Clauses 11.1 and 11.2 do not apply to limit our liability
                  under or in connection with these Terms for:
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-2 start" start="1">
                            <li className="c2" id="h.32hioqz">
                                <span className="c0">personal injury or death;</span>
                            </li>
                            <li className="c2">
                                <span className="c0">fraud or wilful misconduct; or </span>
                            </li>
                            <li className="c2" id="h.1hmsyys">
                                <span className="c0">a breach of clause 9.</span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-1" start="4">
                            <li className="c3">
                                <span className="c0">
                  Clause 11.2 does not apply to limit your liability:
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-2 start" start="1">
                            <li className="c2">
                                <span className="c0">to pay the Fees;</span>
                            </li>
                            <li className="c2">
                                <span className="c0">
                  under the indemnity in clause 6.7; or
                                </span>
                            </li>
                            <li className="c2">
                                <span className="c0">
                  for those matters stated in clause 11.3a to 11.3c.
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-1" start="5">
                            <li className="c3">
                                <span className="c0">
                  Neither party will be responsible, liable, or held to be in
                  breach of these Terms for any failure to perform its
                  obligations under these Terms or otherwise, to the extent that
                  the failure is caused by the other party failing to comply
                  with its obligations under these Terms, or by the negligence
                  or misconduct of the other party or its personnel.
                                </span>
                            </li>
                            <li className="c3">
                                <span className="c0">
                  Each party must take reasonable steps to mitigate any loss or
                  damage, cost or expense it may suffer or incur arising out of
                  anything done or not done by the other party under or in
                  connection with these Terms or the Service.
                                </span>
                            </li>
                        </ol>
                        <p className="c5">
                            <span className="c0"></span>
                        </p>
                        <ol className="c6 lst-kix_list_1-0" start="12">
                            <li className="c3 c10" id="h.41mghml">
                                <span className="c7">TERM, TERMINATION AND SUSPENSION</span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-1 start" start="1">
                            <li className="c3" id="h.2grqrue">
                                <span className="c0">
                  Unless terminated under this clause 12, these Terms and your
                  right to access and use the Service:
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-2 start" start="1">
                            <li className="c2">
                                <span className="c0">starts on the Start Date; and</span>
                            </li>
                            <li className="c2">
                                <span>
                  continues until a party gives at least 30 days notice that
                  these Terms and your access to and use of the Service will
                  terminate on the expiry of that notice.
                                </span>
                                <span className="c7">&nbsp;</span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-1" start="2">
                            <li className="c4" id="h.vx1227">
                                <span>
                  Subject to clause 7.6, if the subscription option you have
                  selected includes a minimum initial term, the earliest date
                  for termination under clause 12.1 will be the expiry of that
                  initial term.
                                </span>
                                <span className="c0">&nbsp;</span>
                            </li>
                            <li className="c3 c10" id="h.3fwokq0">
                                <span>
                  Either party may, by notice to the other party, immediately
                  terminate these Terms and your right to access and use the
                  Service if the other party:
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-2 start" start="1">
                            <li className="c2">
                                <span className="c0">
                  breaches any material provision of these Terms and the breach
                  is not:
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-3 start" start="1">
                            <li className="c13">
                                <span className="c0">
                  remedied within 10 days of the receipt of a notice from the
                  first party requiring it to remedy the breach; or
                                </span>
                            </li>
                            <li className="c13">
                                <span className="c0">capable of being remedied; or</span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-2" start="2">
                            <li className="c2">
                                <span className="c0">
                  becomes insolvent, liquidated or bankrupt, has an
                  administrator, receiver, liquidator, statutory manager,
                  mortgagee’s or chargee’s agent appointed, becomes subject to
                  any form of insolvency action or external administration, or
                  ceases to continue business for any reason.
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-1" start="4">
                            <li className="c3">
                                <span className="c0">
                  You may terminate these Terms and your right to access and use
                  the Service in accordance with clause 7.6.
                                </span>
                            </li>
                            <li className="c3" id="h.1v1yuxt">
                                <span className="c0">
                  Termination of these Terms does not affect either party’s
                  rights and obligations that accrued before that termination.
                                </span>
                            </li>
                            <li className="c3">
                                <span className="c0">
                  On termination of these Terms, you must pay all Fees for the
                  provision of the Service prior to that termination.
                                </span>
                            </li>
                            <li className="c3" id="h.4f1mdlm">
                                <span className="c0">
                  No compensation is payable by us to you as a result of
                  termination of these Terms for whatever reason, and you will
                  not be entitled to a refund of any Fees that you have already
                  paid. &nbsp;{' '}
                                </span>
                            </li>
                            <li className="c3" id="h.2u6wntf">
                                <span className="c0">
                  Except to the extent that a party has ongoing rights to use
                  Confidential Information, at the other party’s request
                  following termination of these Terms but subject to clause
                  12.9, a party must promptly return to the other party or
                  destroy all Confidential Information of the other party that
                  is in the first party’s possession or control. &nbsp;
                                </span>
                            </li>
                            <li className="c3" id="h.19c6y18">
                                <span className="c0">
                  At any time prior to one month after the date of termination,
                  you may request:{' '}
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-2 start" start="1">
                            <li className="c2" id="h.3tbugp1">
                                <span className="c0">
                  a copy of any Data stored using the Service, provided that you
                  pay our reasonable costs of providing that copy. &nbsp;On
                  receipt of that request, we must provide a copy of the Data in
                  a common electronic form. &nbsp;We do not warrant that the
                  format of the Data will be compatible with any software;
                  and/or
                                </span>
                            </li>
                            <li className="c2">
                                <span className="c0">
                  deletion of the Data stored using the Service, in which case
                  we must use reasonable efforts to promptly delete that Data.
                  &nbsp;
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-1" start="10">
                            <li className="c3">
                                <span className="c0">
                  To avoid doubt, we are not required to comply with clause
                  12.9a to the extent that you have previously requested
                  deletion of the Data.
                                </span>
                            </li>
                            <li className="c3" id="h.28h4qwu">
                                <span className="c0">
                  Without limiting any other right or remedy available to us, we
                  may restrict or suspend your access to and use of the Service
                  and/or delete, edit or remove the relevant Data if we consider
                  that you or any of your personnel have:
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-2 start" start="1">
                            <li className="c2">
                                <span className="c0">
                  undermined, or attempted to undermine, the security or
                  integrity of the Service or any Underlying Systems;
                                </span>
                            </li>
                            <li className="c2 c10">
                                <span className="c0">
                  used, or attempted to use, the Service:
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-3 start" start="1">
                            <li className="c13">
                                <span className="c0">for improper purposes; or</span>
                            </li>
                            <li className="c13">
                                <span className="c0">
                  in a manner, other than for normal operational purposes, that
                  materially reduces the operational performance of the Service;{' '}
                                </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-2" start="3">
                            <li className="c2">
                                <span className="c0">
                  transmitted, inputted or stored any Data that breaches or may
                  breach these Terms or any third party right (including
                  Intellectual Property Rights and privacy rights), or that is
                  or may be Objectionable, incorrect or misleading; or
                                </span>
                            </li>
                            <li className="c2">
                                <span className="c0">
                  otherwise materially breached these Terms.
                                </span>
                            </li>
                        </ol>
                        <p className="c5">
                            <span className="c0"></span>
                        </p>
                        <ol className="c6 lst-kix_list_1-0" start="13">
                            <li className="c3 c10">
                                <span className="c7">GENERAL </span>
                            </li>
                        </ol>
                        <ol className="c6 lst-kix_list_1-1 start" start="1">
                            <li className="c3">
                                <span className="c0">
                  Neither party is liable to the other for any failure to
                  perform its obligations under these Terms to the extent caused
                  by Force Majeure.
                                </span>
                            </li>
                            <li className="c3">
                                <span className="c0">
                  No person other than you and us has any right to a benefit
                  under, or to enforce, these Terms.
                                </span>
                            </li>
                            <li className="c3">
                                <span className="c0">
                  For us to waive a right under these Terms, that waiver must be
                  in writing and signed by us.
                                </span>
                            </li>
                            <li className="c3">
                                <span className="c0">
                  Subject to clause 6.4, we are your independent contractor, and
                  no other relationship (e.g. joint venture, agency, trust or
                  partnership) exists under these Terms.
                                </span>
                            </li>
                            <li className="c3">
                                <span className="c0">
                  If we need to contact you, we may do so by email or by posting
                  a notice on the web application. &nbsp;You agree that this
                  satisfies all legal requirements in relation to written
                  communications. &nbsp;You may give notice to us under or in
                  connection with these Terms by emailing
                  support@auk.industries.
                                </span>
                            </li>
                            <li className="c4" id="h.nmf14n">
                                <span className="c0">
                  These Terms, and any dispute relating to these Terms or the
                  Service, are governed by and must be interpreted in accordance
                  with the laws of Singapore. &nbsp;Each party submits to the
                  non-exclusive jurisdiction of the Courts of Singapore in
                  relation to any dispute connected with these Terms or the
                  Service.
                                </span>
                            </li>
                            <li className="c3">
                                <span className="c0">
                  Clauses which, by their nature, are intended to survive
                  termination of these Terms, including clauses 6.7, 8, 9, 11,
                  12.5 to 12.9 and 13.6, continue in force. &nbsp;{' '}
                                </span>
                            </li>
                            <li className="c4" id="h.37m2jsg">
                                <span className="c0">
                  If any part or provision of these Terms is or becomes illegal,
                  unenforceable, or invalid, that part or provision is deemed to
                  be modified to the extent required to remedy the illegality,
                  unenforceability or invalidity. &nbsp;If modification is not
                  possible, the part or provision must be treated for all
                  purposes as severed from these Terms. &nbsp;The remainder of
                  these Terms will be binding on you.
                                </span>
                            </li>
                            <li className="c3">
                                <span className="c0">
                  Subject to clauses 2.1 and 7.6, any variation to these Terms
                  must be in writing and signed by both parties.
                                </span>
                            </li>
                            <li className="c3" id="h.1mrcu09">
                                <span className="c0">
                  These Terms set out everything agreed by the parties relating
                  to the Service, and supersede and cancel anything discussed,
                  exchanged or agreed prior to the Start Date. &nbsp;The parties
                  have not relied on any representation, warranty or agreement
                  relating to the Service that is not expressly set out in these
                  Terms, and no such representation, warranty or agreement has
                  any effect from the Start Date. &nbsp;Without limiting the
                  previous sentence, the parties agree that it is fair and
                  reasonable that the parties are bound by this clause 13.10.
                                </span>
                            </li>
                            <li className="c3">
                                <span>
                  You may not assign, novate, subcontract or transfer any right
                  or obligation under these Terms without our prior written
                  consent, that consent not to be unreasonably withheld.
                  &nbsp;You remain liable for your obligations under these Terms
                  despite any approved assignment, subcontracting or transfer.
                  &nbsp;
                                </span>
                            </li>
                        </ol>
                    </div>
                </PanelBody>
            </Modal>
        );
    }
}
