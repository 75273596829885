import { Breadcrumb, Form } from "antd"
import React, { useCallback, useMemo, useRef, useState } from "react"
import DeviceForm from "./DeviceForm"
import IoForm from "./IoForm"
import { flash } from "../../../components/Flash"
import { DeviceConstants as K } from "../../../../store/old/Devices/Devices.constants"
import AukButton from "../../../components/AukButton"
import { Permission } from "../../../components/Permission"
import { useDispatch } from "react-redux"
import { DoubleRightOutlined } from "@ant-design/icons"
import { updateDevice } from "../../../../store/old/Devices/Devices.action"

const DEVICE_STEP = 1
const CHANNELS_STEP = 2

const DeviceIoForm = (props) => {
    const dispatch = useDispatch()
    const deviceFormRef = useRef()
    const ioFormRef = useRef()

    const [step, setStep] = useState(DEVICE_STEP)
    const isFinalStep = useMemo(() => step === CHANNELS_STEP, [step])
    const handleNext = () => setStep(CHANNELS_STEP)

    const handleSubmit = () => {
            if (ioFormRef.current && deviceFormRef.current) {
                dispatch(updateDevice({
                    device_id: props.device.device_id,
                    data: {
                        device: deviceFormRef.current.getFormData(),
                        metadata: ioFormRef.current.getFormData()
                    }
                }, () => { props.onSubmit && props.onSubmit() }))
            }
        };

    return <Form.Provider
        onFormFinish={async () => {
            try {
                if (!isFinalStep) return handleNext()
                handleSubmit()
            } catch (e) {
                flash({
                    message: "Incomplete / incorrect fields",
                    status: "warning",
                })
            }
        }}
    >
        <div className="device-configuration"> 
            <Breadcrumb
                className="p-3"
                separator=">"
                style={{ borderBottom: "1px solid #ddd" }}
            >
                <Breadcrumb.Item>
                    <a
                        className={step === DEVICE_STEP ? "active" : ""}
                        onClick={(e) => {
                            e.preventDefault()
                            setStep(DEVICE_STEP)
                        }}
                    >
                Node
                    </a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <a
                        className={step === CHANNELS_STEP ? "active" : ""}
                        onClick={(e) => {
                            e.preventDefault()
                            setStep(CHANNELS_STEP)
                        }}
                    >
                Channels
                    </a>
                </Breadcrumb.Item>
            </Breadcrumb>
            <div className="device-configuration__body ">
                <DeviceForm
                    ref={deviceFormRef}
                    hidden={isFinalStep}
                    device={props.device}
                    onUnlink={props.onUnlink}
                    isInit={props.isInit}
                    interface={K.IO}
                />
                <IoForm
                    ref={ioFormRef}
                    hidden={!isFinalStep}
                    data={props.device ? props.device.metadata : []}
                />
            </div>
            <div className="device-configuration__submit">
                <Permission forResource resource="devices" canDo="edit">
                    <Form name="submitButton">
                        <AukButton.Blue
                            htmlType="submit"
                            // disabled={step === CHANNELS_STEP}
                        >
                            {isFinalStep ? 
                                "Submit"
                                : 
                                <span className="d-flex align-items-center">
                                    Channels <DoubleRightOutlined className="ml-2" />
                                </span>
                            }
                        </AukButton.Blue>
                    </Form>
                </Permission>
            </div>
        </div>
    </Form.Provider>
}

export default DeviceIoForm;