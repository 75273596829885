import "./index.css"
import Hotjar from "@hotjar/browser"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { SnackbarProvider } from "notistack"
import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { store } from "./store"
import "antd/dist/antd.css"

Hotjar.init(3789137, 6)

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
)

root.render(
    // <React.StrictMode>
    <BrowserRouter>
        <Provider store={store}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <SnackbarProvider
                    maxSnack={3}
                    autoHideDuration={5000}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                >
                    <App/>
                </SnackbarProvider>
            </LocalizationProvider>
        </Provider>
    </BrowserRouter>
    // </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
