import { store } from '../../store';
import CONSTANTS from '../Constants';
import { TimeSeriesFactory } from '../utils/data';

export const getDefaultSpeedAttribute = (mode) => mode === '1a';

export const parseMetadatumArguments = (m) => {
    return [
        m.analog_conversion,
        m.autoUpdate,
        m.batRec,
        +m.channel,
        m.chart_order ? m.chart_order.chart_order : null,
        m.chart_title,
        m.chart_type,
        m.color,
        m.ctMin,
        m.device_id,
        m.fsMax,
        m.invert,
        m.metadata_id,
        m.mode,
        m.oee_input,
        m.sensor_img,
        m.speed,
        m.stdTime,
        m.type,
        m.unit_conversion,
        m.units,
        m.control_limits,
        m.item_id
    ];
};

export class Metadatum {
    constructor(
        analog_conversion = null,
        autoUpdate = true,
        batRec = null,
        channel = -1,
        chart_order = -1,
        chart_title = '',
        chart_type = '',
        color,
        ctMin = null,
        device_id = -1,
        fsMax = null,
        invert = null,
        metadata_id = -1,
        mode = '',
        oee_input = '',
        sensor_img = null,
        speed = null,
        stdTime = 9999,
        type = '',
        unit_conversion = null,
        units = '',
        control_limits = null,
        item_id = ''
    ) {
        this.analog_conversion = analog_conversion;
        this.autoUpdate = autoUpdate;
        this.batRec = batRec;
        this.channel = channel;
        this.chart_order = chart_order;
        this.chart_title = chart_title;
        this.chart_type = chart_type;
        this.color = color || '#4682b4';
        this.ctMin = ctMin;
        this.device_id = device_id;
        this.fsMax = fsMax;
        this.invert = invert;
        this.metadata_id = metadata_id;
        this.mode = mode;
        this.oee_input = oee_input;
        this.sensor_img = sensor_img;
        this.speed = speed;
        this.stdTime = stdTime;
        this.type = type;
        this.unit_conversion = unit_conversion;
        this.units = units || 'units';
        this.control_limits = control_limits;
        this.item_id = item_id;
    }

    get id() {
        return this.metadata_id;
    }

    get asset_id() {
        return this.ofAsset.asset_id;
    }

    get ofDevice() {
        const {
            devices: { devices: devicesState },
        } = store.getState();

        return devicesState[this.device_id];
    }

    get ofAsset() {
        const {
            assets: { assets: assetsState },
        } = store.getState();

        return assetsState[this.ofDevice.asset_id];
    }

    get samplingRate() {
        return this.ofDevice.sampling_rate;
    }

    get hasData() {
        return this.data.length;
    }

    updateChartOrder(order) {
        this.chart_order = order;
    }

    get data() {
        const { metadata } = store.getState().data;

        return metadata[this.metadata_id] || TimeSeriesFactory([]);
    }

    get unitMeasure() {
        return this.units || 'units';
    }

    get aggregate() {
        const { mode, data, unitMeasure } = this;
        switch (mode) {
        case CONSTANTS.CHANNELS.MODES.DIGITAL_COUNT:
            const total = data.reduce((acc, curr) => (acc += curr.val), 0);
            return total ? `Total: ${total.toLocaleString()} ${unitMeasure}` : '';
        case CONSTANTS.CHANNELS.MODES.ANALOG_VOLTAGE:
            const lastMeasured = data.length ? data[data.length - 1].val : '';
            return lastMeasured
                ? `Last: ${lastMeasured.toFixed(2)} ${unitMeasure}`
                : '';
        default:
            return '';
        }
    }

    serialize() {
        return {
            ...this,
            chart_order: { chart_order: this.chart_order },
            sensor_img_id: this.sensor_img && this.sensor_img.image_id,
        };
    }
}
