export const NotificationConstants = {
    ACTIONS: {
        SET_NOTIFICATIONS_STATE: 'SET_NOTIFICATIONS_STATE',
        ADD_NOTIFICATIONS_RESOURCE: 'ADD_NOTIFICATIONS_RESOURCE',
        REMOVE_NOTIFICATIONS_RESOURCE: 'REMOVE_NOTIFICATIONS_RESOURCE',
        RESET_NOTIFICATIONS_STATE: 'RESET_NOTIFICATIONS_STATE',
        FETCH_NOTIFICATIONS_REQUEST: 'FETCH_NOTIFICATIONS_REQUEST',
        CREATE_NOTIFICATIONS_REQUEST: 'CREATE_NOTIFICATION_REQUEST',
        UPDATE_NOTIFICATIONS_REQUEST: 'UPDATE_NOTIFICATION_REQUEST',
        DELETE_NOTIFICATIONS_REQUEST: 'REMOVE_NOTIFICATION_REQUEST',
    },
};

export const CATEGORIES = {
    ALERT: 'alert',
    GATEWAY: 'gateway',
    REPORT: 'report',
    OUTLIER: 'outlier',
    NODE: 'node',
};

export const TOPICS = {
    CONDITION: 'Machine Condition',
    CORRELATION: 'Correlation',
    OEE: 'OEE',
    OVERALL: 'Overall',
    PRODUCTION: 'Production Rate',
};

export const NOTIFY_FIELDS = {
    CATEGORIES: [
        { value: CATEGORIES.ALERT, label: 'Alert', disabled: false },
        { value: CATEGORIES.GATEWAY, label: 'Gateway', disabled: false },
        { value: CATEGORIES.NODE, label: 'IoT Node', disabled: false },
        // { value: CATEGORIES.OUTLIER, label: 'Anomaly Detection', disabled: true },
        // { value: CATEGORIES.REPORT, label: 'Report', disabled: true },
    ],
    TOPICS: [
        { value: TOPICS.CONDITION, label: 'Machine Condition', disabled: false },
        // { value: TOPICS.OEE, label: 'OEE', disabled: true },
        // { value: TOPICS.OVERALL, label: 'Overall', disabled: true },
        // { value: TOPICS.PRODUCTION, label: 'Production Rate', disabled: true },
    ],
    OPERATORS: [
        { value: 'gt', label: 'Greater than' },
        { value: 'gte', label: 'Greater than or equal' },
        { value: 'lt', label: 'Less than' },
        { value: 'lte', label: 'Less than or equal' },
        { value: 'eq', label: 'Equal' },
    // { value: 'range', label: 'Range' }
    ],
    FREQUENCIES: [
        { value: 'hourly', label: 'Hourly' },
        { value: 'daily', label: 'Daily' },
        { value: 'weekly', label: 'Weekly' },
        { value: 'bi-weekly', label: 'Bi-weekly' },
        { value: 'monthly', label: 'Monthly' },
        { value: '-', label: '-' },
    ],
    MODES: [
        { value: 'email', label: 'Email' },
        { value: 'sms', label: 'SMS' },
        { value: 'whatsapp', label: 'Whatsapp' },
    ],
    STATUS: [
        { value: 'online', label: 'Online' },
        { value: 'offline', label: 'Offline' },
    ],
};
