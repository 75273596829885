import { isEmpty, keys, mapValues } from 'lodash';
import { regexMatch } from './helpers';
import { containsSuspiciousCharacters } from './validate';

export const isValidChartTitle = (str) => {
    const regExpCurl = /[{}]/;
    return !(containsSuspiciousCharacters(str) || regexMatch(str, regExpCurl));
};

const CHANNEL_REGEX = /^(\d)$/;

export const getChannelsFormData = (formData) => {
    return keys(formData)
        .filter((k) => regexMatch(k, CHANNEL_REGEX))
        .map((k) => formData[k])
        .filter((m) => m.mode && m.chart_title)
        .map((m) => {
            if (m.mode === '3a') {
                const batRec = mapValues(m.batRec, (v) => +v);

                // if batRec configured
                !isEmpty(batRec) && (m.batRec = batRec);

                // if batRec not configured and newly initialized channel
                !m.batRec &&
        !m.metadata_id &&
        (m.batRec = { upp: 99999, low: 0.001, in: 1, out: 1 });
            }
            if (m.mode !== '3a') delete m.batRec;
            if (m.mode !== '2a') delete m.invert;
            m.type = formData.type;
            return m;
        });
};

export const getChannelRows = (n) => Array.from(Array(n), (_, x) => x);
