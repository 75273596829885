/* eslint-disable react/prop-types */

import * as d3 from 'd3';
import React, { useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
// UTILS
import CONSTANTS from '../../Constants';
import { DashboardConstants as K } from '../../../store/old/UI/Dashboard/Dashboard.constants';

import { generateContinuousDomain, multiFormat } from '../../utils/charts';

// COMPONENTS
import AutosizeText from '../../components/AutosizeText';
import DashboardLiveLabel from '../../routes/Dashboard/DashboardLiveLabel';
import ImagePlaceholder from '../../components/ImagePlaceholder';
import WidgetLabel from '../components/WidgetLabel';
import { ColumnChart, LineChart } from '../../Charts/v1';
import { ColumnChartTooltip } from '../../Charts/v1/tooltips/templates';
import { NoData } from '../../components/None';

import './AssetPrimary.scss';
import { dummyAssetTile } from '../../auxStore';
import { useNavigate } from 'react-router-dom';

const xAccessor = (d) => d.time;
const yAccessor = (d) => d.val;


const getAggregate = (mode, data) => {
    switch (mode) {
    case CONSTANTS.CHANNELS.MODES.DIGITAL_COUNT:
        const total = data.reduce((acc, curr) => (acc += curr.val), 0);
        return total ? `Total: ${+total.toFixed(2).toLocaleString()}` : '';
    case CONSTANTS.CHANNELS.MODES.ANALOG_VOLTAGE:
        const lastMeasured = data.length ? data[data.length - 1].val : '';
        return lastMeasured ? `Last: ${lastMeasured.toFixed(2)}` : '';
    default:
        return '';
    }
};

export const AssetPrimary = (props) => {
    const navigate = useNavigate();
    let { data: asset, window, canDoubleClick, view } = props;
    
    const handleDoubleClick = useCallback((blockId) => {
        const url = `${CONSTANTS.URLS.ASSET}/${blockId}`;
        navigate(url);
    }, []);
    
    const { blocks_bn } = useSelector((appState) => appState.blocks);
    
    if (!asset) return null;
    
    const { primary, block, labels, block_id } = asset;

    if (!primary)
        return (
            <div className="viz-asset-primary" onDoubleClick={() => { handleDoubleClick(block_id) }}>
                <WidgetLabel>{asset.asset_name}</WidgetLabel>
                <div className="viz-asset-primary__image">
                    {asset && asset.asset_img ? (
                        <img src={asset.asset_img.signedUrl} />
                    ) : (
                        <ImagePlaceholder />
                    )}
                </div>
                <div className="viz-asset-primary__content">
                    <div
                        className="d-flex justify-content-center align-items-center w-100 h-100"
                        style={{ opacity: 0.6 }}
                    >
            No chart selected
                    </div>
                </div>
            </div>
        );

    const { data, units, chart_type, color, mode } = primary;
    const isBottleneck = blocks_bn.has(block.block_id);
    const isOffline = data && data.length === 0;

    return (
        <AssetPrimaryDumb
            data={data}
            color={color}
            mode={mode}
            units={units}
            isOffline={isOffline}
            chart_type={chart_type}
            labels={labels}
            asset={asset}
            isBottleneck={isBottleneck}
            canDoubleClick={canDoubleClick}
            handleDoubleClick={() => { handleDoubleClick(block_id) }}
            view={view}
            status={primary.data.status}
            window={window}
        />
    );
};

export const AssetPrimaryDumb = (props) => {
    let {
        data,
        color,
        mode,
        units,
        isOffline,
        chart_type,
        labels,
        asset,
        isBottleneck,
        canDoubleClick,
        handleDoubleClick,
        view,
        status,
        window,
    } = props;

    if (!window) {
        window = d3.extent(data, (d) => new Date(d.time));
    }

    const getChartProps = () => {
        return {
            data,
            margin: { top: 10, left: 35, right: 15, bottom: 20 },
            xScale: d3.scaleTime(),
            yScale: d3.scaleLinear(),
            xDomain: window,
            yDomain: generateContinuousDomain(data, yAccessor, 1.1),
            xAccessor,
            yAccessor,
            colorAccessor: () => color || 'steelblue',
            useBrush: false,
            xTicks: 6,
            yTicks: 4,
            yTickFormat: (d) => (`${d}`.length > 4 ? d.toExponential(1) : d),
            xTickFormat: (d, i) => (i % 2 === 0 ? multiFormat(d) : ''),
            showYGrid: true,
            useDataLabels: false
        };
    };

    const aggregate = useMemo(
        () => `${getAggregate(mode, data)} ${units}`,
        [data]
    );

    const chart = useMemo(() => {
        if (isOffline) {
            return (
                <div
                    className="w-100 h-100 d-flex justify-content-center align-items-center"
                    style={{
                        pointerEvents: 'none',
                        fontWeight: 'bold',
                    }}
                >
                    <span>Offline</span>
                </div>
            );
        }

        const chartProps = getChartProps();
        let chartComponent = null;

        if (chart_type === CONSTANTS.CHARTS.TYPES.STATE) {
            chartComponent = (
                <ColumnChart {...chartProps} htmlTooltip={ColumnChartTooltip} />
            );
        }

        if (chart_type === CONSTANTS.CHARTS.TYPES.COLUMN) {
            chartComponent = (
                <ColumnChart {...chartProps} htmlTooltip={ColumnChartTooltip} />
            );
        }

        if (chart_type === CONSTANTS.CHARTS.TYPES.LINE) {
            chartComponent = (
                <LineChart
                    {...chartProps}
                    focusEl="circle"
                    htmlTooltip={ColumnChartTooltip}
                />
            );
        }

        return (
            <>
                <div style={{ height: '12%' }}>
                    <AutosizeText>{aggregate}</AutosizeText>
                </div>
                <div style={{ flexGrow: 1 }}>{chartComponent}</div>
            </>
        );
    }, [data, color]);

    const sum = useMemo(() => {
        let value = '';
        switch (mode) {
        case CONSTANTS.CHANNELS.MODES.DIGITAL_COUNT:
            value = data.total;
            break;
        case CONSTANTS.CHANNELS.MODES.ANALOG_VOLTAGE:
            value = data.last;
            break;
        default:
            value = '';
            break;
        }

        return (
            <div className="chart-summary p-2 w-100 h-100 d-flex flex-column align-items-center justify-content-center">
                <div className="chart-summary-row w-100 text-overflow-ellipsis">
                    {value}
                </div>
                <div className="chart-summary-row w-100 text-overflow-ellipsis">
                    {units}
                </div>
            </div>
        );
    }, [data]);

    const currentlyTagging = useMemo(() => {
        const currentTags = labels
            .filter((l) => !l.isComplete)
            .reduce((acc, curr) => {
                if (curr.issue) {
                    acc['issue'] = curr;
                } else {
                    acc['sku'] = curr;
                }
                return acc;
            }, {});

        return asset ? (
            <div className="current-tags py-2 w-100 h-100 d-flex flex-column ">
                {!isEmpty(currentTags) ? (
                    <div className="d-flex flex-column w-100 h-100">
                        <div className="h-50 mb-1">
                            {currentTags['issue'] && (
                                <DashboardLiveLabel label={currentTags['issue']} />
                            )}
                        </div>
                        <div className="h-50">
                            {currentTags['sku'] && (
                                <DashboardLiveLabel label={currentTags['sku']} />
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                        <NoData description="No current labels" />
                    </div>
                )}
            </div>
        ) : null;
    }, [labels]);

    return (
        <>
            {isBottleneck && <OutlineBottleneck />}
            <div
                className="viz-asset-primary"
                style={{ opacity: isOffline ? 0.6 : 1 }}
                onDoubleClick={() => canDoubleClick && handleDoubleClick()}
            >
                <div
                    className="viz-asset-primary__status"
                    style={{ background: status }}
                />
                <WidgetLabel>{asset.asset_name}</WidgetLabel>
                <div className="viz-asset-primary__image">
                    {asset && asset.asset_img ? (
                        <img src={asset.asset_img.signedUrl} />
                    ) : (
                        <ImagePlaceholder />
                    )}
                </div>
                <div className="viz-asset-primary__content">
                    {view === K.VIEW.LABEL
                        ? currentlyTagging
                        : view === K.VIEW.SUMMARY
                            ? sum
                            : chart}
                </div>
            </div>
        </>
    );
};

const OutlineBottleneck = () => (
    <div
        style={{
            position: 'absolute',
            height: 'calc(100% + 15px)',
            width: '100%',
            border: '4px solid #4c4949',
            pointerEvents: 'none',
            zIndex: 5,
        }}
    />
);

AssetPrimaryDumb.defaultProps = {
    showBottleneck: false,
    canDoubleClick: false,
    isOffline: false,
    isBottleneck: false,
    data: dummyAssetTile.asset.primary.data,
    mode: dummyAssetTile.asset.primary.mode,
    units: dummyAssetTile.asset.primary.units,
    chart_type: dummyAssetTile.asset.primary.chart_type,
    labels: [],
    asset: dummyAssetTile.asset,
    view: K.VIEW.TIME_SERIES,
    status: '',
};
