import { NotificationConstants as K } from './Notifications.constants';

export const setNotificationsState = (notifications) => {
    return {
        type: K.ACTIONS.SET_NOTIFICATIONS_STATE,
        payload: { notifications },
    };
};

export const resetNotificationsState = () => {
    return {
        type: K.ACTIONS.RESET_NOTIFICATIONS_STATE,
    };
};

export const addNotificationsResource = (notifications) => {
    return {
        type: K.ACTIONS.ADD_NOTIFICATIONS_RESOURCE,
        payload: { notifications },
    };
};

export const removeNotificationsResource = (notificationIds) => {
    return {
        type: K.ACTIONS.REMOVE_NOTIFICATIONS_RESOURCE,
        payload: { notificationIds },
    };
};

export const FetchNotifications = (entityId) => {
    return {
        type: K.ACTIONS.FETCH_NOTIFICATIONS_REQUEST,
        payload: { entityId }
    };
};

export const CreateNotificationRequest = (payload) => {
    return {
        type: K.ACTIONS.CREATE_NOTIFICATIONS_REQUEST,
        payload,
    };
};

export const UpdateNotificationRequest = (payload) => {
    return {
        type: K.ACTIONS.UPDATE_NOTIFICATIONS_REQUEST,
        payload,
    };
};

export const DeleteNotificationRequest = (notification) => {
    return {
        type: K.ACTIONS.DELETE_NOTIFICATIONS_REQUEST,
        payload: notification,
    };
};
