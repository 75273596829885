import { keys, mapValues, values } from 'lodash';
import { store } from '../../store';
import { DeviceConstants as K } from '../../store/old/Devices/Devices.constants';

const { IO, RS232, RS485 } = K;

export const parseDeviceArguments = (d) => {
    return [
        d.firmware_version
            ? d.firmware_version
            : d.gateway && d.gateway.firmware_version
                ? d.gateway.firmware_version
                : 'v0.0.1',
        d.asset_id,
        d.device_id,
        d.mac_address,
        d.operating_voltage,
        d.original_asset_id,
        d.sampling_rate,
        d.minmax_filter,
        d.filter,
        d.interface,
        d.baud,
        d.serial,
        d.subscriptions,
        d.gateway_id,
        d.relay,
    ];
};

export class Device {
    constructor(
        firmware_version,
        asset_id,
        device_id,
        mac_address,
        operating_voltage,
        original_asset_id = undefined,
        sampling_rate,
        minmax_filter,
        filter,
        interface_type,
        baud,
        serial,
        subscriptions,
        gateway_id,
        relay
    ) {
        this.firmware_version = firmware_version;
        this.asset_id = asset_id;
        this.device_id = device_id;
        this.mac_address = mac_address;
        this.operating_voltage = operating_voltage;
        this.original_asset_id = original_asset_id;
        this.sampling_rate = sampling_rate;
        this.minmax_filter = minmax_filter;
        this.filter = filter;
        this.interface_type = interface_type || IO;
        this.baud = baud;
        this.serial = serial;
        this.subscriptions = subscriptions;
        this.gateway_id = gateway_id;
        this.relay = relay;
    }

    get asset() {
        const {
            assets: { assets },
        } = store.getState();
        return assets[this.asset_id] || null;
    }

    get metadata() {
        const {
            metadata: { metadata },
        } = store.getState();

        const result = values(metadata)
            .filter(metadatum => metadatum.device_id === this.device_id)
            // .map((id) => metadata[id])
            .sort((a, b) => a.chart_order - b.chart_order);

        return result;
    }

    // SERIAL
    get isSerialInterface() {
        return this.interface_type !== IO;
    }

    // RS232
    get dmode() {
        if (!this.serial) return 'poll';
        if (this.interface_type !== RS232) return 'poll';
        return this.serial.dmode;
    }

    // RS232
    get cmd() {
        if (!this.serial) return '';
        if (this.interface_type !== RS232) return '';
        return this.dmode === 'poll' ? this.serial.data[0].cmd.val : '';
    }

    // RS485
    get slaveId() {
        if (!this.serial) return '';
        if (this.interface_type !== RS485) return '';
        return this.serial.data[0].address.slaveId;
    }

    // RS485
    get registers() {
        if (!this.serial) return [];
        if (this.interface_type !== RS485) return [];

        return this.serial.data[0].address.reg.map((reg, i) => {
            return { reg, regSize: this.serial.data[0].address.regSize[i] };
        });
    }

    get channelSerialMap() {
        if (!this.serial) return {};
        if (this.interface_type === IO) return {};

        const { map: serialChannelMap } = this.serial.data[0];

        if (this.interface_type === RS232) {
            return mapValues(serialChannelMap, (o) => [this.cmd, '-']);
        }

        const { registers } = this;
        return keys(serialChannelMap).reduce((acc, curr, index) => {
            return {
                ...acc,
                [serialChannelMap[curr].ch]: [registers[index].reg, '-'],
            };
        }, {});
    }
}
