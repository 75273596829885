import { Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { api_previewSerialData } from '../../../../store/old/Devices/Devices.services';
import AukButton from '../../../components/AukButton';

const SerialPreview = (props) => {
    const [preview, setPreview] = useState('')
    const getSerialPreview = async () => {
        try {
            const res = await api_previewSerialData({
                device_id: props.device.device_id,
                data: props.getDataForPreview(),
            });

            setPreview(res.message);
        } catch (e) {
            errorFlash({ message: 'Error generating preview, please check that device is connected' });
        }
    };

    useEffect(() => {
        props.onChange(preview)
    } , [preview])

    return <>
        {preview ? (
            <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Data Preview"
            >
                <Input.TextArea className="w-100" value={preview} disabled />
            </Form.Item>
            ) : null
        }
        <Form.Item
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            label={' '}
            colon={false}
        >
            <AukButton.Blue className="w-100" onClick={async () => {
                if (props.updateBeforePreview) {
                    props.updateDevice && await props.updateDevice()
                }
                await getSerialPreview()
            }}>
                Preview Inputs
            </AukButton.Blue>
        </Form.Item>
    </>
}

export default SerialPreview;