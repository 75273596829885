import { Select } from "antd"
import React from "react"
import { DeviceConstants as K } from "../../../../store/old/Devices/Devices.constants"
import "./DeviceInterfaceSelect.scss"

const DeviceInterfaceSelect = (props: Props) => {
    return <div className="device-interface-select">
        <Select options={K.INTERFACE_TYPES} onChange={props.onChange} placeholder={"Select device interface to begin"}/>
    </div>
}

interface Props {
    onChange: (value: string) => any
}

export default DeviceInterfaceSelect

