import { Breadcrumb, Form } from "antd"
import React, { useMemo, useRef, useState } from "react"
import DeviceForm from "./DeviceForm"
import { flash } from "../../../components/Flash"
import { DeviceConstants as K } from "../../../../store/old/Devices/Devices.constants"
import AukButton from "../../../components/AukButton"
import { Permission } from "../../../components/Permission"
import { useDispatch } from "react-redux"
import { DoubleRightOutlined } from "@ant-design/icons"
import { updateDevice } from "../../../../store/old/Devices/Devices.action"
import Rs485Form from "./Rs485Form"
import { api_updateDevice } from "../../../../store/old/Devices/Devices.services"

const DEVICE_STEP = 1
const RS485_STEP = 2

const DeviceRs485Form = (props) => {
    const dispatch = useDispatch()
    const deviceFormRef = useRef()
    const rs485FormRef = useRef()

    const [step, setStep] = useState(DEVICE_STEP)
    const isFinalStep = useMemo(() => step === RS485_STEP, [step])
    const handleNext = () => setStep(RS485_STEP)

    const getDevicePayload = () => {
        if (rs485FormRef.current && deviceFormRef.current) {
            const device = deviceFormRef.current.getFormData();
            const { serial, metadata } = rs485FormRef.current.getFormData();

            return {
                device_id: props.device.device_id,
                data: {
                    device: { ...device, serial },
                    metadata
                }
            }
        }
    }

    const handleSubmit = () => {
            if (rs485FormRef.current && deviceFormRef.current) {
                const devicePayload = getDevicePayload()
                dispatch(updateDevice(devicePayload, () => { props.onSubmit && props.onSubmit() }))
            }
        };

    return <Form.Provider
        onFormFinish={async () => {
            try {
                if (!isFinalStep) return handleNext()
                handleSubmit()
            } catch (e) {
                flash({
                    message: "Incomplete / incorrect fields",
                    status: "warning",
                })
            }
        }}
    >
        <div className="device-configuration"> 
            <Breadcrumb
                className="p-3"
                separator=">"
                style={{ borderBottom: "1px solid #ddd" }}
            >
                <Breadcrumb.Item>
                    <a
                        className={step === DEVICE_STEP ? "active" : ""}
                        onClick={(e) => {
                            e.preventDefault()
                            setStep(DEVICE_STEP)
                        }}
                    >
                Node
                    </a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <a
                        className={step === RS485_STEP ? "active" : ""}
                        onClick={(e) => {
                            e.preventDefault()
                            setStep(RS485_STEP)
                        }}
                    >
                        Inputs
                    </a>
                </Breadcrumb.Item>
            </Breadcrumb>
            <div className="device-configuration__body ">
                <DeviceForm
                    ref={deviceFormRef}
                    hidden={isFinalStep}
                    device={props.device}
                    onUnlink={props.onUnlink}
                    isInit={props.isInit}
                    interface={K.RS485}
                />
                <Rs485Form
                    ref={rs485FormRef}
                    hidden={!isFinalStep}
                    device={props.device}
                    data={props.device ? props.device.metadata : []}
                    isInit={props.isInit}
                    updateDevice={async() => { return await api_updateDevice(getDevicePayload()) }}
                />
            </div>
            <div className="device-configuration__submit">
                <Permission forResource resource="devices" canDo="edit">
                    <Form name="submitButton">
                        <AukButton.Blue htmlType="submit">
                            {isFinalStep ? 
                                "Submit"
                                : 
                                <span className="d-flex align-items-center">
                                    Inputs <DoubleRightOutlined className="ml-2" />
                                </span>
                            }
                        </AukButton.Blue>
                    </Form>
                </Permission>
            </div>
        </div>
    </Form.Provider>
}

export default DeviceRs485Form;