export const DeviceConstants = {
    ACTIONS: {
        RESET_DEVICES_STATE: 'RESET_DEVICES_STATE',
        SET_DEVICES_RESOURCE: 'SET_DEVICES_RESOURCE',
        ADD_DEVICES_RESOURCE: 'ADD_DEVICES_RESOURCE',
        REMOVE_DEVICES_RESOURCE: 'REMOVE_DEVICES_RESOURCE',
        PREVIEW_SERIAL_COMMUNICATIONS_DATA: 'PREVIEW_SERIAL_COMMUNICATIONS_DATA',
        UPDATE_DEVICE_REQUEST: 'UPDATE_DEVICE_REQUEST',
        INITIALIZE_DEVICE_REQUEST: 'INITIALIZE_DEVICE_REQUEST',
        // CREATE_DEVICE_REQUEST: 'CREATE_DEVICE_REQUEST',
        UPDATE_DEVICE_ENTITY_REQUEST: 'UPDATE_DEVICE_ENTITY_REQUEST',
        INITIALIZE_RELAY_REQUEST: 'INITIALIZE_RELAY_REQUEST',
        LINK_DEVICE_REQUEST: 'LINK_DEVICE_REQUEST',
        UNLINK_DEVICE_REQUEST: 'UNLINK_DEVICE_REQUEST',
        CASCADE_UNLINK_DEVICE: 'CASCADE_UNLINK_DEVICE',
        REMOVE_RELAY_REQUEST: 'REMOVE_RELAY_REQUEST',
        CREATE_ASSET_OPCUA_DEVICES_METADATA: 'CREATE_ASSET_OPCUA_DEVICES_METADATA',
        UPDATE_ASSET_OPCUA_DEVICES_METADATA: 'UPDATE_ASSET_OPCUA_DEVICES_METADATA'
    },
    TYPES: {
        NODE: 'node',
        RELAY: 'relay',
        OPCUA: 'OPCUA',
        GATEWAY: 'gateway',
    },
    IO: 'i/o',
    RS232: 'rs232',
    RS485: 'rs485',
    DMODE_PUSH: 'print',
    DMODE_POLL: 'poll',
    SAMPLING_RATES: [
        { value: 15, label: '15 seconds' },
        { value: 30, label: '30 seconds' },
        { value: 60, label: '60 seconds / 1 minute' },
        // { value: 120, label: '120 seconds / 2 minutes' },
        { value: 300, label: '300 seconds / 5 minutes' },
        { value: 900, label: '900 seconds / 15 minutes' },
        { value: 1800, label: '1800 seconds / 30 minutes' },
    ],
    MODES: [
        { value: '1a', label: 'Digital Count' },
        { value: '2a', label: 'Digital State' },
        { value: '3a', label: 'Analog Voltage' },
        { value: '4a', label: 'Analog Voltage (Max)' },
        { value: '5a', label: 'Analog Voltage (Min)' },
    ],
    PUSH: [
        { value: 15, label: '15' },
        { value: 30, label: '30' },
        { value: 60, label: '60' },
        { value: 120, label: '120' },
        { value: 300, label: '300' },
    ],
    AUTO_UPDATE: [
        { value: true, label: 'True' },
        { value: false, label: 'False' },
    ],
    INVERT: [
        { value: true, label: 'True' },
        { value: false, label: 'False' },
    ],
    INTERFACE_TYPES: [
        { value: 'i/o', label: 'Sensor Input/Output (Terminal)' },
        { value: 'rs232', label: 'Serial Communication (RS232)' },
        { value: 'rs485', label: 'Serial Communications (ModbusRTU - RS485)' },
    ],
    DMODE: [
        { value: 'poll', label: 'Poll' },
        { value: 'print', label: 'Push' },
    ],
    BAUD_RATE: [
        { value: 1200, label: '1200' },
        { value: 2400, label: '2400' },
        { value: 4800, label: '4800' },
        { value: 9600, label: '9600' },
        { value: 19200, label: '19200' },
        { value: 38400, label: '38400' },
        { value: 57600, label: '57600' },
        { value: 115200, label: '115200' },
    ],
    REGEX_OPTIONS: [
        {
            value: '0',
            rawLabel: /(-?\d[\d\.]*)/g,
            label: '/(-?\\d[\\d\\.]*)/g',
        },
    ],
    MODELS: {
        RELAY: 'RELAY',
        GATEWAY: 'GATEWAY',
        NODE: 'NODE'
    },
    OPCUA: {
        BASE_DEVICE_CONFIG: {
            model_id: 2,
            operating_voltage: 24.0,
            firmware_version: 'opc.v.10.0.0',
            hardware_version: 'AI-AO-2000-UA',
            interface: 'opcua',
            filter: 0,
            minmax_filter: 0
        }
    }
};
