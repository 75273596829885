import React from "react"
import { session } from "../legacy/auxStore"
import { Navigate } from "react-router-dom"
import { ROUTES } from "./routes"
import { SPAWrapper } from "../legacy/components/SPAWrapper"
import { Result } from "antd"

const Fallback = () => {
    if (!session.user_id) {
        return <Navigate to={ROUTES.LOGIN} />
    }

    return <Navigate to={ROUTES.NOT_FOUND} />
}

export default Fallback

export const NotFound = () => {
    return <SPAWrapper>
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
        />
    </SPAWrapper>
}