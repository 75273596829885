import "./DeviceScan.scss"
import { ArrowLeftOutlined, DatabaseOutlined, DeploymentUnitOutlined, SwapOutlined } from "@ant-design/icons"
import React, { forwardRef, useImperativeHandle, useMemo } from "react"
import { useAppDispatch, useAppSelector } from "../../../../store/hooks"
import useCurrentEntity from "../../../../hooks/useCurrentEntity"
import { addGateway } from "../../../../store/old/Gateway/Gateway.action"
import { AnyFunction } from "../../../../types/any"
import { Form, Select } from "antd"
import DeviceSerialInputScan from "./DeviceSerialInputScan"
import Box, { BoxContent } from "../../../components/Box"
import { values } from "lodash"
import AukButton from "../../../components/AukButton"

export const DeviceScanGateway = (props: DeviceScanGatewayProps) => {
    const dispatch = useAppDispatch()
    const { entityId } = useCurrentEntity()
    
    const submit = (serialNumber: string) => {
        // serial number
        void dispatch(
            addGateway(
                entityId,
                serialNumber,
                (result: any) => props.onSubmit && props.onSubmit(result)
            )
        )
    }

    return (
        <div className="device-scan">
            <div className="device-scan__icons p-5">
                <Box>
                    <BoxContent
                        text={DEVICE_MODELS.GATEWAY.label}
                        active={true}
                        icon={DEVICE_MODELS.GATEWAY.icon}
                    />
                </Box>
            </div>
            <Form.Item
                label="Serial Number"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
            >
                <DeviceSerialInputScan submit={submit}/>
            </Form.Item>
        </div>
    )
}

interface DeviceScanGatewayProps {
    onSubmit?: AnyFunction
}

export const DeviceScanNode = forwardRef((props: DeviceScanNodeProps, ref) => {
    const [form] = Form.useForm()
    const _gateways = useAppSelector(appState => appState.gateway.gateways)
    const gateways = useMemo(() => {
        return values(_gateways)
            .filter((gw) => gw.interface !== "opcua")
            .map((gw) => ({
                label: gw.serial_number,
                value: gw.device_id,
            })
            )}, [_gateways])


    useImperativeHandle(ref, () => ({ 
        getFormData: () => { void form.getFieldsValue(true)},
        form
    }), [])

    return (
        <div className="device-scan" ref={ref as any}>
            <div className="device-scan__icons p-5">
                <Box>
                    <BoxContent
                        text={DEVICE_MODELS[props.forModel].label}
                        active={true}
                        icon={DEVICE_MODELS[props.forModel].icon}
                    />
                </Box>
            </div>
            <Form
                name="nodeScanForm"
                id="nodeScanForm"
                form={form}
                onFinish={async () => {
                    try {
                        await form.validateFields()
                        const errors = form.getFieldsError().filter(item => item.errors.length)

                        if (!errors.length) {
                            props.submit(form.getFieldsValue(true))
                            return
                        }
                    } catch(e) {
                        console.log(e)
                    }
                }}
            >
                <Form.Item
                    label="Gateway"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    name="gatewayId"
                    initialValue={+gateways[0].value}
                    rules={[
                        {
                            required: true,
                            message: "Gateway is required",
                        },
                    ]}
                >
                    <Select
                        options={gateways}
                        style={{ width: "calc(100% - 41px)" }}
                    />
                </Form.Item>
                <Form.Item
                    label="Serial Number"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    name="dmac"
                    initialValue={""}
                    rules={[
                        {
                            required: true,
                            message: "Node serial number is required",
                        },
                    ]}
                >
                    <DeviceSerialInputScan/>
                </Form.Item>
                {
                    props.showBack ? <AukButton.Outlined className="mt-3 float-left" onClick={props.handleClickBack}><ArrowLeftOutlined/>Back</AukButton.Outlined> : null
                }
                {
                    props.showSubmit ? <AukButton.Save htmlType="submit" className="mt-3 float-right"/> : null
                }
            </Form>
        </div>
    )
})

DeviceScanNode.displayName = "DeviceScanNode"

interface DeviceScanNodeProps {
    showSubmit?: boolean
    showBack?: boolean
    handleClickBack?: AnyFunction
    submit: AnyFunction
    forModel: "RELAY" | "NODE"
    footer?: React.ReactNode
}


const DEVICE_MODELS = {
    RELAY: {
        label: "Relay",
        icon: <SwapOutlined/>
    },
    GATEWAY: {
        label: "Gateway",
        icon: <DatabaseOutlined/>
    },
    NODE: {
        label: "Node",
        icon: <DeploymentUnitOutlined/>
    }
}

